import React, { useState, useEffect } from 'react';
import { Button, Form, FloatingLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useIdentityContext } from '../identity-react';
import { getAppName } from '../features/display/displaySlice';
import LoadingSpinner from './utilities/loadingSpinner';
import { ValidatePassword } from './utilities/passwordValidation';

function Login() {
    const identity = useIdentityContext();
    const appName = useSelector(getAppName);
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userFullName, setUserFullName] = useState('');
    const [formError, setFormError] = useState('');
    const [userIntention, setUserIntention] = useState("login");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = ((userIntention === "signup" || userIntention === "invite") ? 'Sign Up' : 'Login') + " to " + appName;
    }, [appName, userIntention]);

    useEffect(() => {
        if (identity.urlToken) {
            switch(identity.urlToken.type) {
                case "passwordRecovery":
                    setUserIntention("passwordRecovery");
                    break;
                case "confirmation":
                    setIsLoading(true);
                    break;
                case "invite":
                    setUserIntention("invite");
                    break;
                case "email_change":
                    setUserIntention("emailChange");
                    break;
                default:
                    break;
            }
        } else if (identity.provisionalUser) {
            setUserIntention("provisionalUser");
        }
    }, [identity.urlToken, identity.provisionalUser]);

    const handleLogin = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        await identity.login({
            email: userEmail,
            password: userPassword
        })
            .catch((e) => {
                setFormError(e.message);
                setIsLoading(false);
            })
    };

    const handleForgot = async e => {
        e.preventDefault();
        try {
            await identity.sendPasswordRecovery({
                email: userEmail
            });
            setFormError('Please check your email for a password recovery link');
        } catch (error) {
            setFormError(error.message);
        }
    };

    const handleSignUp = async e => {
        e.preventDefault();
        if (!ValidatePassword(userPassword)) {
            setFormError('Password must be over 6 characters long, include a number, and at least 1 uppercase character.');
            return;
        }
        if (userPassword !== confirmPassword) {
            setFormError("Passwords do not match");
            return;
        }
        try {
            setIsLoading(true);
            await identity.signup({
                password: userPassword,
                email: userEmail,
                user_metadata: {
                    full_name: userFullName
                }
            })
        } catch (error) {
            setFormError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotToggle = (toggleTo) => {
        setUserIntention(toggleTo);
    };

    const handlePasswordReset = async e => {
        e.preventDefault();
        if (!ValidatePassword(userPassword)) {
            setFormError('Password must be over 6 characters long, include a number, and at least 1 uppercase character.');
            return;
        }
        if (userPassword !== confirmPassword) {
            setFormError("Passwords do not match");
            return;
        }
        try {
            setIsLoading(true);
            await identity.completeUrlTokenTwoStep({
                password: userPassword
            })
        } catch (error) {
            setFormError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInviteSignUp = async e => {
        e.preventDefault();
        if (!ValidatePassword(userPassword)) {
            setFormError('Password must be over 6 characters long, include a number, and at least 1 uppercase character.');
            return;
        }
        if (userPassword !== confirmPassword) {
            setFormError("Passwords do not match");
            return;
        }
        try {
            setIsLoading(true);
            await identity.completeUrlTokenTwoStep({
                password: userPassword,
                user_metadata: {
                    full_name: userFullName
                }
            })
        } catch (error) {
            setFormError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : userIntention === "provisionalUser" ? (
                <>
                    <h2><i className="bi bi-envelope-exclamation"></i></h2>
                    <h5>Confirm Your E-Mail Address</h5><br />
                    <p>To finish your registration, please check {identity.provisionalUser.email || 'your email'} for a confirmation link.</p>
                    <p>This step verifies your email address and ensures we can communicate with you properly.</p>
                </>
            ) : (
                <Form className="py-2 px-0 p-md-4"
                    onSubmit={
                        userIntention === "passwordReset" ? handleForgot
                        : userIntention === "signup" ? handleSignUp
                        : userIntention === "passwordRecovery" ? handlePasswordReset
                        : userIntention === "invite" ? handleInviteSignUp
                        : handleLogin
                    }
                >
                    {/* Email Address */}
                    {!(userIntention === "passwordRecovery" || userIntention === "invite") && (
                        <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                            <Form.Control type="email" placeholder="name@example.com" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required />
                        </FloatingLabel>
                    )}

                    {/* Full Name */}
                    {(userIntention === "signup" || userIntention === "invite") && (
                        <FloatingLabel controlId="floatingInput" label="Full Name" className="mb-3">
                            <Form.Control type="text" placeholder="Your Name" value={userFullName} onChange={(e) => setUserFullName(e.target.value)} required />
                        </FloatingLabel>
                    )}

                    {/* Password */}
                    {!(userIntention === "passwordReset") && (
                        <FloatingLabel controlId="floatingPassword" label={userIntention === "passwordRecovery" ? 'New Password' : 'Password'}>
                            <Form.Control className="mb-3" type="password" placeholder="Password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} required />
                        </FloatingLabel>
                    )}

                    {/* Confirm Password */}
                    {(userIntention === "signup" || userIntention === "passwordRecovery" || userIntention === "invite") && (
                        <FloatingLabel controlId="confirmPassword" label={userIntention === "passwordRecovery" ? 'Confirm New Password' : 'Confirm Password'}>
                            <Form.Control className="mb-3" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                        </FloatingLabel>
                    )}

                    {/* GDPR Check */}
                    {(userIntention === "signup" || userIntention === "invite") && (
                        <Form.Check
                            type="checkbox"
                            label={<span><small>I agree to the <a href="/terms" target="_blank" rel="noopener noreferrer">Privacy Policy & Terms of Service.</a></small></span>} 
                            id="gdprcheck"
                            required={true}
                            className="mb-3"
                      />
                    )}

                    {/* Submit Button */}
                    <button type="submit" id="login_button" className="purple-button w-100">
                        {userIntention === "passwordReset" ? 'Send Recovery Link'
                        : userIntention === "login" ? 'Login'
                        : userIntention === "passwordRecovery" ? 'Set New Password'
                        : 'Sign Up'}
                    </button>

                    {/* Display Error */}
                    {formError && <div className="text-danger mt-2 fs-6"><small>{formError}</small></div>}

                    {/* Toggle Links */}
                    <Button variant="link" onClick={() => handleForgotToggle(userIntention === "login" ? "passwordReset" : "login")}>
                        <small>{userIntention === "login" ? "Forgot Password?" : "Go back to Login"}</small>
                    </Button>

                    {!(userIntention === "signup" || userIntention === "invite") && (
                        <Button variant="link" onClick={() => handleForgotToggle("signup")}>
                            <small>Sign Up</small>
                        </Button>
                    )}
                </Form>
            )}
        </>
    );
}

export default Login;