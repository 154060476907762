import React, { useState } from 'react';
import { Button, Form, FloatingLabel, Modal, DropdownItem } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';
import LoadingSpinner from '../utilities/loadingSpinner';
import { ValidatePassword } from '../utilities/passwordValidation';
import { addAlert } from '../../features/alerts/alertsSlice';
import { useDispatch } from 'react-redux';

export function PasswordChange( { linkStyle } ) {
    const identity = useIdentityContext();
    const [userPassword, setUserPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [formError, setFormError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChangePassword = async e => {
        e.preventDefault();
        if (!ValidatePassword(userPassword)) {
            setFormError('Password must be over 6 characters long, include a number, and at least 1 uppercase character.');
            return;
        }
        if (userPassword !== confirmPassword) {
            setFormError("Passwords do not match");
            return;
        }
        try {
            setIsLoading(true);
            await identity.login({
                email: identity?.user?.email,
                password: currentPassword
            });

            await identity.update({
                password: userPassword
              });
            
            setSuccess(true);
            setUserPassword('');
            setConfirmPassword('');

            const newAlert = {
                id: 'passwordSuccess',
                variant: 'success',
                message: 'Your password was successfully updated.',
                dismissible: true
              };
              
              // Dispatch the addAlert action with the new alert object as payload
            dispatch(addAlert(newAlert));
        } catch (error) {
            setFormError("There was an error updating your password. Check your current password is correct and try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
        {linkStyle === "button" ? (<Button variant="primary" onClick={handleShow}>
        Change Password
        </Button>) : linkStyle === "dropdownItem" ? (
            <DropdownItem onClick={handleShow}>Change Password</DropdownItem>
        ) :
        (<Button variant="link" onClick={handleShow}>
            Change Password
            </Button>)
        }

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          Change Your Password
        </Modal.Header>
        <Modal.Body>
        {isLoading ? <LoadingSpinner /> : success ? <><div className="text-center text-success"><h1><i className="bi bi-check-circle"></i></h1><p>Your password has been updated</p></div></> : (
        <Form id="passwordChangeForm" className="py-2 px-0 p-md-4"
                    onSubmit={handleChangePassword}
                >
                    <FloatingLabel controlId="currentPassword" label='Current Password'>
                            <Form.Control className="mb-3" type="password" placeholder="Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required />
                        </FloatingLabel>


                    {/* Password */}
                        <FloatingLabel controlId="floatingPassword" label='New Password'>
                            <Form.Control className="mb-3" type="password" placeholder="Password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} required />
                        </FloatingLabel>

                    {/* Confirm Password */}
                        <FloatingLabel controlId="confirmPassword" label='Confirm New Password'>
                            <Form.Control className="mb-3" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                        </FloatingLabel>

                    {/* Display Error */}
                    {formError && <div className="text-danger mt-2 fs-6"><small>{formError}</small></div>}
                </Form>
                )}
                </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!success && (
          <Button type="submit" form="passwordChangeForm" id="edit_password_button" variant="primary" disabled={(success || isLoading)}>
                        Set New Password
            </Button>)}
        </Modal.Footer>
      </Modal>
        </>
    );
}
