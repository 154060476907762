import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

export const MainShadowBox = (props) => {
    return (
        <>
            <div className="row w-100 mt-2 d-flex justify-content-between">
                <div className="col">
                    <h1 className="purple_text fw-lighter">{props.title}</h1>
                    {props.statusBadge && (
                    <h5 className="text-secondary fw-lighter text-capitalize">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                        <Tooltip id={`statusBadge${props.statusBadgeLabel}`}>
                            {props.statusBadgeLabel}
                        </Tooltip>
                        }
                    >
                        <Badge pill bg={props.statusBadgeColour}>
                        {props.statusBadge}
                        </Badge>
                    </OverlayTrigger>
                    </h5>
                )}
                </div>
                {props.barcodetext && (<div className="d-none d-sm-block col-auto align-self-end pb-2 text-secondary text-wrap" style={{fontSize: '8pt'}}>
                    {props.barcodetext}
                </div>)}
            </div>
            {props.children && (<div className="row w-100">
                <div className="col-12 bg-body shadow-sm rounded-4 p-4">
                    {props.children}
                </div>
            </div>)}
        </>
    );
}