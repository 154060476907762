import React, { useState, useEffect, useCallback } from 'react';
import { Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MainShadowBox } from './utilities/mainShadowBox';
import { useIdentityContext } from '../identity-react';
import LoadingSpinner from './utilities/loadingSpinner';

export function SetAvails () {
    const identity = useIdentityContext();
    const [selectedDates, setSelectedDates] = useState([]);
    const [currentDates, setCurrentDates] = useState([]);
    const [availsData, setAvailsData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [createAbsence, setCreateAbsence] = useState(false);
    const [reason, setReason] = useState('');

    const fetchAvails = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await identity.authorizedFetch(`/app_api/teacherAvails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            const skValues = data.map(item => item.SK); // Extract SK values
            setCurrentDates(skValues);

            const dateClassMap = data.map(item => ({
                date: item.SK,
                holidayName: item.Reason,
                className: item.ReasonCode
            }));

            setAvailsData(dateClassMap);
        } catch (error) {
            console.error('Error fetching avails data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [identity]); 

    useEffect(() => {
        fetchAvails();
    }, [identity, fetchAvails]);

    const handleDateChange = (dates) => {
        setSelectedDates(dates);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        const formattedDates = selectedDates.map(dateString => {
            return isoDate(dateString);
        });
    
        try {
            const response = await identity.authorizedFetch('/app_api/teacherAvails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"#Dates": formattedDates, '#Reason': reason})
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            await response.json();
            setSelectedDates([]);
            setReason('');
            setCreateAbsence(false);
            fetchAvails();
        } catch (error) {
            console.error('Error creating absence:', error);
            setIsLoading(false);
        }
    };

    const handleDelete = async (sk) => {
        setIsLoading(true);

        try {
            const response = await identity.authorizedFetch('/app_api/teacherAvails', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "#Date": sk })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await response.json();
            fetchAvails(); // Refresh the data after successful deletion
        } catch (error) {
            console.error('Error deleting date:', error);
            setIsLoading(false);
        }
    };
    
    

    const today = new Date();

    const isoDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const dayClassName = (date) => {
        const formattedDate = isoDate(date);
        const dateClass = availsData.find(d => d.date === formattedDate);
        return dateClass ? dateClass.className : '';
    };

    return (
        <>
        <MainShadowBox title="Set Availiability">
            {isLoading ? (<LoadingSpinner />) 
                  : (<Row>

                    <Col className="text-center" sm={4}>
                        <DatePicker
                            calendarClassName="display-datepicker"
                            selectsMultiple
                            shouldCloseOnSelect={false}
                            disabledKeyboardNavigation
                            minDate={today}
                            maxDate={new Date(today.getTime() + (90 * 24 * 60 * 60 * 1000))}
                            calendarStartDay={1}
                            dayClassName={dayClassName}
                            holidays={availsData}
                            inline
                            />

                            <Row className="m-1 gap-1">

                            <Col xs={12} className="d-flex align-center text-center">
                            <p className="text-secondary ms-2 my-0"><small>Key:</small></p>
                            </Col>

                            <Col xs={6} sm={12} className="d-flex align-center text-center">
                            <div style={{width: '1.5rem', height: '1.5rem'}} className="user_absence rounded mx-2" />
                            <p className="text-secondary fw-lighter mx-2 my-0"><small>Set as Unavailiable</small></p>
                            </Col>

                            <Col xs={6} sm={12} className="d-flex align-center text-center">
                            <div style={{width: '1.5rem', height: '1.5rem'}} className="job_confirmed rounded mx-2" />
                            <p className="text-secondary fw-lighter mx-2 my-0"><small>Job Confirmed</small></p>
                            </Col>

                            <Col xs={6} sm={12} className="d-flex align-center text-center">
                            <div style={{width: '1.5rem', height: '1.5rem'}} className="job_pending rounded mx-2" />
                            <p className="text-secondary fw-lighter mx-2 my-0"><small>Job Pending</small></p>
                            </Col>

                            </Row>
                    </Col>

                    <Col>
                        {availsData && (
                            <><Row className="my-3 text-center fw-bold">
                            <Col>
                            Dates Currently Set as Unavailable:
                            </Col>
                            </Row>
                            
                            {availsData
                                .filter(uDate => uDate.className === "user_absence")
                                .map((uDate, index) => (
                                    <Row key={index} className="my-2 text-center">
                                        <Col>{uDate.date}</Col>
                                        <Col>{uDate.holidayName}</Col>
                                        <Col>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`${index}DeleteTooltip`}>Delete</Tooltip>}>
                                                <Button variant="outline-danger" id={`${index}DeleteBtn`} onClick={() => handleDelete(uDate.date)} size="sm">
                                                    <i className="bi bi-calendar2-x"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                ))
                            }</>)}
                        {createAbsence && (
                            <Row className="my-4 text-center">
                            <Col>
                                <DatePicker className="custom-datepicker"
                                selectedDates={selectedDates}
                                selectsMultiple
                                onChange={handleDateChange}
                                shouldCloseOnSelect={false}
                                disabledKeyboardNavigation
                                minDate={today}
                                maxDate={new Date(today.getTime() + (90 * 24 * 60 * 60 * 1000))}
                                calendarStartDay={1}
                                excludeDates={currentDates}
                                dayClassName={dayClassName}
                                holidays={availsData}
                                placeholderText="Select Date(s)"
                                />
                            </Col>
                            <Col>
                                    <Form.Control
                                        placeholder="Reason (optional)"
                                        type="text"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                            </Col>
                            <Col>
                                <Button variant="outline-success mb-2 me-0 mb-md-0 me-md-2" id={'createAbsenceButton'} onClick={handleSubmit} size="sm"><i className="bi bi-calendar2-plus me-2"></i>Add</Button>
                                <Button variant="outline-secondary" id={'cancelAbsenceCreate'} onClick={() => setCreateAbsence(false)} size="sm">Cancel</Button>
                            </Col>
                        </Row>
                        )}
                        {!createAbsence && <Row className="my-2 text-center"><Col>
                            <Button variant="outline-secondary" id="update_button" onClick={() => setCreateAbsence(true)}>
                                    <i className="bi bi-calendar2-plus me-2"></i>
                                    Create a new Absence
                            </Button>
                        </Col></Row>}
                    </Col>
                    </Row>
                )}
        </MainShadowBox>
        </>
    )
};
