import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { MainShadowBox } from './utilities/mainShadowBox';
import { SearchComponent } from './searchComponent';

export function ListAllEntities () {
    const [activeButton, setActiveButton] = useState('Database');

    const handleButtonClick = (pageName) => {
        setActiveButton(pageName);
      };

    return (
        <>
        <MainShadowBox title={`Search ${activeButton}`}>
        <ButtonGroup className="w-100" aria-label="Basic example">
            <Button
                variant={activeButton === 'Teachers' ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick('Teachers')}
            >
                Teachers
            </Button>
            <Button
                variant={activeButton === 'Schools' ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick('Schools')}
            >
                Schools
            </Button>
            <Button
                variant={activeButton === 'School Users' ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick('School Users')}
            >
                School Users
            </Button>
            <Button
                variant={activeButton === 'Staff' ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick('Staff')}
            >
                Staff
            </Button>
        </ButtonGroup>

        {activeButton === "Teachers" ? <SearchComponent key='teachers' initialParam={["Name"]} fullParamsList={{ "Name": "#Name", "Education Level": "#EducationLevel", "Year Groups": "#YearGroups", "Subject Specialism": "#SubjectSpec", "Experience With": "#ExtraExperience" }} userType="teacher" />
        : activeButton === "Schools" ? <SearchComponent key='schools' initialParam={[]} fullParamsList={{ "School Name": "#SchoolName", "Education Level": "#EducationLevel" }} userType="school" />
        : activeButton === "School Users" ? <SearchComponent key='schoolUsers' initialParam={["Name"]} fullParamsList={{ "Name": "#Name" }} userType="schooluser" />
        : activeButton === "Staff" ? <SearchComponent key='staff' initialParam={["Name"]} fullParamsList={{ "Name": "#Name" }} userType="staff" />
        : <></>}
        </MainShadowBox>
        </>
    )
};
