import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { removeNotificationById } from '../../features/alerts/alertsSlice';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

function NotificationModule() {
  const notifications = useSelector((state) => state.alerts.notifications);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, forceUpdate] = useState();

  useEffect(() => {
    const timer = setInterval(() => {
      forceUpdate({});
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  const handleRemoveNotification = (notificationId, event) => {
      if (event) {
        event.stopPropagation();
      }
      dispatch(removeNotificationById(notificationId));
  };

  const handleNotificationClick = (notificationId, LinkType) => {
    if (LinkType === "linkToJobPage") {
      navigateToJob(notificationId);
      dispatch(removeNotificationById(notificationId));
    } else if (LinkType === "linkToApprovals") {
      navigate('/approvalrequests', {
        state: {
            userType: 'school_pending',
        }
    });
      dispatch(removeNotificationById(notificationId));
    }
  };

  const navigateToJob = (rawInput) => {
    if (!rawInput) {
      return;
    }
    const parts = rawInput.split('#');
    const rawJobId  = parts[3];
    const rawSchoolId  = parts[1];
    navigate(`/job/${rawJobId}`, {
      state: {
          originPage: 'Notification',
          schoolId: `school#${rawSchoolId}`,
      }
    });
  };

    const toastContainerStyle = {
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
        zIndex: 1050
    };

    //add this to end of toast for autohide behaviour delay={1000} autohide
    //key={notification?.id}

    return (
    <ToastContainer className="p-3" position="bottom-end" style={toastContainerStyle}>
    {notifications.map(notification => (
      <Toast key={notification?.id} style={{cursor: "pointer"}} onClick={() => handleNotificationClick(notification?.id, notification?.notificationLinkType)} className="border-0 toastMobile" onClose={(event) => handleRemoveNotification(notification?.id, event)} delay={7000} autohide={notification?.dontPersist}>
      <Toast.Header>
      <Spinner animation="grow" variant={notification?.tagColour ? notification.tagColour : 'danger'} size="sm" className="me-2" />
        <strong className="me-auto">{notification?.notificationTitle}</strong>
        <small className="text-muted">{formatDistanceToNow(notification?.notificationTime, { addSuffix: true })}</small>
      </Toast.Header>
      <Toast.Body  className="d-flex"><span className="me-auto">{notification?.notificationMessage}</span>
      <i className="bi bi-arrow-right-circle text-muted"></i>
      </Toast.Body>
    </Toast>
    ))}
    </ToastContainer>
    );
}

export default NotificationModule;