import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar/sidebar';
import { ActivePane } from './components/activePane';
import Login from './components/login';
import SignUp from './components/Signup/signUp';
import { useIdentityContext } from './identity-react';
import { fetchSchools, fetchUserType, schoolIsLoading } from './features/schools/schoolsSlice';
import { fetchTheme, displayIsLoading } from './features/display/displaySlice';
import './App.css';
import './components/mainStyles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginBox from './components/utilities/loginBox';
import CheckAccessLevel from './components/utilities/checkAccessLevel';
import { SchoolSwitcher } from './components/utilities/schoolSwitcher';
import { RequestReactivationButton } from './components/utilities/actionButtons/requestReactivationButton';
import { fetchAlerts } from './features/alerts/alertsSlice';
import HelmetIcons from './components/utilities/helmetIcons';
import { useWebSocket } from './components/utilities/useWebsocket';
import NotificationModule from './components/utilities/notificationModule';

function App() {
  const identity = useIdentityContext();
  const dispatch = useDispatch();
  const getCombinedLoading = (state) => displayIsLoading(state) || schoolIsLoading(state);
  const isLoading = useSelector(getCombinedLoading);
  const selectedSchool = useSelector((state) => state.schools.selectedSchool);
  //const schoolPermissionLevel = useSelector((state) => state.schools.selectedSchool?.AccessLevel);
  //const selectedSchoolAccess = useSelector((state) => state.schools.selectedSchool?.classifictionGSI);
  //const selectedSchoolPK = useSelector((state) => state.schools.selectedSchool?.PK);
  const schoolList = useSelector((state) => state.schools.schoolList);
  const userType = useSelector((state) => state.schools.userType);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const memoizedUserId = useMemo(() => identity?.user?.id, [identity?.user?.id]);
  const shallowUserType = useSelector((state) => state.schools.userType, shallowEqual);

  useWebSocket(memoizedUserId, shallowUserType);

  useEffect(() => {
    dispatch(fetchTheme());
  }, [dispatch]);

  useEffect(() => {
    if (identity?.user?.id) {
      dispatch(fetchUserType(identity));
      dispatch(fetchSchools(identity));
      dispatch(fetchAlerts(identity));
    }
  }, [identity, dispatch]);

  const renderLoading = () => (
    <div className="container mt-3 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Spinner animation="border" size="lg" className="purple_spinner" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  const handleRefreshClick = () => {
    setRefreshLoading(true);
    identity.authorizedFetch('/app_api/pingHello', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      }
  })
  .then(identity.refreshUser)
  .catch(error => {
      console.error('Error refreshing ping:', error);
  })
  .finally(setRefreshLoading(false));
};

  const renderStop = (icon, header, message, reactivateOption, personalise, showRefresh) => (
    <LoginBox>
      <h2><i className={icon}></i></h2>
      <h5>{header}</h5>
      <p>{personalise && ((selectedSchool?.classifictionGSI === "school_deactivated" || selectedSchool?.classifictionGSI === "school_pending") ? selectedSchool?.FullName : 'Your account')}{message}</p>
      <div className="d-flex flex-column gap-2 w-100 justify-content-center mb-3 mb-md-0">
      {schoolList && schoolList.length > 1 && <><SchoolSwitcher linkStyle='smallButton' /></>}
      {reactivateOption && userType && identity.user && <RequestReactivationButton userId={userType === "school" ? selectedSchool?.PK : (userType === "staff" || userType === "teacher") ? `${userType}#${identity.user.id}` : null} />}
      {identity.user && (<>
        <div className="d-flex gap-1">
        {showRefresh && (<OverlayTrigger
                placement="top"
                overlay={<Tooltip id="refreshPageTooltip">Refresh Status</Tooltip>}
              ><Button size="sm" variant="light" disabled={refreshLoading} onClick={handleRefreshClick}>{refreshLoading ? <Spinner size="sm" />: <i className="bi bi-arrow-clockwise"></i>}</Button>
              </OverlayTrigger>)}
      <Button className="flex-grow-1 w-100" size="sm" variant="light" onClick={identity.logout}>Sign out</Button></div></>)}
      </div>
    </LoginBox>
  );

  const renderMainContent = () => (
    <main className="d-flex flex-wrap flex-md-nowrap">
      <NotificationModule alertId="a" alertHeading="a" />
      <HelmetIcons />
      <Sidebar />
      <ActivePane />
    </main>
  );

  const renderContent = () => {
    if (!identity.user || identity.urlToken) {
      return <LoginBox><Login /></LoginBox>;
    }

    const { roles } = identity.user.app_metadata || {};
    if (!roles || roles.includes('block')) {
      return renderStop("bi bi-person-slash", "Error", "You are not authorized to view this page.");
    }

    if (roles.includes('deactivated') || selectedSchool?.classifictionGSI === "school_deactivated" || selectedSchool?.AccessLevel === "deactivated") {
      return renderStop("bi bi-exclamation-circle", "Account Deactivated", " has been deactivated.", true, true);
    }

    if (roles.includes('rejected') || selectedSchool?.classifictionGSI === "school_rejected") {
      return renderStop("bi bi-exclamation-circle", "Account Rejected", " has been rejected. Please check your profile details and resubmit.", true, true);
    }

    if ((roles.includes('pending') && !roles.includes('school')) || (selectedSchool?.AccessLevel && !CheckAccessLevel('viewer', selectedSchool?.AccessLevel)) || selectedSchool?.classifictionGSI === "school_pending") {
      return renderStop("bi bi-hourglass-split", "Awaiting Approval", " has been submitted for approval and is currently under review.", false, true, true);
    }

    if (roles.includes('approved')) {
      return renderMainContent();
    }

    if (roles.includes('starter')) {
      return <SignUp />;
    }

    return renderStop("bi bi-person-slash", "Error", "You are not authorized to view this page.");
  };

  const renderAdminContent = () => {
    if (!identity.user || identity.urlToken) {
      return <LoginBox><Login /></LoginBox>;
    }

    const { roles } = identity.user.app_metadata || {};
    if (!roles || roles.includes('block')) {
      return renderStop("bi bi-person-slash", "Error", "You are not authorized to view this page.");
    }

    if (roles.includes('starter')) {
      return <SignUp signUpType='Staff' />;
    }

    return renderStop("bi bi-person-slash", "Error", "You are not authorized to view this page.");
  };

  const renderLegals = () => {
    return <LoginBox noLegals={true}>
      <div className="d-flex flex-column gap-2 w-100 justify-content-center">
      <Button className="flex-grow-1 w-100" size="sm" variant="light" target="_blank" href="https://www.teacherflow.co.uk/privacypolicy">TeacherFlow Privacy Policy</Button>
      <Button className="flex-grow-1 w-100" size="sm" variant="light" target="_blank" href="https://www.teacherflow.co.uk/terms">TeacherFlow Terms of Service</Button>
      </div>
    </LoginBox>;
  };

  return (
    <Router>
      {isLoading ? renderLoading() : (
        <Routes>
          <Route path="/*" element={renderContent()} />
          <Route path="/:pageName" element={renderContent()}>
            <Route path=":jobId" element={renderContent()} />
          </Route>
          <Route path="/staffsignup" element={renderAdminContent()} />
          <Route path="/terms" element={renderLegals()} />
        </Routes>
      )}
    </Router>
  );
}

export default App;