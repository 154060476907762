import React from 'react';
import { useSelector } from 'react-redux';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export function NavLinks({ links }) {
  const isCollapsed = useSelector((state) => state.display.is_collapsed);
  const navigate = useNavigate();

  const handleClick = (eventKey) => {
    navigate(`/${eventKey}`);
  };

  //<><i className={`transition-col ${link.icon} ${!isCollapsed && 'me-2'}`}></i>{!isCollapsed && link.label}</>

  return (
    <>
      {links.map(link => (
        <OverlayTrigger
          key={link.key}
          placement="right"
          overlay={isCollapsed ? <Tooltip id={`${link.key}#tooltip`}>{link.tooltip}</Tooltip> : <></>}
        >
          <Nav.Link onClick={() => handleClick(link.key)} className="contrast_theme_text" key={link.key} eventKey={link.key}>
            <>
            <i
              className={`nav-transition ${link.icon} ${isCollapsed ? 'translate-out' : 'translate-in'} ${!isCollapsed ? 'me-2' : ''}`}
            ></i>
            <span className={`textTransition ${isCollapsed && 'close'}`}>
              {link.label}
            </span>
            </>
            
          </Nav.Link>
        </OverlayTrigger>
      ))}
    </>
  );
}