import React from 'react';
import { DisplayUserProfileSections } from '../utilities/displayUserProfileSections';
import { Badge, Col, Row } from 'react-bootstrap';
import { EditImage } from '../utilities/editImage';

export function TeacherProfileView( {inputData, formLock, noEdit, masterOveride} ) {
    return (
        <>
            <Row className="mb-2">
                <Col className="text-secondary" sm={3}>Profile Picture:</Col>
                <Col sm={3}>
                    <EditImage origImage={inputData.ProfileImage} aspectRatio={1 / 1} shape={'roundedCircle'} dynamoImageAttr={'ProfileImage'} entryType={'teacher'} format={'image/jpeg'} noEdit={noEdit} />
                </Col>
            </Row>
            <DisplayUserProfileSections label="Email:" data={inputData.Email} />
            {(!masterOveride || formLock) && (<>
            <DisplayUserProfileSections label="Full Name:" data={inputData.FullName} />
            <DisplayUserProfileSections label="Teacher Reference Number:" data={inputData.TRN} />
            <DisplayUserProfileSections label="DBS Number:" data={inputData.DBS} />
            <DisplayUserProfileSections label="Date of Birth:" data={inputData.DOB} />
            </>)}
            {!noEdit && (!masterOveride || !formLock) && <hr className="border-dark-subtle" />}
            {formLock && (<>
            <DisplayUserProfileSections label="Address:" data={inputData.Address} />
            <DisplayUserProfileSections label="Postcode:" data={inputData.Postcode} />
            <DisplayUserProfileSections label="Phone Number:" data={inputData.PhoneNumber} />
            <DisplayUserProfileSections label="Education Level:" data={inputData.EducationLevel} />
            <Row className="mb-3">
                <Col className="text-secondary" sm={3}>Year Groups:</Col>
                <Col>{inputData.YearGroups.map((item, index) => (<Badge pill bg="success" key={index} className="m-1"><i className="bi bi-check2-circle me-2"></i>{item}</Badge>))}</Col>
            </Row>
            <DisplayUserProfileSections label="Bio:" data={inputData.Bio} />
            <Row className="mb-3">
                <Col className="text-secondary" sm={3}>Subject Specialism:</Col>
                <Col>{inputData.SubjectSpecialism.map((item, index) => (<Badge pill bg="danger" key={index} className="m-1"><i className="bi bi-check2-circle me-2"></i>{item}</Badge>))}</Col>
            </Row>
            <Row className="mb-3">
                <Col className="text-secondary" sm={3}>Previous Experience:</Col>
                <Col>{inputData.ExtraExperience.map((item, index) => (<Badge pill bg="warning" key={index} className="m-1"><i className="bi bi-check2-circle me-2"></i>{item}</Badge>))}</Col>
            </Row>
            <DisplayUserProfileSections label="Day Rate:" data={"£" + inputData.DayRate} />
            <DisplayUserProfileSections label="Teaching Experience:" data={inputData.YearsExperience + " Years"} /></>)}
        </>
    );
}
