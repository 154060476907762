import React from 'react';
import { Image, Col, Row, Badge } from 'react-bootstrap';
import { DisplayUserProfileSections } from './utilities/displayUserProfileSections';
import { ListFunctionButtons } from './utilities/actionButtons/listFunctionButtons';


export const DisplaySchool = ({ result, functionButton, afterEffects, hideImage }) => {
    return (
        <Row className="justify-content-center gap-3 gap-sm-0">
            {!hideImage && <Col xs={10} sm={3} className="d-flex align-items-center justify-content-center">
                {result.SchoolLogo && <Image className="w-100" alt="profile_image" src={result.SchoolLogo} rounded />}
            </Col>}
            <Col sm={7} className="d-flex flex-column justify-content-center flex-grow-1">
                <Row>
                    <Col sm={12} className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        <h5 className="mb-0">{result.FullName}</h5>
                        {result.EducationLevel && (
                            <p className="fw-light mb-0 align-self-center">   
                            {result.EducationLevel.map((level, index) => (
                                <span key={index}>
                                    {index > 0 && ", "}{level}
                                </span>
                            ))}
                            </p>
                        )}
                    </Col>
                    <Col sm={12} className="d-flex justify-content-between align-items-center">
                                <small>
                                    <DisplayUserProfileSections data={result.Address} noMargin={true} />
                                    {result.Postcode}
                                </small>
                                <small>
                                    {result.OfstedRating && (<p className="text-end text-secondary m-0">
                                    <Badge><span className="fw-light me-1 text-warning">Ofsted:</span>{result.OfstedRating}</Badge>
                                    </p>)}
                                    {result.Email && (<p className="text-end text-secondary m-0">
                                    {result.Email}</p>)}
                                    {result.PhoneNumber && (<p className="text-end text-secondary m-0">
                                    {result.PhoneNumber}</p>)}
                                    {result.Website && (<p className="text-end text-secondary m-0">
                                    <a className="icon-link icon-link-hover link-underline-light" href={result.Website} target="_blank" rel="noreferrer">Website<i className="bi bi-link-45deg"></i></a>
                                    </p>)}
                                    {result.Headteacher && (
                                        <p className="text-end text-secondary m-0">
                                    <span className="fw-lighter me-1">Headteacher/Principal:</span>{result.Headteacher}</p>
                                    )}
                                </small>
                    </Col>
                </Row>
            </Col>
            {functionButton && <ListFunctionButtons functionButton={functionButton} result={result} afterEffects={afterEffects} />}
            </Row>
    );
};