const getCroppedImg = async (imageSrc, crop, format) => {
  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  // Create a canvas with a size large enough to maintain quality
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', { alpha: true });
  canvas.width = crop.width * 2; // Double the size for higher quality
  canvas.height = crop.height * 2;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // Create a new canvas with the desired dimensions for the final output
  const resizedCanvas = document.createElement('canvas');
  resizedCanvas.width = 500;
  resizedCanvas.height = (crop.height / crop.width) * 500;
  const resizedCtx = resizedCanvas.getContext('2d', { alpha: true });
  resizedCtx.clearRect(0, 0, resizedCanvas.width, resizedCanvas.height);
  resizedCtx.drawImage(canvas, 0, 0, 500, resizedCanvas.height);

  // Convert the resized canvas to a data URL with higher quality
  return resizedCanvas.toDataURL(format, 1); // Adjust quality as needed
};

export default getCroppedImg;