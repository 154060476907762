import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PhotoUpload from '../utilities/photoUpload';
import { FormNameGeneric, FormEmailGeneric, FormAddress, FormPhoneNumberGeneric, FormPostcode, FormWebsite, FormOfstedRating, FormEducationLevel } from './allFormComponents';
import { SchoolURNScrape } from './schoolURNScrape';

export function SchoolCreateSection({ formData, addNewSchool, handleFormChange, handleImageCropUpload, wideMode, setFormData }) {
    return (
        <>
            <SchoolURNScrape formData={formData} required={addNewSchool} handleFormChange={handleFormChange} setFormData={setFormData} />

            <FormNameGeneric label="School Name:" handleFormChange={handleFormChange} formDataSection={formData['#SchoolName']} controlId="#SchoolName" required={addNewSchool} wideMode={wideMode} />
            <Form.Group as={Row} className="mb-3" controlId="#ImageUpload">
                <Form.Label column sm={3}>School Logo:</Form.Label>
                <Col sm={4}>
                    <PhotoUpload onImageCropUpload={handleImageCropUpload} aspectRatio={3 / 1} isRequired={addNewSchool} format={'image/png'} allowZoomOut={true} />
                </Col>
            </Form.Group>
            <FormNameGeneric label="Headteacher/Principal:" handleFormChange={handleFormChange} formDataSection={formData['#Headteacher']} controlId="#Headteacher" required={addNewSchool} wideMode={wideMode} />
            <FormEmailGeneric label="Office Email:" handleFormChange={handleFormChange} formDataSection={formData['#SchoolEmail']} controlId="#SchoolEmail" required={addNewSchool} wideMode={wideMode} />
            <FormAddress label="School Address:" handleFormChange={handleFormChange} formData={formData} required={addNewSchool} wideMode={wideMode} />
            <FormPostcode label="School Postcode:" handleFormChange={handleFormChange} formData={formData} required={addNewSchool} wideMode={wideMode} />
            <FormPhoneNumberGeneric label="Office Phone Number:" handleFormChange={handleFormChange} formDataSection={formData['#SchoolPhoneNumber']} controlId='#SchoolPhoneNumber' required={addNewSchool} wideMode={wideMode} />
            <FormWebsite label="School Website:" handleFormChange={handleFormChange} formData={formData} required={addNewSchool} />
            <FormEducationLevel label="Education Level:" handleFormChange={handleFormChange} formData={formData} required={addNewSchool} />
            <FormOfstedRating label="Ofsted Rating:" handleFormChange={handleFormChange} formData={formData} required={addNewSchool} />
        </>
    )
};