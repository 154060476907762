import React, { useState, useEffect } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import '../mainStyles.css';
import { useIdentityContext } from '../../identity-react';

const defaultExcludedSchools = [];

export function SchoolSelector({ chosenSchool, setChosenSchool, setAddNewSchool, excludedSchools = defaultExcludedSchools }) {
    const [schoolList, setSchoolList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const identity = useIdentityContext();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await identity.authorizedFetch('/app_api/listSchools', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                
                const excludedSchoolPKs = excludedSchools.map(school => school.PK);
                
                const filteredData = data.Items.filter(
                    school => !excludedSchoolPKs.includes(school.PK)
                );

                setSchoolList(filteredData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching school selection data:', error);
            }
        };

        fetchUserData();
    }, [identity, excludedSchools]);

    const filteredSchools = searchText ? schoolList.filter(school => school.FullName.toLowerCase().includes(searchText.toLowerCase())) : [];

    const handleSelectSchool = (school) => {
        setChosenSchool(school);
        setAddNewSchool(!school);
    };

    return (
        <>
                    <Dropdown>
                        <Dropdown.Toggle className="w-100" variant="primary" id="dropdown-basic" disabled={loading}>
                            {loading ? "Loading..." : chosenSchool ? chosenSchool.FullName : "Select School"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3 w-100">
                            <FormControl
                                type="text"
                                placeholder="Search..."
                                className="mr-sm-2"
                                onChange={e => setSearchText(e.target.value)}
                                isInvalid={false}
                                isValid={false}
                            />
                            {searchText && <Dropdown.Divider />}
                            {filteredSchools.map((school) => (
                                <Dropdown.Item key={school.PK} onClick={() => handleSelectSchool(school)}>
                                    {school.FullName}
                                </Dropdown.Item>
                            ))}
                            {searchText && (<><Dropdown.Divider />
                            <Dropdown.Item key="add school" onClick={() => handleSelectSchool(null)}>Can't see your  school? Add School</Dropdown.Item></>)}
                        </Dropdown.Menu>
                    </Dropdown>
        </>
    );
}
