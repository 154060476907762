import React from 'react';
import { Badge, Row } from 'react-bootstrap';

export const MainSectionBox = (props) => {
    return (
        <>
            <Row className="border rounded-3 p-2 mx-1 mt-4 position-relative">
                {props.title && (<div className="position-absolute translate-middle" style={{ top: '0', left: '55%' }}>
                    <Badge id="TitleBadge" bg={props.titleColour ? props.titleColour : 'dark-subtle'} pill className="px-5 py-2 text-wrap">{props.title}{props.subtitle && <span className="fw-lighter ms-2">{props.subtitle}</span>}</Badge>
                </div>)}
                    {props.children}
            </Row>
        </>
    );
}