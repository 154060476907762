import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addAlert } from '../alerts/alertsSlice';

const initialState = {
    schoolList: null,
    userType: null,
    selectedSchool: null,
    userTypeLoading: false,
    schoolListLoading: false,
    error: null,
  };

export const fetchSchools = createAsyncThunk(
    'schools/fetchSchools',
    async (identity, thunkAPI) => {
      if (identity?.user?.app_metadata?.roles.includes("school")) {
          try {
            const response = await identity.authorizedFetch('/app_api/user/listSchools', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error('Failed to fetch school data');
            }
            const data = await response.json();
            return data; // This will be the payload of the fulfilled action
          } catch (error) {
            return thunkAPI.rejectWithValue(error.message); // This will be the payload of the rejected action
          }
        }
      else {
        return thunkAPI.rejectWithValue("not school user");
      }
    }
  );

  export const fetchUserType = createAsyncThunk(
    'schools/fetchUserType',
    async (identity, { rejectWithValue }) => {
      try {
        if (
          identity?.user?.app_metadata?.roles?.includes("school")
        ) {
          return 'school';
        } else if (
          identity?.user?.app_metadata?.roles?.includes("teacher")
        ) {
          return 'teacher';
        } else if (
          identity?.user?.app_metadata?.roles?.includes("staff")
        ) {
          return 'staff';
        } else {
          return rejectWithValue('Unknown user type');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const setDefaultSchool = createAsyncThunk(
    'schools/setDefaultSchool',
    async ({ identity, newDefault, currentDefault }, thunkAPI) => {
      try {
        // Making the API call to set the default school
        const response = await identity.authorizedFetch('/app_api/setDefaultSchool', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            defaultSchool: newDefault,
            currentDefaultList: currentDefault
          }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to set a new default school');
        }
  
        await response.json();

        thunkAPI.dispatch(addAlert({
          id: 'newDefaultSuccess',
          variant: 'success',
          message: 'Your default school was successfully updated.',
          dismissible: true
        }));
  
        // Dispatch fetchSchools after setting the default school successfully
        thunkAPI.dispatch(fetchSchools(identity));
  
        return newDefault; // This will be the payload of the fulfilled action
      } catch (error) {
        console.error("Error 2103:", error);
        return thunkAPI.rejectWithValue(error.message); // This will be the payload of the rejected action
      }
    }
  );


export const schoolsSlice = createSlice({
    name: 'schools',
    initialState,
    reducers: {
      setSelectedSchool: (state, action) => {
          state.selectedSchool = state.schoolList.find(entry => entry.PK === action.payload);
      },
      logoutResetSchools: (state) => {
          state.schoolList = initialState.schoolList;
          state.userType = initialState.userType;
          state.selectedSchool = initialState.selectedSchool;
          state.error = initialState.error;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchSchools.pending, (state) => {
          state.schoolListLoading = true;
          state.error = null;
        })
        .addCase(fetchSchools.fulfilled, (state, action) => {
          state.schoolList = action.payload;
          const defaultSchool = action.payload?.find(school => school.DefaultSchool === true);
          const teacherSchool = action.payload?.find(school => school.classifictionGSI === "school" && school.AccessLevel !== "requested");
          state.selectedSchool = defaultSchool || teacherSchool || (action.payload?.length > 0 ? action.payload[0] : null);
          state.schoolListLoading = false;
        })
        .addCase(fetchSchools.rejected, (state, action) => {
          state.schoolListLoading = false;
          state.selectedSchool = null;
          state.error = action.payload;
        })
        .addCase(fetchUserType.pending, (state) => {
          state.userTypeLoading = true;
          state.error = null;
        })
        .addCase(fetchUserType.fulfilled, (state, action) => {
          state.userType = action.payload;
          state.userTypeLoading = false;
        })
        .addCase(fetchUserType.rejected, (state, action) => {
          state.userTypeLoading = false;
          state.userType = null;
          state.error = action.payload;
        })
        .addCase(setDefaultSchool.pending, (state) => {
          state.schoolListLoading = true;
          state.error = null;
        })
        .addCase(setDefaultSchool.fulfilled, (state) => {
          state.schoolListLoading = false;
        })
        .addCase(setDefaultSchool.rejected, (state, action) => {
          state.schoolListLoading = false;
          state.error = action.payload;
        });
    },
  });

export const listSchools = (state) => state.schools.schoolList;
export const schoolIsLoading = (state) => state.schools.schoolListLoading || state.schools.userTypeLoading;

export const { setSelectedSchool, logoutResetSchools } = schoolsSlice.actions;

export default schoolsSlice.reducer;