import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAppLogo, getColourSet } from '../../features/display/displaySlice';
import { Helmet } from 'react-helmet';


function HelmetIcons() {
    const [squareLogo, setSquareLogo] = useState(null);
    const appLogo = useSelector(getAppLogo);
    const colourSet = useSelector(getColourSet);
    const memoizedAppLogo = useMemo(() => appLogo, [appLogo]);
    const memoizedColourSet = useMemo(() => colourSet, [colourSet]);

    useEffect(() => {
        if (memoizedAppLogo && memoizedColourSet?.coreColour) {
            convertToSquareLogo(memoizedAppLogo, memoizedColourSet?.coreColour, memoizedColourSet?.colour2);
        }
    }, [memoizedAppLogo, memoizedColourSet]);

    const convertToSquareLogo = async (logoUrl, bgColour, secondColour) => {
        try {
            const response = await fetch('app_api/squareImage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imageUrl: logoUrl,
                    size: 500, // Set the size to your desired square dimensions
                    mainColor: bgColour, // Set the background color
                    secondColor: secondColour
                }),
            });

            const data = await response.json();
            setSquareLogo(data.imageUrl);
        } catch (error) {
            console.error('Error converting logo:', error);
        }
    };


    return (
        <>
            <Helmet>
                <link rel="apple-touch-icon" href={squareLogo} />
            </Helmet>
        </>
    );
};

export default HelmetIcons;