import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { removeAlertById } from '../../features/alerts/alertsSlice';

function AlertModule({ alertId, variant, alertHeading, alertBody, timedRemove, testDisplay }) {
    const dispatch = useDispatch();

  useEffect(() => {
    if (timedRemove) {
      console.log("hello timed remove");
      const timer = setTimeout(() => {
        dispatch(removeAlertById(alertId));
      }, 5000); // 5000 milliseconds = 5 seconds

      return () => {
        clearTimeout(timer); // Clear the timer if the component unmounts or show changes
      };
    }
  }, [timedRemove, alertId, dispatch]);

  const handleRemoveAlert = () => {
    if (!testDisplay) {
      dispatch(removeAlertById(alertId));
    }
  };

  if (alertId && (alertHeading || alertBody)) {
    return (
      <Alert key={alertId} className="w-100" variant={variant} onClose={handleRemoveAlert} dismissible={!testDisplay}>
        {alertHeading && <Alert.Heading>{alertHeading}</Alert.Heading>}
        {alertBody}
      </Alert>
    );
  } else {
    return null; // Return null when show is false or there's no heading/body
  }
}

export default AlertModule;