import React from 'react';
import { DisplayUserProfileSections } from '../utilities/displayUserProfileSections';
import { Col, Row } from 'react-bootstrap';
import { EditImage } from '../utilities/editImage';


export function SchoolProfileView( {inputData, noEdit} ) {
    return (
        <>
            <Row className="mb-2">
                <Col className="text-secondary" sm={3}>School Logo:</Col>
                <Col sm={3}>
                    <EditImage origImage={inputData.SchoolLogo} aspectRatio={3 / 1} shape={'rounded'} dynamoImageAttr={'SchoolLogo'} entryType={'school'} format={'image/png'} allowZoomOut={true} noEdit={noEdit} />
                </Col>
            </Row>
            <DisplayUserProfileSections label="School Name:" data={inputData.FullName} />
            {inputData.SchoolURN && <DisplayUserProfileSections label="School URN:" data={inputData.SchoolURN} />}
            {inputData.Headteacher && <DisplayUserProfileSections label="Headteacher/Principal:" data={inputData.Headteacher} />}
            <DisplayUserProfileSections label="Office Email:" data={inputData.Email} />
            <DisplayUserProfileSections label="Address:" data={inputData.Address} />
            <DisplayUserProfileSections label="Postcode:" data={inputData.Postcode} />
            <DisplayUserProfileSections label="Office Phone Number:" data={inputData.PhoneNumber} />
            {inputData.Website && <DisplayUserProfileSections label="School Website:" data={inputData.Website} />}
            {inputData.EducationLevel && <DisplayUserProfileSections label="Education Level:" data={inputData.EducationLevel} />}
            {inputData.OfstedRating && <DisplayUserProfileSections label="Ofsted Rating:" data={inputData.OfstedRating} />}
        </>
    );
}