import React, { useState } from 'react';
import { Button, Modal, DropdownItem, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from './loadingSpinner';
import { SchoolSelector } from '../Signup/schoolSelector';
import { SchoolCreateSection } from '../Signup/schoolCreateSection';
import { useIdentityContext } from '../../identity-react';
import { addAlert } from '../../features/alerts/alertsSlice';
import { fetchSchools } from '../../features/schools/schoolsSlice';

export function AddNewSchoolModal( { linkStyle } ) {
    const identity = useIdentityContext();

    const [formData, setFormData] = useState("");
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const schoolList = useSelector((state) => state.schools.schoolList);

    const [chosenSchool, setChosenSchool] = useState(null);
    const [addNewSchool, setAddNewSchool] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    const handleFormChange = (e) => {
        const { id, name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: {
                    ...prevFormData[name],
                    [id]: checked,
                },
            }));
        } else if (id === '#Postcode') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.toUpperCase(),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
            }));
        }
    };

    const handleImageCropUpload = (data, id) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            '#ImageUpload': data,
        }));
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!chosenSchool && !addNewSchool) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
        setError(false);

        identity.authorizedFetch('/app_api/addSchool', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({formData: formData, addNewSchool: addNewSchool, chosenSchool: chosenSchool ? chosenSchool.PK : null})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(
            () => {
                const alert = {
                    id: 'newDefaultSuccess',
                    variant: 'success',
                    message: chosenSchool ? 'A request has been sent to that school' : 'A request has been sent to create a new school',
                    dismissible: true
                };
                dispatch(addAlert(alert));
                dispatch(fetchSchools(identity));
            }
        )
        .catch(error => {
            setIsLoading(false);
            setValidated(false);
            setError(true);
            console.error('Error signing up:', error);
        });
    };

    return (
        <>
        {linkStyle === "button" ? (<Button size="sm" variant="outline-primary" className="w-100 mt-3" onClick={handleShow}>
        Add another School
        </Button>) : linkStyle === "dropdownItem" ? (
            <DropdownItem onClick={handleShow}>Add another School</DropdownItem>
        ) :
        (<Button variant="link" onClick={handleShow}>
            Add another School
            </Button>)
        }

        <Modal size={addNewSchool && 'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>Add another School
        </Modal.Header>
        <Modal.Body>
        {isLoading ? (<LoadingSpinner />) 
                  : ( <>
                  <Form noValidate className="py-2 px-0 p-md-2" onSubmit={handleSubmit} validated={validated}>
                  <fieldset>
                    <div className="mb-3">
                    <SchoolSelector chosenSchool={chosenSchool} setAddNewSchool={setAddNewSchool} setChosenSchool={setChosenSchool} addNewSchool={addNewSchool} excludedSchools={schoolList} />
                    {validated && !chosenSchool && !addNewSchool && (
                                <div className="invalid-feedback d-block">Please select a school.</div>
                            )}
                    </div>
                  {addNewSchool && (<>
                    <hr />
                    <SchoolCreateSection setFormData={setFormData} formData={formData} addNewSchool={addNewSchool} handleFormChange={handleFormChange} handleImageCropUpload={handleImageCropUpload} wideMode={true} />
                    <hr /></>)}
                    <Button type="submit" variant="primary" id="#SignUpButton" disabled={addNewSchool && !formData['#ImageUpload']}>{addNewSchool ? 'Create School' : 'Send Request'}</Button>
                    {error && <p className="text-danger pb-0">An error occured. Please try again</p>}
                </fieldset>
            </Form>
        </>)}
        </Modal.Body>
      </Modal>
        </>
    );
}