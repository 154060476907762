import { useState, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { connectWebSocket, closeWebSocket } from './websocketService';

export const useWebSocket = (userId, userType) => {
  const [isConnected, setIsConnected] = useState(false);
  const selectedSchool = useSelector((state) => state.schools.selectedSchool, shallowEqual);
  const memoizedSelectedSchool = useMemo(() => selectedSchool, [selectedSchool]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && userType) {
        if (userType === "school" && memoizedSelectedSchool?.classifictionGSI === "school" && memoizedSelectedSchool?.AccessLevel !== "requested") {
          connectWebSocket('schooluser#' + userId, memoizedSelectedSchool.PK, dispatch);
          setIsConnected(true);
        } else if (userType === "staff") {
          connectWebSocket(userType + '#' + userId, 'staff', dispatch);
          setIsConnected(true);
        } else if (userType === "teacher") {
          connectWebSocket(userType + '#' + userId, null, dispatch);
          setIsConnected(true);
        }
    }
    return () => {
      closeWebSocket();
      setIsConnected(false);
    };
  }, [userId, userType, memoizedSelectedSchool, dispatch]);

  return isConnected;
};