import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ListFunctionButtons } from './utilities/actionButtons/listFunctionButtons';

export const DisplaySchoolUser = ({ result, functionButton, afterEffects }) => {
    return (
        <Row className="justify-content-center gap-3 gap-sm-0">
            <Col sm={9} className="d-flex flex-column justify-content-center flex-grow-1">
                <Row>
                    <Col xs={12} sm={9} className="d-flex flex-column flex-sm-row justify-content-between align-items-center flex-grow-1">
                        <div className="text-center text-sm-start">
                            <h5 className="mb-0">{result.FullName}</h5>
                            {result.Email && (
                                <span className="text-secondary"><small>{result.Email}</small></span>
                            )}
                        </div>
                        <div className="text-center text-sm-end">
                        {result.JobTitle && (
                            <p className="fw-light mb-0 mt-2 mt-sm-0">
                                <span className="text-secondary me-2">Job Title:</span>
                                {result.JobTitle}
                            </p>
                        )}
                        {result.AccessLevel && (
                            <p className="fw-light mb-0 mt-2 mt-sm-0 text-capitalize">
                                <span className="text-secondary me-2">Access Level:</span>
                                {result.AccessLevel}
                            </p>
                        )}
                        </div>
                    </Col>
                </Row>
            </Col>
            {functionButton && (
                <ListFunctionButtons functionButton={functionButton} result={result} afterEffects={afterEffects} />
            )}
        </Row>
    );
};