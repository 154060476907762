import React, {useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SchoolSignUp } from './schoolSignUp';
import { TeacherSignUp } from './teacherSignUp';
import { useSelector } from 'react-redux';
import { useIdentityContext } from '../../identity-react';
import { getAppName } from '../../features/display/displaySlice';
import { StaffSignUp } from './staffSignUp';

function SignUp( {signUpType} ) {
    const identity = useIdentityContext();
    const appName = useSelector(getAppName);
    const [signupSelector, setSignupSelector] = useState(signUpType || null);

    useEffect(() => {
        !signupSelector ? document.title = `Sign Up to ${appName}` : document.title = `Sign Up to ${appName} | ${signupSelector}`;
    }, [signupSelector, appName]);    

    const handleClick = (value) => {
        setSignupSelector(value);
    };

    return (
        <>
        <div className={`container mt-2 d-flex align-items-center ${signupSelector ? 'flex-column' : 'justify-content-center'}`} style={{minHeight: "100vh"}}>
        {signupSelector === "School" ? <SchoolSignUp handleChange={() => handleClick('Teacher')} /> : signupSelector === "Teacher" ? <TeacherSignUp handleChange={() => handleClick('School')} /> : signupSelector === "Staff" ? <StaffSignUp /> : (
        <><div className="row w-100">
            <div className="col-12 col-md-6 mb-2 mb-md-0 align-self-center">
                <h1 className="text-center purple_text fw-lighter">Let's get started {identity.user.user_metadata.full_name}...</h1>
            </div>
            <div className="col-12 col-md-3 mb-2 mb-md-0 align-self-center">
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="list-1">I'm a supply teacher who wants to be placed in schools.</Tooltip>}
                >
                <button onClick={() => handleClick('Teacher')} className="purple-button w-100 p-3"><i className="bi bi-person-video3 me-2"></i>Teacher
                </button>
                </OverlayTrigger>
            </div>
            <div className="col-12 col-md-3 align-self-center">
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="list-1">I'm a school user looking to arrange supply.</Tooltip>}
                >
                <button onClick={() => handleClick('School')} className="purple-button w-100 p-3"><i className="bi bi-buildings me-2"></i>School User
                </button>
                </OverlayTrigger>
            </div>
        </div></>)}

        </div>
        </>
    );
}

export default SignUp;
