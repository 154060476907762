import React, { useState } from 'react';
import { Button, Dropdown, OverlayTrigger, Tooltip, Row, Col, Spinner } from 'react-bootstrap';
import { useIdentityContext } from '../identity-react';
import { FormNameGeneric, FormSubjectSpec, FormEducationLevel, FormExtraExp, FormYearGroups } from './Signup/allFormComponents';
import { DisplaySearchResults } from './displaySearchResults';

export function SearchComponent({ initialParam, fullParamsList, userType }) {
  const identity = useIdentityContext();
  const [formData, setFormData] = useState({});
  const [searchParams, setSearchParams] = useState(initialParam);
  const [searchResults, setSearchResults] = useState();
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const availableParams = Object.keys(fullParamsList).filter(param => !searchParams.includes(param));

  const handleFormChange = (e) => {
    const { id, name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: {
          ...prevFormData[name],
          [id]: checked,
        },
      }));
    } else if (id === '#Postcode') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.toUpperCase(),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: value,
      }));
    }
  };

  const handleRemoveParam = (paramToRemove) => {
    setSearchParams((prevParams) => prevParams.filter(param => param !== paramToRemove));
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      delete newFormData[fullParamsList[paramToRemove]];
      return newFormData;
    });
  };

  const handleAddParam = (param) => {
    setSearchParams((prevParams) => [...prevParams, param]);
  };

  const fetchResults = async (isLoadMore = false) => {
    const queryParams = new URLSearchParams();
    if (formData['#SchoolName']) {
      queryParams.append('fullName', formData['#SchoolName']);
    }
    if (formData['#Name']) {
      queryParams.append('fullName', formData['#Name']);
    }
    if (formData['#SubjectSpec']) {
      let index = 0;
      Object.keys(formData['#SubjectSpec']).forEach(key => {
        if (formData['#SubjectSpec'][key]) {
          queryParams.append(`subjectSpec[${index}]`, key);
          index++;
        }
      });
    }
    if (formData['#EducationLevel']) {
      let index = 0;
      Object.keys(formData['#EducationLevel']).forEach(key => {
        if (formData['#EducationLevel'][key]) {
          queryParams.append(`educationLevel[${index}]`, key);
          index++;
        }
      });
    }
    if (formData['#ExtraExperience']) {
      let index = 0;
      Object.keys(formData['#ExtraExperience']).forEach(key => {
        if (formData['#ExtraExperience'][key]) {
          queryParams.append(`extraExperience[${index}]`, key);
          index++;
        }
      });
    }
    if (formData['#YearGroups']) {
      let index = 0;
      Object.keys(formData['#YearGroups']).forEach(key => {
        if (formData['#YearGroups'][key]) {
          queryParams.append(`yearGroups[${index}]`, key);
          index++;
        }
      });
    }

    if (isLoadMore && lastEvaluatedKey) {
      queryParams.append('LastEvaluatedKey', JSON.stringify(lastEvaluatedKey));
    }

    const queryString = queryParams.toString();
    const url = `/app_api/search/${userType}?${queryString}`;
    console.log('Fetching URL:', url);

    const response = await identity.authorizedFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setSearchResults();
    try {
      const data = await fetchResults();
      setSearchResults(data.SearchResults);
      setLastEvaluatedKey(data.LastEvaluatedKey || null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error searching teachers:', error);
    }
  };

  const handleLoadMore = async () => {
    setIsLoading(true);
    try {
      const data = await fetchResults(true);
      setSearchResults((prevResults) => [...prevResults, ...data.SearchResults]);
      setLastEvaluatedKey(data.LastEvaluatedKey || null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error loading more teachers:', error);
    }
  };  

  return (
    <>
      <div className="my-3 border rounded-3 p-4">
        {searchParams.map(param => (
          <ParamsFilterItem key={param} value={param} handleRemoveParam={handleRemoveParam}>
            {param === "School Name" && (
              <FormNameGeneric label="Search School Name:" handleFormChange={handleFormChange} formDataSection={formData[fullParamsList["School Name"]]} controlId="#SchoolName" />
            )}
            {param === "Name" && (
              <FormNameGeneric label="Search Name:" handleFormChange={handleFormChange} formDataSection={formData[fullParamsList["Name"]]} controlId="#Name" />
            )}
            {param === "Education Level" && (
              <FormEducationLevel label="Education Level:" handleFormChange={handleFormChange} formData={formData} required={true} />
            )}
            {param === "Year Groups" && (
              <FormYearGroups label="Year Groups:" handleFormChange={handleFormChange} formData={formData} selecterOverride={true} />
            )}
            {param === "Subject Specialism" && (
              <FormSubjectSpec label="Subject Specialism:" handleFormChange={handleFormChange} formData={formData} required={true} />
            )}
            {param === "Experience With" && (
              <FormExtraExp label="Experience With:" handleFormChange={handleFormChange} formData={formData} />
            )}
          </ParamsFilterItem>
        ))}

        {availableParams.length > 0 && (
          <div className="text-center">
            <Dropdown>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="add-filter">Add Filter</Tooltip>}
              >
                <Dropdown.Toggle as="a" role="button" className="dropdown-no-arrow" drop="down-centered">
                  <i className="bi bi-plus-circle"></i>
                </Dropdown.Toggle>
              </OverlayTrigger>
              <Dropdown.Menu>
                {availableParams.map((value, index) => (
                  <Dropdown.Item key={index} onClick={() => handleAddParam(value)}>
                    {value}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>

      <Button disabled={isLoading} onClick={handleSubmit}>{searchParams.length > 0 ? "Search" : "Show All"}</Button>
      

      {searchResults && (searchResults.length > 0 ? (
        <>
          <div className="my-3 text-secondary fw-lighter">
          {searchResults.length > 1 
                    ? `${searchResults.length}${lastEvaluatedKey ? '+' : ''} results to display` 
                    : `${searchResults.length}${lastEvaluatedKey ? '+' : ''} result to display`}
          </div>
          <div className="mt-3 border rounded-3 p-4">
          <DisplaySearchResults searchResults={searchResults} functionButton={{type: 'manage_user', userId: searchResults.PK}} userType={userType} afterEffects={handleSubmit} />
          
          {lastEvaluatedKey && (<div className="text-center mt-4">
            <Button variant="outline-secondary" size="sm" onClick={handleLoadMore} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Load More'}
            </Button>
          </div>)}
          </div>
        </>
      ) : <div className="mt-3 border rounded-3 p-4 text-secondary">Your search returned no results</div>)}

      {isLoading && !searchResults && (
        <div className="py-5 text-center purple_text">
        <Spinner animation="border" size="lg" className="purple_spinner" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      </div>
      )}

    </>
  );
};

const ParamsFilterItem = ({ value, children, handleRemoveParam }) => {
  return (
    <Row className="mt-3">
      <Col xs={10} sm={11}>
        {children}
      </Col>
      <Col>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`${value}-tooltip`}>Remove</Tooltip>}
        >
          <Button size="sm" variant="outline-danger float-end" onClick={() => handleRemoveParam(value)}>
            <i className="bi bi-x-lg"></i>
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  );
};
