import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIdentityContext } from '../identity-react';
import { Button } from 'react-bootstrap';
import LoadingSpinner from './utilities/loadingSpinner';
import { ListJobsDisplay } from './listJobsDisplay';

export function ListJobs( {userType, displayType, givenId, requestType} ) {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const identity = useIdentityContext();
    const [appendLoading, setAppendLoading] = useState(false);
    const selectedSchool = useSelector((state) => state.schools.selectedSchool);


    const fetchUserData = useCallback(async (searchId = null, lastKey = null, append = false, justRequests = false, requestType = null) => {
        setLoading(true);
        append && setAppendLoading(true);
        try {
            const baseUrl = `/app_api/${userType}/listJobs/${searchId ? encodeURIComponent(searchId) : ''}`;

            const queryParams = [];
            if (justRequests) {
            queryParams.push('showJustRequests=request');
            }
            if (requestType) {
            queryParams.push(`filterStatus=${encodeURIComponent(requestType)}`);
            }
            if (lastKey) {
            queryParams.push(`LastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastKey))}`);
            }

            const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
            const url = baseUrl + queryString;

            const response = await identity.authorizedFetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setUserData(prevData => append ? [...prevData, ...data.jobDetails] : data.jobDetails);
            setLastEvaluatedKey(data.LastEvaluatedKey || null);
            setLoading(false);
            setAppendLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
            setAppendLoading(false);
            // Handle error state
        }
    }, [identity, userType]);

    useEffect(() => {
        if (userType === "school" && selectedSchool) {
            setSearchId(selectedSchool.PK);
            fetchUserData(selectedSchool.PK);
        }
        if (userType === "teacher") {
            setSearchId(null);
            if (displayType === "requests") {
                fetchUserData(undefined, undefined, undefined, true);
            } else {
                fetchUserData();
            }
        }
        if (userType === "staff") {
            setSearchId(null);
            if (displayType === "agencySchoolModal" && givenId) {
                setSearchId(givenId)
                fetchUserData(givenId);
            } else {
                fetchUserData(undefined, undefined, undefined, undefined, requestType);
            }
        }
    }, [fetchUserData, selectedSchool, displayType, userType, givenId, requestType]);



    return (
        <>
                <div className='p-0'>
                    {loading && !appendLoading ? (
                        <LoadingSpinner />
                    ) : (
                        userData.length > 0 ? (
                            <>
                            <ListJobsDisplay inputData={userData} displayType={displayType} userType={userType} />
                                {lastEvaluatedKey && (
                                    <div className="text-center mt-4">
                                        <Button variant="outline-secondary" size="sm" onClick={() => fetchUserData(searchId, lastEvaluatedKey, true, undefined, requestType)} disabled={loading}>
                                            {loading ? 'Loading...' : 'Load More'}
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : <p className="text-secondary my-0">{userType === "school" 
                            ? 'You haven\'t created any jobs yet.' 
                            : userType === "teacher" && displayType === "requests"
                                ? 'No pending job requests to show.' 
                                : userType === "teacher" ? 'You don\'t have any jobs yet.' : 'No jobs were returned.'}</p>
                    )}
                </div>
        </>
    );
}
