import React from 'react';
import { ApprovalsFetcher } from './approvalsFetcher';
import { useLocation } from 'react-router-dom';

export function AgencyApprovals () {
    const selectionList = [['teacher_pending', 'Teacher'], ['school_pending', 'School'], ['staff_pending', 'Staff']]
    const location = useLocation();
    const passedUserType = location.state?.userType;

    return (
        <ApprovalsFetcher 
        title={'Approval Requests'} 
        selectionList={selectionList} 
        functionButton={{type: 'agency_approve_profile'}} 
        customNoResultsMessage={'No approval requests to show.'} 
        initialUserType={passedUserType}
        />
    )
}

export function AgencyDeactivated () {
    const selectionList = [['teacher_deactivated', 'Teacher'], ['school_deactivated', 'School'], ['staff_deactivated', 'Staff']]
    return (
        <ApprovalsFetcher 
        title={'Deactivated Users'} 
        selectionList={selectionList} 
        functionButton={{type: 'agency_reactivate'}} 
        customNoResultsMessage={'No deactivated users to show.'} 
        />
    )
}