import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchTheme = createAsyncThunk(
  'display/fetchTheme',
  async () => {
    const response = await fetch('/app_api/theme', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch theme data');
    }
    const data = await response.json();
    return data; // Adjust according to your API response
  }
);

export const updateTheme = createAsyncThunk(
  'display/updateTheme',
  async ({ analogousColors, identity, textColour }, thunkAPI) => {
    if (identity.user && identity.user.app_metadata && identity.user.app_metadata.roles && identity.user.app_metadata.roles.includes("staff")) {
        try {
          const payload = {
            ...analogousColors,
            textColour
          };
  
          const response = await identity.authorizedFetch('/app_api/theme', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
          });
          if (!response.ok) {
            throw new Error('Failed to update theme');
          }
          const return_data = await response.json();
          return return_data.data;
        } catch (error) {
          return thunkAPI.rejectWithValue(error.message);
        }
      }
  }
);

export const updateAppName = createAsyncThunk(
  'display/updateAppName',
  async ({ appDetails, identity }, thunkAPI) => {
    if (identity.user && identity.user.app_metadata && identity.user.app_metadata.roles && identity.user.app_metadata.roles.includes("staff")) {
        try {
          const response = await identity.authorizedFetch('/app_api/appDetails', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(appDetails)
          });
          if (!response.ok) {
            throw new Error('Failed to update theme');
          }
          const return_data = await response.json();
          return return_data.data;
        } catch (error) {
          return thunkAPI.rejectWithValue(error.message);
        }
      }
  }
);

const initialState = {
  is_collapsed: false,
  active_page: null,
  colour_set: {'coreColour': '', 'colour2': '', 'colour3': '', 'textColour': ''},
  app_logo: null,
  app_name: "",
  isLoading: true,
};


export const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    toggleCollapse: (state) => {
        state.is_collapsed = !state.is_collapsed;
    },
    makeCollapse: (state) => {
      state.is_collapsed = true;
  },
      makeUnCollapse: (state) => {
        state.is_collapsed = false;
    },
    setActivePage: (state, action) => {
      state.active_page = action.payload
    },
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    logoutResetDisplay: (state) => {
      state.is_collapsed = initialState.is_collapsed;
      state.active_page = initialState.active_page;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTheme.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTheme.fulfilled, (state, action) => { 
        state.colour_set.coreColour = action.payload.ColourCore1;
        state.colour_set.colour2 = action.payload.Colour2;
        state.colour_set.colour3 = action.payload.Colour3;
        state.colour_set.textColour = action.payload.TextColour;
        state.app_logo = action.payload.AppLogo;
        state.app_name = action.payload.AppName;
        document.documentElement.style.setProperty('--1grad-color', state.colour_set.coreColour);
        document.documentElement.style.setProperty('--2grad-color', state.colour_set.colour2);
        document.documentElement.style.setProperty('--3grad-color', state.colour_set.colour3);
        document.documentElement.style.setProperty('--4text-color', state.colour_set.textColour);
        state.isLoading = false;
      })
      .addCase(fetchTheme.rejected, (state) => {
        state.isLoading = false;
        console.error('Failed to fetch theme data');
      })
      .addCase(updateTheme.fulfilled, (state, action) => {
        console.log("update theme payload", action.payload);
        state.colour_set.coreColour = action.payload.ColourCore1;
        state.colour_set.colour2 = action.payload.Colour2;
        state.colour_set.colour3 = action.payload.Colour3;
        state.colour_set.textColour = action.payload.TextColour;
        document.documentElement.style.setProperty('--1grad-color', state.colour_set.coreColour);
        document.documentElement.style.setProperty('--2grad-color', state.colour_set.colour2);
        document.documentElement.style.setProperty('--3grad-color', state.colour_set.colour3);
        document.documentElement.style.setProperty('--4text-color', state.colour_set.textColour);
      })
      .addCase(updateTheme.rejected, (state, action) => {
        console.error('Failed to update theme colors:', action.payload);
      })
      .addCase(updateAppName.fulfilled, (state, action) => {
        console.log("update name payload", action.payload);
        state.app_name = action.payload.AppName;
      })
      .addCase(updateAppName.rejected, (state, action) => {
        console.error('Failed to update app name:', action.payload);
      });
  }
});

export const { toggleCollapse, setActivePage, setBackgroundColor, logoutResetDisplay, makeCollapse, makeUnCollapse } = displaySlice.actions;

export const getColourSet = (state) => state.display.colour_set;
export const getAppLogo = (state) => state.display.app_logo;
export const getAppName = (state) => state.display.app_name;
export const getTextColour = (state) => state.display.colour_set.textColour;
export const displayIsLoading = (state) => state.display.isLoading;

export default displaySlice.reducer;