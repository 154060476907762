import React, { useState, useEffect, useCallback } from 'react';
import { MainShadowBox } from './utilities/mainShadowBox';
import { useIdentityContext } from '../identity-react';
import LoadingSpinner from './utilities/loadingSpinner';
import { EntitySearchList } from './entitySearchList';
import { Button, ButtonGroup } from 'react-bootstrap';
import { JobCounterBadge } from './utilities/jobCounterBadge';

export function ApprovalsFetcher ( {title, selectionList, functionButton, customNoResultsMessage, initialUserType} ) {
    const identity = useIdentityContext();
    const [staffData, setStaffData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const [userType, setUserType] = useState(initialUserType);

    const fetchData = useCallback(async (userType = null, lastKey = null, append = false) => {
        setLoading(true);
        try {
            const response = await identity.authorizedFetch(`/app_api/search/${encodeURIComponent(userType)}${lastKey ? `?LastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastKey))}` : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch staff data');
            }
            const data = await response.json();
            setStaffData(prevData => append ? [...prevData, ...data.SearchResults] : data.SearchResults);
            setLastEvaluatedKey(data.LastEvaluatedKey || null);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching staff data:', error);
            setLoading(false);
        }
    }, [identity]);

    useEffect(() => {
        if (userType) {
            setSearchId(userType);
            fetchData(userType);
        }
    }, [fetchData, userType]);

    const afterEffects = () => {
        setStaffData([]);
        fetchData(userType);
    }

    const handleButtonClick = (pageName) => {
        if (pageName !== userType) {
            setUserType(pageName);
            setStaffData([]);
        }
      };

    return (
        <>
        <MainShadowBox title={title}>
            {selectionList?.length > 0 && (
            <ButtonGroup className="w-100">
                {selectionList.map((selection) => (
                    <Button key={`${selection[0]}-${title}`} 
                    variant={userType === selection[0] ? 'primary' : 'outline-primary'}
                    onClick={() => handleButtonClick(selection[0])}>
                    {selection[1]}
                    <JobCounterBadge countType="requests" countParam={selection[0]} colour="danger" />
                    </Button>
                ))}
            </ButtonGroup>)}
                    {loading && staffData.length === 0 ? (
                        <LoadingSpinner />
                    ) : userType ? (
                        <div className="mt-3">
                        <EntitySearchList inputData={staffData} 
                            lastEvaluatedKey={lastEvaluatedKey} 
                            afterEffects={afterEffects}
                            loading={loading}
                            searchId={searchId}
                            loadMoreFunction={fetchData}
                            userType={userType}
                            //functionButton={{type: 'manage_school_staff', schoolId: 'searchId'}}
                            //functionButton={{type: 'approve_school_staff', schoolId: 'testy'}}
                            functionButton={functionButton}
                            customNoResultsMessage={customNoResultsMessage}
                            />
                        </div>
                    ) : null}
        </MainShadowBox>
        </>
    )
};