import { Spinner } from "react-bootstrap";

const LoadingSpinner = ( {children} ) => {  
    return (
        <div className="py-5 text-center purple_text">
        <Spinner animation="border" size="lg" className="purple_spinner" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      {children}
      </div>
    )
  };
  
  export default LoadingSpinner;
