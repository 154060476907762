import { configureStore } from '@reduxjs/toolkit';
import displayReducer from '../features/display/displaySlice';
import schoolsReducer from '../features/schools/schoolsSlice';
import alertsReducer from '../features/alerts/alertsSlice';

export const store = configureStore({
  reducer: {
    display: displayReducer,
    schools: schoolsReducer,
    alerts: alertsReducer,
  },
  devTools: true,
});
