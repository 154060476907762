import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdentityContext } from '../../identity-react';
import { Nav, Dropdown, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { toggleCollapse, getAppLogo, getAppName, getTextColour, setActivePage, logoutResetDisplay, makeCollapse, makeUnCollapse } from '../../features/display/displaySlice'; 
import { NavLinks } from './navLinks';
import { useParams, useNavigate } from 'react-router-dom';
import { TeacherLinks, SchoolBaseLinks, SchoolAdminLinks, SchoolEditorLinks, StaffLinks } from './linkSettings';
import { PasswordChange } from '../userprofilepage/passwordChange';
import { SchoolSwitcher } from '../utilities/schoolSwitcher';
import { AddNewSchoolModal } from '../utilities/addNewSchoolModal';
import LoadingSpinner from '../utilities/loadingSpinner';
import { logoutResetAlerts } from '../../features/alerts/alertsSlice';
import { logoutResetSchools } from '../../features/schools/schoolsSlice';


function Sidebar () {
    const [links, setLinks] = useState([]);
    const isCollapsed = useSelector((state) => state.display.is_collapsed);
    const activePage = useSelector((state) => state.display.active_page);
    const schoolPermissionLevel = useSelector((state) => state.schools.selectedSchool?.AccessLevel);
    const appName = useSelector(getAppName);
    const appLogo = useSelector(getAppLogo);
    const identity = useIdentityContext();
    const user = identity && identity.user;
    const dispatch = useDispatch();

    useEffect(() => {
      document.title = appName + " Dashboard";
  }, [appName]);

  const { pageName } = useParams();

  useEffect(() => {
    if (pageName) {
      dispatch(setActivePage(pageName));
    }
  }, [pageName, dispatch]);

  const getLinks = useCallback(() => {
    let links = [];
  
    if (user && user.app_metadata.roles.includes("teacher")) {
      links = [...TeacherLinks];
    } else if (user.app_metadata.roles.includes("school")) {
      if (schoolPermissionLevel === "editor") {
        links = [...SchoolBaseLinks, ...SchoolEditorLinks];
      } else if (schoolPermissionLevel === "admin") {
        links = [...SchoolBaseLinks, ...SchoolEditorLinks, ...SchoolAdminLinks];
      } else if (schoolPermissionLevel === "viewer") {
        links = [...SchoolBaseLinks];
      }
    } else if (user.app_metadata.roles.includes("staff")) {
      links = [...StaffLinks];
    }
  
    // Sort the links based on the 'order' property
    return links.sort((a, b) => a.order - b.order);
  }, [user, schoolPermissionLevel]);

  useEffect(() => {
    const sortedLinks = getLinks();
    setLinks(sortedLinks);
  }, [getLinks]);

  //this should be the img url in non demo mode
  //<img src={`${appLogo}?${new Date().toISOString().split('T')[0]}`}
  ////src={`${origImage}?${new Date().getTime()}`}

    return (
      <>
      <div className={`bg_gradient_purp main-shadow shadow fixed-top-md fs-${isCollapsed ? '5' : '6'} fw-light col-12 col-md-${isCollapsed ? '1' : '3'} contrast_theme_text m-0 m-md-2 rounded-3 p-2 pb-0 transition-col`}>
          <div className="d-flex flex-column flex-shrink-0 p-3 p-md-0 p-lg-2 p-xl-3 pb-0 pb-sm-0 pb-md-0 pb-lg-0 pb-xl-0 h-100 justify-content-between">
            <div className="overflow-y-scroll overflow-x-hidden">
              <a href="/" className="d-flex justify-content-center align-items-center mb-0 mb-md-0 me-md-auto text-white text-decoration-none">
                  <img src={`${appLogo}?${new Date().getTime()}`} style={{ width: isCollapsed ? '100%' : '50%' }} alt={appName} className="image-transition" />
              </a>
              <hr />
              <Nav className={`flex-column mb-auto ${isCollapsed && " text-center fs-5"}`} variant="pills" activeKey={activePage}>
                <NavLinks links={links} />
                {links?.length < 1 && <LoadingSpinner />}
              </Nav>
            </div>
              <BottomMenu />
              </div>
      </div>
      </>
    );
  };

  function BottomMenu () {
    const identity = useIdentityContext();
    const user = identity && identity.user;
    const isCollapsed = useSelector((state) => state.display.is_collapsed);
    const schoolList = useSelector((state) => state.schools.schoolList);
    const selectedSchool = useSelector((state) => state.schools.selectedSchool);
    const textColour = useSelector(getTextColour);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogOut = async () => {
      dispatch(logoutResetDisplay());
      dispatch(logoutResetAlerts());
      dispatch(logoutResetSchools());
      navigate('/', { replace: true });
      await identity.logout();
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) { // Adjust the width as necessary
          dispatch(makeUnCollapse());
        } else if (window.innerWidth < 1200 && window.innerWidth > 768) { // Adjust the width as necessary
          dispatch(makeCollapse());
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      // Initial check
      //handleResize();
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [dispatch]);

    const handleToggleCollapse = () => {
        dispatch(toggleCollapse());
    };
    
    return (
      <div className={`pb-3 text-center w-100 sticky-bottom d-flex ${isCollapsed ? 'flex-column' : 'flex-column flex-lg-row justify-content-between align-items-center'}`}>      
      <Dropdown drop={isCollapsed ? "end" : "up-start"}>
        <OverlayTrigger
        placement="right"
        overlay={isCollapsed ? <Tooltip id="list-1">Account Options</Tooltip> : <></>}
        >
        <Dropdown.Toggle as="a" style={{cursor: "pointer"}} className="align-items-center contrast_theme_text text-decoration-none" id="dropdown-basic" data-bs-toggle="dropdown" aria-expanded="false">
        {isCollapsed ? <i className="bi bi-person-circle"></i> : <><i className="bi bi-person-circle me-2"></i><span id="nav_id_welcome" className="d-inline-block align-middle text-truncate" style={{ maxWidth: '200px' }}>{user && user.user_metadata.full_name}{selectedSchool && ` (${selectedSchool.FullName})`}</span></>}
        </Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-light text-small shadow" style={{minWidth: '300px'}}>
          <Dropdown.ItemText>Logged in as {user && user.user_metadata.full_name}</Dropdown.ItemText>
          <Dropdown.ItemText id="user_text_email" className="dropdown-header">{user && user.email}</Dropdown.ItemText>
          <PasswordChange linkStyle='dropdownItem' />
          {selectedSchool && <Dropdown.ItemText className="dropdown-header">Current School: {selectedSchool?.FullName}</Dropdown.ItemText>}
          {schoolList && (schoolList.length > 1 ? <SchoolSwitcher linkStyle='dropdownItem' /> : <AddNewSchoolModal linkStyle='dropdownItem' />)}
          {user ? (<Dropdown.Item id="logoutBtn" onClick={handleLogOut}>Sign out</Dropdown.Item>) : (<Dropdown.Item id="loginBtn">Login</Dropdown.Item>)}
          <Dropdown.Divider />
          <Dropdown.ItemText className="text-secondary"><small>Powered by <a href="https://www.teacherflow.co.uk" target="_blank" rel="noreferrer">TeacherFlow</a></small></Dropdown.ItemText>
          <Dropdown.Item className="text-secondary" href="/terms" target="_blank"><small>Privacy Policy & Terms of Service</small></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <OverlayTrigger
        placement="right"
        overlay={isCollapsed ? <Tooltip id="collapse_button">Open Menu</Tooltip> : <Tooltip id="collapse_button">Close Menu</Tooltip>}
        >
      <Button size="sm" variant={textColour === "#000000" ? 'outline-dark' : 'outline-light'} className={isCollapsed ? "mt-2 border-0 d-none d-md-block" : "mt-0 d-none d-md-block"} onClick={handleToggleCollapse}><i className={isCollapsed ? "bi bi-arrow-bar-right" : "bi bi-arrow-bar-left"}></i></Button>
      </OverlayTrigger>
    </div>
    );
};

export default Sidebar;