import React from 'react';
import { Button } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';
import { StaffSignUpForm } from './staffSignUpForm';
import { useSelector } from 'react-redux';
import { getAppName } from '../../features/display/displaySlice';

export function StaffSignUp () {
    const identity = useIdentityContext();
    const appName = useSelector(getAppName);

    return (
        <>
        <div className="row w-100 justify-content-between">
            <div className="col-12 col-md-7 mb-2">
                <h1 className="purple_text fw-lighter">{appName} Staff Signup...</h1>
            </div>
            <div className="col-12 col-md-5 float-end">
                <div className="float-end">
                {identity.user && (<Button className="mb-3 mb-md-0 m-1" size="sm" variant="light" onClick={identity.logout}>Sign out</Button>)}
                </div>
            </div>
        </div>
        <div className="row w-100 mb-4">
        <div className="col-12 bg-body shadow-sm rounded-4 p-4">
            <StaffSignUpForm />
        </div>
        </div>
        </>
    )
};
