import React from 'react';
import { AgencyProfileViewer } from '../agencyProfileViewer';

export function AgencyApproveButton( {userId, afterEffects} ) {
  const [typeOfUser] = userId.split('#');

  return (
    <>
      <AgencyProfileViewer 
        profileId={userId}
        typeOfUser={typeOfUser}
        linkStyle={'agencyApprove'}
        noEdit={true}
        approvalFooter={true}
        afterEffects={afterEffects} 
        />
    </>
  );
};