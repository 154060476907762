import React, {useState, useEffect} from 'react';
import { Image, Button, Spinner } from 'react-bootstrap';
import PhotoUpload from './photoUpload';
import { useIdentityContext } from '../../identity-react';
import '../mainStyles.css';


export function EditImage( {origImage, aspectRatio, shape, dynamoImageAttr, entryType, format, allowZoomOut, noEdit} ) {
    const[imageData, setImageData] = useState(null);
    const[editImg, setEditImg] = useState(false);
    const[uploadDetails, setUploadDetails] = useState(null);
    const[isLoading, setIsLoading] = useState(false);
    const[error, setError] = useState(false);
    const identity = useIdentityContext();

    useEffect(() => {
        const regex = /\/([^/]+)\/([^/]+)\.(jpg|png)$/;
        const match = origImage.match(regex);

        if (match) {
            const folder = match[1];
            const fileName = match[2];
            const fileFormat = match[3];
            setUploadDetails([folder, fileName, fileFormat]);
        }
    }, [origImage]);

    const handleImageCropUpload = (data) => {
        setImageData(data);
      };

    const handleUpload = async () => {
        setIsLoading(true);
        setError(false);
        identity.authorizedFetch('/app_api/imageUpload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "#ImageUpload": imageData, 
                "#UploadFolder": uploadDetails[0], 
                "#UploadId": uploadDetails[1],
                "#DBAttr": dynamoImageAttr,
                "#EntryType": entryType,
                "#ImageFormat": uploadDetails[2]
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setEditImg(false);
            setIsLoading(false);
            return response.json();
        })
        .catch(error => {
            setIsLoading(false);
            setError(true);
            console.error('Error uploading image:', error);
        });
    };

    const getShapeProps = (shape) => {
        switch (shape) {
          case 'rounded':
            return { rounded: true };
          case 'roundedCircle':
            return { roundedCircle: true };
          default:
            return {};
        }
      };

    return (
        <>
            {isLoading ? (<div className="py-5 text-center purple_text">
                    <Spinner animation="border" size="lg" className="purple_spinner" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  </div>)
            : (
                <>
                {!editImg ? (<Image alt="image/logo" src={`${origImage}?${new Date().getTime()}`} style={{ height: '100px'}} {...getShapeProps(shape)} />) :
            (<PhotoUpload onImageCropUpload={handleImageCropUpload} aspectRatio={aspectRatio} isRequired={false} format={format} allowZoomOut={allowZoomOut}/>)}
            {!noEdit && (<div>
            <Button className="ms-0 m-2" variant={!editImg ? 'primary' : 'outline-secondary'} onClick={() => setEditImg(!editImg)}>{!editImg ? 'Edit Image' : 'Cancel'}</Button>
            {editImg && imageData && <Button className="ms-0 m-2" variant="primary" onClick={handleUpload}>Upload</Button>}</div>)}
            {error && <p className="text-danger"><small>An error occured, please try again</small></p>}
                </>
            )}
        </>
    );
}