import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MainShadowBox } from './utilities/mainShadowBox';
import { useIdentityContext } from '../identity-react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './utilities/loadingSpinner';
import { FormEducationLevel, FormExtraExp, FormNameGeneric, FormSubjectSpec, FormTextAreaGeneric, FormYearGroups } from './Signup/allFormComponents';

export function JobCreateForm () {
    const identity = useIdentityContext();
    const [selectedDates, setSelectedDates] = useState([]);

    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);

    //const schoolList = useSelector((state) => state.schools.schoolList);
    const selectedSchool = useSelector((state) => state.schools.selectedSchool);
    const isReduxLoading = useSelector((state) => state.schools.loading);
    const [isLoading, setIsLoading] = useState(isReduxLoading);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(isReduxLoading);
      }, [isReduxLoading]);
  
      const handleFormChange = (e) => {
        const { id, name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: {
                    ...prevFormData[name],
                    [id]: checked,
                },
            }));
        } else if (id === '#Postcode') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.toUpperCase(),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
            }));
        }
    };

    const handleDateChange = (dates) => {
        setSelectedDates(dates);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
    
        const formattedDates = selectedDates.map(dateString => {
            return isoDate(dateString);
        });
    
        try {
            const response = await identity.authorizedFetch('/app_api/createJob', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"#Dates": formattedDates, formData: formData, "#SchoolId": selectedSchool.PK, "#SchoolName": selectedSchool.FullName})
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            navigate(`/job/${data.jobId}`, {
                state: {
                    originPage: 'JobCreate',
                    schoolId: data.schoolId,
                }
            });
            setIsLoading(false);
        } catch (error) {
            console.error('Error signing up:', error);
            setIsLoading(false);
        }
    };
    

    const today = new Date();

    const isoDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    //export const FormEducationLevel = ({ label, handleFormChange, formData, required }) => {

    return (
        <>
        <MainShadowBox title="Create a New Job">
            {isLoading ? <LoadingSpinner /> 
                  : (<Form noValidate className="p-4" onSubmit={handleSubmit} validated={validated}>
                <fieldset>
                    <FormNameGeneric label="Job Title:" handleFormChange={handleFormChange} formDataSection={formData['#JobTitle']} controlId='#JobTitle' required={true} />
                    <Form.Group as={Row} className="mb-3" controlId="edit_playlist_name">
                        <Form.Label column sm={3}>When would you like the supply?</Form.Label>
                        <Col sm={4}>
                        <DatePicker className="custom-datepicker"
                            selectedDates={selectedDates}
                            selectsMultiple
                            onChange={handleDateChange}
                            shouldCloseOnSelect={false}
                            disabledKeyboardNavigation
                            minDate={today}
                            calendarStartDay={1}
                            inline
                            />
                        {validated && selectedDates.length < 1 && (
                                <div className="invalid-feedback d-block">Please select date(s).</div>
                            )}
                        </Col>
                        <Col sm={3}>
                        <b>Selected Date{selectedDates.length > 1 && "s"}:</b>
                        {selectedDates.map((date, index) => (
                        <p className="mb-0" key={index}><small>{isoDate(date)}</small></p>
                    ))}
                        </Col>
                    </Form.Group>
                    <FormTextAreaGeneric label="Job Description:" handleFormChange={handleFormChange} formDataSection={formData['#JobDescription']} controlId="#JobDescription" required={true} colWidth={6} />
                    <FormSubjectSpec label="Subject:" handleFormChange={handleFormChange} formData={formData} required={false} />
                    <FormExtraExp label="Experience Required:" handleFormChange={handleFormChange} formData={formData} />
                    <FormEducationLevel label="Education Level:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormYearGroups label="Year Group(s):" handleFormChange={handleFormChange} formData={formData} />
                    <FormTextAreaGeneric label="Additional Information:" handleFormChange={handleFormChange} formDataSection={formData['#AdditionalInfo']} controlId="#AdditionalInfo" required={false} colWidth={6} />
                    <Button type="submit" id="job_submit_button">Create Job</Button>
                </fieldset>
            </Form>)}
        </MainShadowBox>
        </>
    )
};
