import React, { useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';

export function SearchSettingButton({ fetchData }) {
  const [removeSubjects, setRemoveSubjects] = useState(false);
  const [removeExpNeeded, setRemoveExpNeeded] = useState(false);

  const handleChange = () => {
    const queryParams = new URLSearchParams();
    if (removeSubjects) {
      queryParams.append('removeSubjects', true);
    }
    if (removeExpNeeded) {
      queryParams.append('removeExpNeeded', true);
    }
    const queryString = queryParams.toString();
    
    // Call the fetchData function with the query string
    fetchData(queryString);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle size="sm" variant="outline-secondary" id="searchsettingsdropdown" className="m-0">
          <i className="bi bi-gear"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu className="p-3" style={{ minWidth: '300px' }}>
          <p className="text-secondary mb-2">Include matches without required...</p>
          <Form>
            <Form.Check
              type="switch"
              id="removeSubjects"
              label="Subject Specialism"
              checked={removeSubjects}
              onChange={(e) => setRemoveSubjects(e.target.checked)}
            />
            <Form.Check
              type="switch"
              id="removeExpNeeded"
              label="Experience Needed"
              checked={removeExpNeeded}
              onChange={(e) => setRemoveExpNeeded(e.target.checked)}
            />
            <Button size="sm" onClick={handleChange} className="mt-2">Update Results</Button>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}