import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';

const LongText = ({ textInput }) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const [showFullText, setShowFullText] = useState(false);
    const textRef = useRef(null);
    const lines = textInput.split('\n');

    useEffect(() => {
        const element = textRef.current;
        setIsTruncated(element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
    }, [textInput]);

    const toggleText = () => {
        setShowFullText(!showFullText);
    };

    return (
        <Row className="mt-2">
            <Col sm={12} className="flex-grow-1">
                <div
                    className={`fs-6 ${showFullText ? '' : 'text-truncate'}`}
                    style={{
                        whiteSpace: showFullText ? 'normal' : 'normal',
                        overflow: showFullText ? 'visible' : 'hidden',
                        textOverflow: showFullText ? 'unset' : 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: showFullText ? 'unset' : '1',
                        WebkitBoxOrient: 'vertical',
                    }}
                    ref={textRef}
                >
                    {lines.map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            </Col>
            <Col>
                {isTruncated && !showFullText && (
                    <span
                        className="text-secondary"
                        onClick={toggleText}
                        style={{ cursor: 'pointer' }}
                    >
                        {showFullText ? ' see less' : ' see more'}
                    </span>
                )}
            </Col>
        </Row>
    );
};

export default LongText;