import React, {useState, useCallback, useEffect} from 'react';
import { Col, Row, OverlayTrigger, Tooltip, Badge, Button } from 'react-bootstrap';
import { formatDistanceToNow, format } from 'date-fns';
import { ViewTeacherProfileModal } from './viewTeacherProfileModal';
import { useIdentityContext } from '../../identity-react';
import LoadingSpinner from '../utilities/loadingSpinner';
import { MainSectionBox } from '../utilities/mainSectionBox';

function ViewProfileButton({ jobId, userId, userName, disabled }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
        <Button
            onClick={handleShow}
            disabled={disabled}
            id={userId}
            size="sm"
            className="px-3 rounded-4"
            variant="light"
        >
            <i className="bi bi-person me-1 text-secondary"></i>
            {userName}
        </Button>
        
        <ViewTeacherProfileModal show={show} handleClose={handleClose} userId={userId} jobId={jobId} />
        </>
    );
}


export function DisplayJobRequestHistory({ jobId }) {
    const identity = useIdentityContext();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [historyData, setHistoryData] = useState([]);

    const fetchHistory = useCallback(async () => {
        try {
            const response = await identity.authorizedFetch(`/app_api/job/requestHistory/${encodeURIComponent(jobId)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An unexpected error occurred');
            }
            const data = await response.json();
            setHistoryData(data);
            setIsLoading(false);
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    }, [identity, jobId]);

    useEffect(() => {
        if (jobId) {
            setIsLoading(true);
            fetchHistory();
        }
    }, [jobId, fetchHistory]);

    const sortedRequestHistory = [...historyData].sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));

    return (
        <>
            <MainSectionBox title="Request History">
            {isLoading ? <LoadingSpinner /> : error ? <div className="p-4 text-secondary mt-2">{error}</div> : 
            sortedRequestHistory.length === 0 ? <div className="p-4 text-secondary">No requests have been made</div> : 
            (<Col className="mt-2">
            <Row className="text-center text-secondary border-bottom p-2" key="headerRow">
                <Col xs={4} sm={3}>
                    <small><i className="bi bi-clock"></i><span className="d-none d-sm-block">Request Sent</span></small>
                </Col>
                <Col xs={3} className="d-none d-sm-block">
                    <small><i className="bi bi-person-up"></i><span className="d-none d-sm-block">Requested By</span></small>
                </Col>
                <Col xs={4} sm={3}>
                    <small><i className="bi bi-person"></i><span className="d-none d-sm-block">User Requested</span></small>
                </Col>
                <Col xs={4} sm={3}>
                    <small><i className="bi bi-arrow-left-right"></i><span className="d-none d-sm-block">Status</span></small>
                </Col>
            </Row>
            {sortedRequestHistory.map((historyLine, index) => {
                const isLastItem = index === sortedRequestHistory.length - 1;
                const singleResult = sortedRequestHistory.length === 1;
                const borderClass = !isLastItem && !singleResult ? 'border-bottom' : '';

                return (
                    <Row key={index} className={`text-center p-2 fs-6 ${borderClass}`}>
                        <Col xs={4} sm={3} className="py-1">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id={`displaydata${index}`}>{format(new Date(historyLine.CreatedAt), 'E do LLL y p')}</Tooltip>}
                            >
                                <span>{format(new Date(historyLine.CreatedAt), 'E do LLL y p')}</span>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={3} className="py-1 d-none d-sm-block">
                            {historyLine.CreatedByName}
                        </Col>
                        <Col xs={4} sm={3}>
                            <ViewProfileButton jobId={jobId} userId={historyLine.SK} userName={historyLine.Status === "rejected" ? '' : 'View Profile'} disabled={historyLine.Status === "rejected"} />
                        </Col>
                        <Col xs={4} sm={3}>
                            <Badge bg={historyLine.Status === "confirmed" ? 'success' : historyLine.Status === "rejected" ? 'danger' : 'secondary'} pill className="text-capitalize px-3 py-2 text-wrap">
                                {historyLine.Status.replace(/_/g, ' ')}
                                {historyLine.TimeOfReply && (<span className="fw-lighter ms-1">({formatDistanceToNow(new Date(historyLine.TimeOfReply), { addSuffix: true })})</span>)}
                            </Badge>
                        </Col>
                    </Row>
                );
            })}
            </Col>)}
            </MainSectionBox>
        </>
    );
}