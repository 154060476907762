import React, { useState } from 'react';
import { Button, Modal, FormCheck } from 'react-bootstrap';
import { useIdentityContext } from '../../../identity-react';
import LoadingSpinner from '../loadingSpinner';


export function ModalApprovalFooter({ userId, typeOfUser, afterEffects }) {
    const identity = useIdentityContext();
    const [confirmationChecked, setConfirmationChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleUserCodeSubmit = async (action) => {
        try {
            setError(null);
            if (!action || !typeOfUser || !userId) {
                throw new Error('Missing required parameters');
            }
            setIsLoading(true);
    
            const response = await identity.authorizedFetch(`/app_api/account/${encodeURIComponent(action)}/${encodeURIComponent(typeOfUser)}/${encodeURIComponent(userId)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            await response.json();
            setIsLoading(false);
            if (afterEffects) {
                afterEffects();
            }
        } catch (error) {
            console.error('Error handling user code submission:', error);
            setError("An unexpected error occured, please refresh the page and try again")
            setIsLoading(false);
        }
    };

    return (
        <>
                <Modal.Footer>
                    <div className="flex-column gap-1 my-4 w-100">
                    {isLoading ? <LoadingSpinner /> : (<>
                        {typeOfUser === "teacher" && (<FormCheck
                        className={`mb-3 ${confirmationChecked ? 'text-success' : 'text-danger'}`}
                        type='checkbox'
                        key={`${userId}-confirmCheck`}
                        label='I confirm the user has been fully checked in accordance with government guidelines and is eligable to supply'
                        onChange={(event) => setConfirmationChecked(event.target.checked)}
                    />)}
                    <div className="d-flex gap-1">
                    <Button onClick={() => handleUserCodeSubmit('rejected')} className="w-50" variant="danger">Reject</Button>
                    <Button onClick={() => handleUserCodeSubmit('approved')} className="w-50" variant="success" disabled={!confirmationChecked && typeOfUser === "teacher"}>Approve</Button>
                    </div>
                    {error && <p className="text-center text-danger mb-0 mt-2"><small>{error}</small></p>}
                    </>)}
                    </div>
            </Modal.Footer>
        </>
    );
}