import React, {useState} from 'react';
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';

export function SendTeacherRequest( {userId, jobId, schoolId, afterEffects} ) {
  const identity = useIdentityContext();
  const [loading, setLoading] = useState(false);

  //const [typeOfUser, userCode] = userId.split('#');

  const handleSubmit = async () => {
    setLoading(true);
    identity.authorizedFetch('/app_api/requestTeacher', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ '#userId': userId, '#jobId': jobId, '#schoolId': schoolId })
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        afterEffects();
        return response.json();
    })
    .catch(error => {
        console.error('Error signing up:', error);
        setLoading(false);
    });
};


  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip id={`${userId}ReqTooltip`}>Send Request</Tooltip>}>
      <Button variant="outline-danger" id={userId} onClick={handleSubmit} size="sm" disabled={loading}>{loading ? <Spinner as="span" size="sm" /> : <><i className="bi bi-send"></i><span className='d-sm-none ms-2 ms-md-0'>Send Request</span></>}</Button>
      </OverlayTrigger>
    </>
  );
};