import React, { useState, useEffect} from 'react';
import { DisplaySearchResults } from './displaySearchResults';
import { Row, Col, Button } from 'react-bootstrap';
import { SearchBox } from './Signup/allFormComponents';

export function EntitySearchList ( {inputData, lastEvaluatedKey, afterEffects, loading, searchId, loadMoreFunction, userType, functionButton, customNoResultsMessage} ) {
    const [filteredData, setFilteredData] = useState(inputData);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const filtered = inputData.filter((staff) => {
            return (
                staff?.FullName?.toLowerCase().includes(searchQuery) ||
                staff?.JobTitle?.toLowerCase().includes(searchQuery) ||
                staff?.Email?.toLowerCase().includes(searchQuery)
            );
        });
        setFilteredData(filtered);
    }, [inputData, searchQuery]);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    };

    return (
        <>
            {inputData.length > 0 ? (
                            <>
                                <Row className="mt-0 mb-2 px-1 justify-content-end">
                                    <Col xs={12} md={4}>
                                    <SearchBox value={searchQuery} handleChange={handleSearch} placeholder='Search' showClearBtn={true} />
                                    </Col>
                                </Row>

                                <div className="mt-3 border rounded-3 p-4">
                                    <DisplaySearchResults searchResults={filteredData} userType={userType} vertPadding='3' functionButton={functionButton} afterEffects={afterEffects} />
                                {lastEvaluatedKey && (
                                    <div className="text-center mt-4">
                                        <Button variant="outline-secondary" size="sm" onClick={() => loadMoreFunction(searchId, lastEvaluatedKey, true)} disabled={loading}>
                                            {loading ? 'Loading...' : 'Load More'}
                                        </Button>
                                    </div>
                                )}
                                </div>
                            </>
            ) : <div className="mt-3 border rounded-3 p-4 text-secondary">{customNoResultsMessage ? customNoResultsMessage : 'No results to display.'}</div>}
        </>
    )
};