import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

function OfflineWarning() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Event listeners to handle online and offline events
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        // Cleanup event listeners on component unmount
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };
      }, []);


    return (
      !isOnline && (<Alert style={{zIndex: "500"}} key="offlinealert" className="w-100" variant="danger" dismissible={false}>
        <i className="bi bi-exclamation-circle me-2"></i>You are not connected to the internet.
      </Alert>)
    );
}

export default OfflineWarning;