import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { MainShadowBox } from './utilities/mainShadowBox';
import { ListJobs } from './listJobs';
import { JobCounterBadge } from './utilities/jobCounterBadge';

export function AgencyListJobs () {
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (pageName) => {
        setActiveButton(pageName);
      };

    return (
        <>
        <MainShadowBox title="Search Jobs">
        <ButtonGroup className={`w-100 ${activeButton && 'mb-4'}`} aria-label="Jobs List">
            <Button
                variant={activeButton === 'open' ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick('open')}
            >
                Open
                <JobCounterBadge countType="job" countParam="open" colour="danger" />
            </Button>
            <Button
                variant={activeButton === 'pending' ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick('pending')}
            >
                Pending
                <JobCounterBadge countType="job" countParam="pending" colour="warning" />
            </Button>
            <Button
                variant={activeButton === 'confirmed' ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick('confirmed')}
            >
                Confirmed
            </Button>
        </ButtonGroup>

            {activeButton && <ListJobs userType='staff' displayType='requests' requestType={activeButton} />}
        </MainShadowBox>
        </>
    )
};
