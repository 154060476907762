import React, { useState, useEffect, useCallback } from 'react';
import { MainShadowBox } from '../utilities/mainShadowBox';
import { useIdentityContext } from '../../identity-react';
import { DisplaySearchResults } from '../displaySearchResults';
import { Col, Row, Button } from 'react-bootstrap';
import LoadingSpinner from '../utilities/loadingSpinner';
import { useSelector } from 'react-redux';
import { JobRequestButtons } from './jobRequestButtons';
import CheckAccessLevel from '../utilities/checkAccessLevel';
import { JobDetailsSection } from './jobDetailsSection';
import { DisplayJobRequestHistory } from './displayJobRequestHistory';
import { MainSectionBox } from '../utilities/mainSectionBox';
import { ConfirmedCandidateBox } from './confirmedCandidateBox';
import { SearchSettingButton } from './searchSettingsButton';

export function JobPagePass( {schoolId, jobId, userType} ) {
    const identity = useIdentityContext();
    const [error, setError] = useState(null);
    const [jobData, setJobData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const schoolPermissionLevel = useSelector((state) => state.schools.selectedSchool?.AccessLevel);
    const [show, setShow] = useState(false);

    const handleToggle = () => setShow(!show);

    const fetchJobData = useCallback(async (queryString = null) => {
        try {
            setIsLoading(true);
            const response = await identity.authorizedFetch(`/app_api/job/${userType}/${encodeURIComponent(schoolId)}/${encodeURIComponent(jobId)}?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An unexpected error occurred');
            }
            const data = await response.json();
            setJobData(data);
            setIsLoading(false);
        } catch (error) {
            setJobData(null);
            setError(error.message);
            setIsLoading(false);
        }
    }, [identity, jobId, schoolId, userType]);

    useEffect(() => {
        if (jobId && schoolId && userType) {
            setIsLoading(true);
            fetchJobData();
        } else {
            setIsLoading(true);
            const timer = setTimeout(() => {
                setError('You are not authorised to view this page');
                setIsLoading(false);
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [jobId, schoolId, fetchJobData, userType]);

    return (
        <>
            <MainShadowBox title={jobData?.details?.JobTitle} statusBadge={jobData?.status?.JobStatus} statusBadgeLabel='Job Status' statusBadgeColour={jobData?.status?.JobStatus === "open" ? 'danger' : jobData?.status?.JobStatus === "pending" ? 'warning' : jobData?.status?.JobStatus === "confirmed" ? 'success' : 'secondary'} barcodetext={jobData?.details?.PK}>
                <div>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : jobData && jobData.details ? (
                        <>
                            <JobDetailsSection jobDetails={jobData.details} SchoolLogo={jobData.metadata.SchoolLogo} />

                            {userType === "teacher" && (<Row>
                            <Col sm={5} className="py-4">
                            <iframe
                                title="locationmap"
                                width="100%"
                                referrerpolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDejHZKzY7PRXmgXrIOrOiVXnTc-HADO1M&q=${jobData.metadata.Address}`}
                                allowfullscreen>
                                </iframe>
                            </Col>
                            <Col sm={7}>
                            <DisplaySearchResults searchResults={[jobData.metadata]} userType={'school'} functionButton={false} hideImage={true} />
                            </Col>
                            </Row>)}

                            {((jobData?.status?.JobStatus === "pending" || jobData?.status?.JobStatus === "confirmed") && (CheckAccessLevel('viewer', schoolPermissionLevel) || userType === "staff")) && (
                                <div className="mt-5 mb-3">
                                    <ConfirmedCandidateBox jobId={jobData.details.PK} requestType={jobData?.status?.JobStatus === "pending" ? "request_sent" : jobData?.status?.JobStatus} />
                                </div>
                            )}
                        
                            
                            {jobData?.status?.JobStatus === "pending" && userType === "teacher" && <JobRequestButtons jobId={jobData.details.PK} schoolId={jobData.metadata.PK} afterEffects={fetchJobData} />}

                                {(CheckAccessLevel('editor', schoolPermissionLevel) || userType === "staff") && (jobData.teacher_matches && (<>
                                    <MainSectionBox title="Teacher Matches"
                                            subtitle={jobData?.teacher_matches?.length === 1
                                                ? `${jobData.teacher_matches.length} result to display`
                                                : `${jobData.teacher_matches.length} results to display`}
                                            >
                                            {userType === "staff" && (<div className="d-flex justify-content-end m-0">
                                                <SearchSettingButton fetchData={fetchJobData} />
                                            </div>)}
                                            {jobData?.teacher_matches?.length > 0 ? (
                                            <DisplaySearchResults searchResults={jobData.teacher_matches} userType={'teacher'} functionButton={{ type: 'send_teacher_request', jobId: jobData.details.PK, schoolId: jobData.metadata.PK }} matchCount={false} afterEffects={fetchJobData} />
                                            ) : <div className="p-4 text-secondary">No matches found</div>}
                                        </MainSectionBox>
                                    </>
                                    ))
                            }

                                    {(CheckAccessLevel('viewer', schoolPermissionLevel) || userType === "staff") && (
                                        <Button active={show} size="sm" variant="outline-secondary my-2" onClick={handleToggle}>{show ? 'Hide' : 'Show'} Request History</Button>
                                    )}

                            {(CheckAccessLevel('viewer', schoolPermissionLevel) || userType === "staff") && show && (
                                <DisplayJobRequestHistory jobId={jobData.details.PK} />
                            )}
                        </>
                    ) : error ? <p className="text-secondary p-4 m-0">{error}</p> : (
                        <p className="text-secondary p-4 m-0">An unexpected error occured.</p>
                    )}
                </div>
            </MainShadowBox>
        </>
    );
}