import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, DropdownItem, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIdentityContext } from '../identity-react';
import LoadingSpinner from './utilities/loadingSpinner';

export function GetTeacherAvails ( {userId, linkType, staffFlag, showKey} ) {
    const identity = useIdentityContext();
    const [availsData, setAvailsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);

    const fetchAvails = useCallback(async () => {
        setIsLoading(true);
        setError(false);
        try {
            const response = await identity.authorizedFetch(`/app_api/teacherAvails/${encodeURIComponent(userId)}/${staffFlag ? 'staff' : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            const dateClassMap = data.map(item => ({
                date: item.SK,
                holidayName: item.Reason,
                className: item.ReasonCode ? item.ReasonCode : 'user_busy'
            }));

            setAvailsData(dateClassMap);
        } catch (error) {
            console.error('Error fetching avails data:', error);
            setError('An error occured fetching availbility data.')
        } finally {
            setIsLoading(false);
        }
    }, [identity, staffFlag, userId]); 

    useEffect(() => {
        if (show) {
            fetchAvails();
        }
    }, [identity, fetchAvails, show]);

    const today = new Date();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isoDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const dayClassName = (date) => {
        const formattedDate = isoDate(date);
        const dateClass = availsData.find(d => d.date === formattedDate);
        return dateClass ? dateClass.className : '';
    };

    return (
        <>
            {linkType === "dropdownItem" ? <DropdownItem onClick={handleShow}>Show Availability</DropdownItem>
            : <></>}

            <Modal show={show} size="sm" onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
            <Modal.Body>
                {isLoading ? (<LoadingSpinner />) 
                    : error ? error : (<><Row>
                        <Col className="text-center">
                            <DatePicker
                                calendarClassName={!staffFlag && 'display_others_avails_datepicker'}
                                selectsMultiple
                                shouldCloseOnSelect={false}
                                disabledKeyboardNavigation
                                minDate={today}
                                maxDate={new Date(today.getTime() + (90 * 24 * 60 * 60 * 1000))}
                                calendarStartDay={1}
                                dayClassName={dayClassName}
                                holidays={availsData}
                                inline
                                />

                        </Col>
                        </Row>

                        {showKey && (
                            <Row className="m-1 gap-1">

                            <Col xs={12} className="d-flex align-center text-center">
                            <p className="text-secondary ms-2 my-0"><small>Key:</small></p>
                            </Col>

                            <Col xs={6} sm={12} className="d-flex align-center text-center">
                            <div style={{width: '1.5rem', height: '1.5rem'}} className="user_absence rounded mx-2" />
                            <p className="text-secondary fw-lighter mx-2 my-0"><small>Set as Unavailiable</small></p>
                            </Col>

                            <Col xs={6} sm={12} className="d-flex align-center text-center">
                            <div style={{width: '1.5rem', height: '1.5rem'}} className="job_confirmed rounded mx-2" />
                            <p className="text-secondary fw-lighter mx-2 my-0"><small>Job Confirmed</small></p>
                            </Col>

                            <Col xs={6} sm={12} className="d-flex align-center text-center">
                            <div style={{width: '1.5rem', height: '1.5rem'}} className="job_pending rounded mx-2" />
                            <p className="text-secondary fw-lighter mx-2 my-0"><small>Job Pending</small></p>
                            </Col>

                            </Row>)}
                    </>)}
            </Modal.Body>
            </Modal>
        </>
    )
};
