import React, {useState} from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { handleNumberKeyPress, LabelPopoverHelper } from './allFormComponents';
import { useIdentityContext } from '../../identity-react';

export function SchoolURNScrape({ formData, required, handleFormChange, setFormData, justDisplay }) {
    const identity = useIdentityContext();
    const [isScrapeLoading, setIsScrapeLoading] = useState(false);
    const [error, setError] = useState(false);

    const scrapeData = async (e) => {
        e.preventDefault();
        if (!formData['#SchoolURN'] || justDisplay || !setFormData) {
            return null;
        }
        setError(false);
        setIsScrapeLoading(true);
        try {
            const response = await identity.authorizedFetch(`/app_api/scrape/${formData['#SchoolURN']}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
               throw new Error('Network response was not ok');
        }

            const scrapeData = await response.json();

            setFormData((prevFormData) => ({
                ...prevFormData,
                '#SchoolName': scrapeData['establishmentName'],
                '#Address': scrapeData['Address'],
                '#SchoolPhoneNumber': scrapeData['Telephone'],
                '#Website': scrapeData['Website']?.split(' ')[0],
                '#Headteacher': cleanWhitespace(scrapeData['Headteacher / Principal']).replaceAll('\n', ''),
                '#OfstedRating': scrapeData['Ofsted rating']?.split(' ')[0].replace('\n\n', ''),
              }));
              setIsScrapeLoading(false);
        } catch (error) {
            setIsScrapeLoading(false);
            setError("There was an error prefilling the details, try again or manually enter the information below");
            console.error('Error deleting date:', error);
        }
    };

    const cleanWhitespace = (str) => {
        if (!str) {
            alert("hello no string boy");
            return;
        }
        return str
          .split(' ') // Split the string into an array of words
          .filter(word => word !== '') // Remove any empty strings from the array
          .join(' ')
          .replace('\n', ''); // Join the array back into a string with single spaces between words
      };


    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId="#SchoolURN">
                        {!justDisplay && <p className="text-secondary">Enter your school's URN (Unique Refrence Number) to prefill your schools details.</p>}
                        <Form.Label column sm={3}>School URN:
                            <LabelPopoverHelper />
                        </Form.Label>
                        <Col sm={3}>
                        <Form.Control onChange={handleFormChange} onKeyDown={handleNumberKeyPress} pattern="[0-9]*" value={formData['#SchoolURN'] || ''} required={required} type="text" />
                        <Form.Control.Feedback type="invalid">
                            This is a required field.
                        </Form.Control.Feedback>
                        </Col>
                        {!justDisplay && (<><Col sm={5}>
                            <Button className="mt-2 mt-sm-0" disabled={!formData['#SchoolURN'] || isScrapeLoading} onClick={scrapeData}>Prefill School Details{isScrapeLoading && <Spinner size="sm" className="ms-2" />}</Button>
                        </Col>
                        {error && <p className="text-danger mb-0"><small>{error}</small></p>}</>)}
            </Form.Group>
        </>
    )
};