import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const notificationSound = new Audio('/notification.mp3');


export const fetchAlerts = createAsyncThunk(
    'alerts/fetchAlerts',
    async (identity, thunkAPI) => {
      if (identity.user) {
        const roles = identity?.user?.app_metadata?.roles || [];
        const userType = roles.includes("school") ? "schools" :
                         roles.includes("teacher") ? "teachers" :
                         roles.includes("staff") ? "staff" : null;
        if (!userType) {
          return [];
        }
        try {
          const response = await identity.authorizedFetch(`/app_api/alerts/${userType}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
          if (!response.ok) {
            throw new Error('Failed to get alerts');
          }
          const returnData = await response.json();
          return returnData;
        } catch (error) {
          return thunkAPI.rejectWithValue(error.message);
        }
      } else {
        return [];
      }
    }
  );

const initialState = {
  alerts: [],
  notifications: [],
  isLoading: false,
};

//ID
//title "You have a new job update"
//time recieved
//Message body
//where to link to

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    removeAlertById(state, action) {
      const alertId = action.payload;
      state.alerts = state.alerts.filter(alert => alert.id !== alertId);
    },
    addAlert: (state, action) => {
      const newAlert = action.payload;
      state.alerts = [...state.alerts, newAlert];
    },
    addNotification: (state, action) => {
      let newNotification;
      if (action.payload.type === "NEW_JOB_UPDATE" && action.payload.jobId) {
        newNotification = {
          id: action.payload.jobId,
          notificationTitle: 'New Update',
          notificationTime: Date.now(),
          notificationMessage: action.payload.newJobStatus === "open" ? 'Your request has been rejected. Click to see alternative candidates.' : 'Your request has been accepted!',
          notificationLinkType: 'linkToJobPage',
          dontPersist: action.payload.newJobStatus === 'open' ? false : true,
          tagColour: action.payload.newJobStatus === "open" ? 'danger' : 'success'
        };
      } else if (action.payload.type === "NEW_JOB_REQUEST" && action.payload.jobId) {
        newNotification = {
          id: action.payload.jobId,
          notificationTitle: 'New Job Request',
          notificationTime: Date.now(),
          notificationMessage: `You have recieved a new job request!`,
          notificationLinkType: 'linkToJobPage',
          dontPersist: false,
          tagColour: 'success'
        };
      } else if (action.payload.type === "NEW_APPROVAL_REQUEST") {
        newNotification = {
          id: 'aa',
          notificationTitle: 'New Approval Request',
          notificationTime: Date.now(),
          notificationMessage: `${action.payload?.approvalType === "teacher_pending" ? 'Teacher' : action.payload?.approvalType === "staff_pending" ? 'Staff' : action.payload?.approvalType === "school_pending" ? 'school' : ''}: ${action.payload.requestorName} is awaiting your approval.`,
          notificationLinkType: 'linkToApprovals',
          dontPersist: false,
          tagColour: 'info'
        };
      } else {
        return;
      }
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== newNotification.id
      );

      // Add the new notification
      state.notifications.push(newNotification);
      notificationSound.play().catch(error => console.error('Error playing sound:', error));
    },
        removeNotificationById(state, action) {
          const notificationId = action.payload;
          state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
        },
        logoutResetAlerts: (state) => {
            state.alerts = initialState.alerts;
            state.notifications = initialState.notifications;
        },
      },
    extraReducers: (builder) => {
      builder
        .addCase(fetchAlerts.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchAlerts.fulfilled, (state, action) => {
          if (action.payload) {
            const newAlerts = Array.isArray(action.payload) ? action.payload : [action.payload];
            const filteredAlerts = newAlerts.filter(
                newAlert => !state.alerts.some(existingAlert => existingAlert.id === newAlert.id)
            );
            state.alerts = [...state.alerts, ...filteredAlerts];
        }
        
          state.isLoading = false;
        })
        .addCase(fetchAlerts.rejected, (state) => {
          state.isLoading = false;
          state.alerts = initialState.alerts;
        })
    }
  });

  export const { removeAlertById, addAlert, logoutResetAlerts, addNotification, removeNotificationById } = alertsSlice.actions;
  
  export default alertsSlice.reducer;