import React, { useState, useEffect, useCallback } from 'react';
import { useIdentityContext } from '../identity-react';
import LoadingSpinner from './utilities/loadingSpinner';
import { EntitySearchList } from './entitySearchList';

export function StaffList ( {schoolId} ) {
    const identity = useIdentityContext();
    const [staffData, setStaffData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

    const fetchStaffData = useCallback(async (searchId = null, lastKey = null, append = false) => {
        setLoading(true);
        try {
            const response = await identity.authorizedFetch(`/app_api/stafflist/${encodeURIComponent(searchId)}${lastKey ? `?LastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastKey))}` : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch staff data');
            }
            const data = await response.json();
            setStaffData(prevData => append ? [...prevData, ...data.SearchResults] : data.SearchResults);
            setLastEvaluatedKey(data.LastEvaluatedKey || null);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching staff data:', error);
            setLoading(false);
        }
    }, [identity]);

    useEffect(() => {
        if (schoolId) {
            fetchStaffData(schoolId);
        }
    }, [fetchStaffData, schoolId]);

    const afterEffects = () => {
        setStaffData([]);
        fetchStaffData(schoolId);
    }

    return (
        <>
                    {loading && staffData.length === 0 ? (
                        <LoadingSpinner />
                    ) : (
                        <EntitySearchList inputData={staffData} 
                            lastEvaluatedKey={lastEvaluatedKey} 
                            afterEffects={afterEffects}
                            loading={loading}
                            searchId={schoolId}
                            loadMoreFunction={fetchStaffData}
                            userType={'schooluser'}
                            functionButton={{type: 'manage_school_staff', schoolId: schoolId}}
                            //functionButton={{type: 'approve_school_staff', schoolId: searchId}}
                            />
                    )}
        </>
    )
};


