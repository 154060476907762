export const SchoolBaseLinks = [
    { key: "jobs", label: "List Jobs", icon: "bi bi-card-checklist", tooltip: "List Jobs", order: 2 },
    { key: "userprofile", label: "User Profile", icon: "bi bi-person", tooltip: "User Profile", order: 5 }
  ];

export const SchoolEditorLinks = [
    { key: "requestsupply", label: "Request Supply", icon: "bi bi-plus-circle-dotted", tooltip: "Request Supply", order: 1 },
    { key: "schoolprofile", label: "School Profile", icon: "bi bi-buildings", tooltip: "School Profile", order: 4 }
  ];

export const SchoolAdminLinks = [
    { key: "stafflist", label: "Staff List", icon: "bi bi-person-lines-fill", tooltip: "Staff List", order: 3 }
];

export const TeacherLinks = [
    { key: "jobrequests", label: "Job Requests", icon: "bi bi-bell", tooltip: "Job Requests", order: 1 },
    { key: "jobs", label: "List Jobs", icon: "bi bi-card-checklist", tooltip: "List Jobs", order: 2 },
    { key: "setavailability", label: "Set Availability", icon: "bi bi-calendar-week", tooltip: "Set Availability", order: 3 },
    { key: "userprofile", label: "User Profile", icon: "bi bi-person", tooltip: "User Profile", order: 4 }
  ];

export const UnUsedLink = [
    { key: "school#favTeachers", label: "Favourite Teachers", icon: "bi bi-star", tooltip: "Favourite Teachers", order: 10 },
];

export const StaffLinks = [
  { key: "userprofile", label: "User Profile", icon: "bi bi-person", tooltip: "User Profile", order: 8 },
  { key: "jobs", label: "List Jobs", icon: "bi bi-card-checklist", tooltip: "List Jobs", order: 1 },
  { key: "listall", label: "Search Active Users", icon: "bi bi-search", tooltip: "Search Active Users", order: 3 },
  { key: "edittheme", label: "Edit Theme", icon: "bi bi-palette", tooltip: "Edit Theme", order: 6 },
  { key: "broadcastmessage", label: "Broadcast Message", icon: "bi bi-chat-left-text", tooltip: "Broadcast Message", order: 7 },
  { key: "stafflist", label: "Staff List", icon: "bi bi-person-lines-fill", tooltip: "Staff List", order: 5 },
  { key: "approvalrequests", label: "Approval Requests", icon: "bi bi-send-plus-fill", tooltip: "Approval Requuests", order: 2 },
  { key: "deactivatedusers", label: "Deactivated Users", icon: "bi bi-person-fill-slash", tooltip: "Deactivated Users", order: 4 },
];