import React, { useState, useEffect } from 'react';
import { GroupByDate } from './utilities/groupByDate';
import { Badge, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { SearchBox } from './Signup/allFormComponents';

export function ListJobsDisplay({ inputData, displayType, userType }) {
    const [filteredData, setFilteredData] = useState(inputData);
    const [searchQuery, setSearchQuery] = useState('');
    const [hoveredDate, setHoveredDate] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const filtered = inputData.filter((job) => {
            return (
                job?.Details?.SchoolName?.toLowerCase().includes(searchQuery) ||
                job?.Details?.JobTitle?.toLowerCase().includes(searchQuery) ||
                job?.Details?.JobDescription?.toLowerCase().includes(searchQuery) ||
                job?.Request?.SchoolName?.toLowerCase().includes(searchQuery)
            );
        });
        setFilteredData(filtered);
    }, [inputData, searchQuery]);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    };


    const handleJobClick = (fullKey) => {
        const keyParts = fullKey.split('#');
        if (keyParts.length >= 4) {
            navigate('/job/' + keyParts[3], {
                state: {
                    originPage: 'JobList',
                    schoolId: `school#${keyParts[1]}`,
                }
            });
        }
    };

    const getMinMaxDates = (dates, type) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' }
        if (type === "min") {
            return new Date(Math.min(...dates.map(date => new Date(date)))).toLocaleDateString('en-GB', options);
        } else if (type === "max") {
            return new Date(Math.max(...dates.map(date => new Date(date)))).toLocaleDateString('en-GB', options);
        }
    };

    return (
        <>
                                <Row className="mt-0 mb-2 px-1 justify-content-end">
                                    <Col xs={12} md={4}>
                                    <SearchBox value={searchQuery} handleChange={handleSearch} placeholder='Search' showClearBtn={true} />
                                    </Col>
                                </Row>

                                {filteredData.length > 0 ? (
                            <>
                                {Object.entries(GroupByDate(filteredData)).map(([date, jobs]) => (
            <div key={date} onMouseEnter={() => setHoveredDate(date)} onMouseLeave={() => setHoveredDate(null)}>
                <Row className={`${(displayType === 'requests' || displayType === 'agencySchoolModal') ? 'mb-0' : 'mb-3'}`}>
                    {(displayType !== "requests" && displayType !== "agencySchoolModal") && (
                        <Col sm={2} className="mt-1 text-center">
                            <Badge pill className={`px-2 ${hoveredDate === date && 'bg_third'}`} bg="dark-subtle">{date}</Badge>
                        </Col>)}
                    <Col className="overflow-hidden flex-shrink-1">
                        {(displayType !== "requests" && displayType !== 'agencySchoolModal') && <hr className="border-dark-subtle" />}
                        {/* Format the delivery of teacher and school froom below */}
                        {jobs.map((job) => (
                                <div onClick={() => handleJobClick(job.Details.PK)} className={`fs-6 rounded p-2 mb-2 border cursor-pointer ${userType === "teacher" && job.Status?.JobStatus === "pending" ? 'grey_stripe_bg' : ''}`} key={job.Details.PK}>
                                    <div
                                        className={`border-start border-5 p-0 ps-3 ${job.Status ? (job.Status?.JobStatus === "open" ? 'border-danger' : job.Status?.JobStatus === "pending" ? 'border-warning' : job.Status?.JobStatus === "confirmed" ? 'border-success' : 'border-secondary') : 'border-secondary'
                                            }`}>
                                        <Row>
                                            <Col sm={8} className="flex-shrink-1">
                                                <h6 className="text-truncate">{job.Details.JobTitle}</h6>
                                                <small><p className="mb-0"><i className="bi bi-calendar-range me-2"></i>{job.Details.JobDates.length > 1 ? `Between ${getMinMaxDates(job.Details.JobDates, 'min')} and ${getMinMaxDates(job.Details.JobDates, 'max')}` : job.Details.JobDates}</p>
                                                    <p className="text-truncate"><i className="bi bi-card-text me-2"></i>{job.Details.JobDescription}</p></small>
                                            </Col>
                                            <Col sm={4} className="text-secondary fw-lighter text-end">
                                                {userType === "teacher" && job?.Request?.SchoolName && (<p className="m-0 fw-bold"><small>{job?.Request?.SchoolName}</small></p>)}
                                                {userType === "staff" && job?.Details?.SchoolName && (<p className="m-0 fw-bold"><small>{job?.Details?.SchoolName}</small></p>)}
                                                <p className="m-0"><small>{userType === "teacher" ? `Requested by ${job?.Request?.CreatedByName}` : `Created by ${job.Details.CreatedByName}`}</small></p>
                                                <p className="m-0">
                                                    <small>{(userType === "teacher" && job.Request.CreatedAt) ? formatDistanceToNow(job.Request.CreatedAt, { addSuffix: true }) : formatDistanceToNow(job?.Details?.CreatedAt, { addSuffix: true })}</small>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                        ))}
                    </Col>
                </Row>
            </div>
            ))}



                            </>
            ) : <p className="text-secondary my-0"><small>No results to display.</small></p>}
        </>
    );
}
