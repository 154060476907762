import React from 'react';
import { Button } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';
import { SchoolSignUpForm } from './schoolSignUpForm';

export function SchoolSignUp ( {handleChange} ) {
    const identity = useIdentityContext();

    return (
        <>
        <div className="row w-100 justify-content-between">
            <div className="col-12 col-md-7 mb-2">
                <h1 className="purple_text fw-lighter">Sign up as a School User...</h1>
            </div>
            <div className="col-12 col-md-5 float-end">
                <div className="float-end">
                <Button className="mb-3 mb-md-0 m-1" size="sm" variant="light" onClick={handleChange}>Switch to Teacher sign up</Button>
                {identity.user && (<Button className="mb-3 mb-md-0 m-1" size="sm" variant="light" onClick={identity.logout}>Sign out</Button>)}
                </div>
            </div>
        </div>
        <div className="row w-100 mb-4">
        <div className="col-12 bg-body shadow-sm rounded-4 p-4">
            <SchoolSignUpForm />
        </div>
        </div>
        </>
    )
};
