import React, { useState, useEffect, useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useIdentityContext } from '../../identity-react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { MainShadowBox } from '../utilities/mainShadowBox';
import { EditTeacherProfile } from './editTeacherProfile';
import { TeacherProfileView } from './teacherProfileView';
import { SchoolProfileView } from './schooolProfileView';
import { EditSchoolProfile } from './editSchoolProfile';
import { EditSchoolUserProfile } from './editSchoolUserProfile';
import { SchoolUserProfileView } from './schoolUserProfileView';
import { PasswordChange } from './passwordChange';
import LoadingSpinner from '../utilities/loadingSpinner';

export function TeacherProfile() {
    const [displayData, setDisplayData] = useState(null);
    const [loading, setLoading] = useState(true);
    const identity = useIdentityContext();
    const [formLock, setFormLock] = useState(true);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await identity.authorizedFetch('/app_api/getaccount/teacher', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setDisplayData(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
            // Handle error state
        }
    }, [identity]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const handleRefreshData = () => {
        setLoading(true);
        setFormLock(true);
        fetchUserData();
    };

    return (
        <>
        <MainShadowBox title={formLock ? "Your User Profile" : "Edit Your User Profile"}>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (displayData ? (
                        <>
                        <TeacherProfileView inputData={displayData} formLock={formLock} />
                        {!formLock && <EditTeacherProfile data={displayData} formLock={formLock} handleRefreshData={handleRefreshData} cancelHandler={() => setFormLock(!formLock)} />}

                    {formLock && <Button className="my-2" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Profile</Button>}
                        </>) : <p className="text-secondary mt-3">An error occured loading the data. Refresh the page to try again.</p>)}
        </MainShadowBox>
        </>
    );
}


export function SchoolProfile() {
    const [displayData, setDisplayData] = useState(null);
    const [loading, setLoading] = useState(true);
    const identity = useIdentityContext();
    const [formLock, setFormLock] = useState(true);
    const [schoolId, setSchoolId] = useState(null);
    const selectedSchoolPK = useSelector((state) => state.schools?.selectedSchool?.PK);

    const fetchUserData = useCallback(async (schoolIdInput = null) => {
        try {
            const response = await identity.authorizedFetch(`/app_api/getaccount/school/${encodeURIComponent(schoolIdInput)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setDisplayData(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
            // Handle error state
        }
    }, [identity]);

    useEffect(() => {
        if (selectedSchoolPK) {
            setSchoolId(selectedSchoolPK);
            fetchUserData(selectedSchoolPK);
        }
    }, [fetchUserData, selectedSchoolPK]);

    const handleRefreshData = () => {
        setLoading(true);
        setFormLock(true);
        fetchUserData(schoolId);
    };

    return (
        <>
        <MainShadowBox title={formLock ? "School Profile" : "Edit School Profile"}>
        {loading ? (
                        <LoadingSpinner />
                    ) : (displayData ? (
                        <>
                        {formLock ? (<><SchoolProfileView inputData={displayData} /><Button className="my-2" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Profile</Button></>):
                        (<EditSchoolProfile schoolId={schoolId} data={displayData} formLock={formLock} handleRefreshData={handleRefreshData} cancelHandler={() => setFormLock(!formLock)} />)}

                        </>) : <p className="text-secondary mt-3">An error occured loading the data. Refresh the page to try again.</p>)}
        </MainShadowBox>
        </>
    );
}

export function SchoolUserProfile( {staff} ) {
    const [displayData, setDisplayData] = useState(null);
    const [loading, setLoading] = useState(true);
    const identity = useIdentityContext();
    const [formLock, setFormLock] = useState(true);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await identity.authorizedFetch(`/app_api/getaccount/${staff ? 'staff' : 'schooluser'}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setDisplayData(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
            // Handle error state
        }
    }, [identity, staff]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const handleRefreshData = () => {
        setLoading(true);
        setFormLock(true);
        fetchUserData();
    };

    return (
        <>
        <MainShadowBox title={formLock ? "Your User Profile" : "Edit Your User Profile"}>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (displayData ? (
                        <>
                        <SchoolUserProfileView inputData={displayData} formLock={formLock} />
                        {!formLock && <EditSchoolUserProfile data={displayData} formLock={formLock} handleRefreshData={handleRefreshData} cancelHandler={() => setFormLock(!formLock)} staff={staff} />}

                    {formLock && (<div className="mt-4 d-flex gap-1"><Button className="" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Profile</Button><PasswordChange linkStyle='button' /></div>)}
                        </>) : <p className="text-secondary mt-3">An error occured loading the data. Refresh the page to try again.</p>)}
        </MainShadowBox>
        </>
    );
}