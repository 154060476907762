import React, { useState } from 'react';
import { Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import '../mainStyles.css';
import PhotoUpload from '../utilities/photoUpload';
import { useIdentityContext } from '../../identity-react';
import { FormNamePreInput, FormEmailPreInput, FormAddress, FormPhoneNumberGeneric, FormDayRate, FormPostcode, FormTRN, FormDBS, FormDOB, FormBio, FormYearsExp, FormSubjectSpec, FormExtraExp, FormEducationLevel, FormYearGroups } from './allFormComponents';

export function TeacherSignUp ( {handleChange} ) {
    const identity = useIdentityContext();

    const [formData, setFormData] = useState({ '#ExtraExperience': {} });
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleFormChange = (e) => {
        const { id, name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: {
                    ...prevFormData[name],
                    [id]: checked,
                },
            }));
            if (name === '#EducationLevel') {
                const inputList = id === "Primary" ? PrimaryGroup : SecondaryGroup;
                toggleAllYearGroupCheckboxes(checked, inputList);
            }
        } else if (id === '#Postcode') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.toUpperCase(),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
            }));
        }
    };

    const handleImageCropUpload = (data, id) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            '#ImageUpload': data,
        }));
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
        identity.authorizedFetch('/app_api/teachersignup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(identity.refreshUser)
        .catch(error => {
            setIsLoading(false);
            console.error('Error signing up:', error);
        });
    };


    const PrimaryGroup = ['EYFS', 'Y1', 'Y2', 'Y3', 'Y4', 'Y5', 'Y6'];
    const SecondaryGroup = ['Y7', 'Y8', 'Y9', 'Y10', 'Y11', 'Y12', 'Y13'];

    const toggleAllYearGroupCheckboxes = (checked, inputGroup) => {
        const updatedYearGroups = { ...formData['#YearGroups'] };
        inputGroup.forEach(groupName => {
            updatedYearGroups[groupName] = checked;
        });
        setFormData((prevFormData) => ({
            ...prevFormData,
            '#YearGroups': updatedYearGroups,
        }));
    };
    
    

    return (
        <>
        <div className="row w-100 justify-content-between">
            <div className="col-12 col-md-7 mb-2">
                <h1 className="purple_text fw-lighter">Sign up as a Teacher...</h1>
            </div>
            <div className="col-12 col-md-5 float-end">
                <div className="float-end">
                <Button className="mb-3 mb-md-0 m-1" size="sm" variant="light" onClick={handleChange}>Switch to School User sign up</Button>
                {identity.user && (<Button className="mb-3 mb-md-0 m-1" size="sm" variant="light" onClick={identity.logout}>Sign out</Button>)}
                </div>
            </div>
        </div>
        <div className="row w-100 mb-4">
        <div className="col-12 bg-body shadow-sm rounded-4 p-4">
        {isLoading ? (<div className="py-5 text-center purple_text">
                    <Spinner animation="border" size="lg" className="purple_spinner" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p className="mt-3">Signing Up</p>
                  </div>) 
                  : (<Form noValidate className="py-2 px-0 p-md-4" onSubmit={handleSubmit} validated={validated}>
                <fieldset>
                    
                    <FormNamePreInput label="Your Full Name:" value={identity.user.user_metadata.full_name} controlId="#Name" />
                    <FormEmailPreInput label="Your Email:" value={identity.user.email} controlId="#Email" />
                    <Form.Group as={Row} className="mb-3" controlId="#ImageUpload">
                        <Form.Label column sm={3}>Profile Image:</Form.Label>
                        <Col sm={4}>
                        <PhotoUpload onImageCropUpload={handleImageCropUpload} aspectRatio={1 / 1} isRequired={true} format={'image/jpeg'} />
                        </Col>
                    </Form.Group>
                    <FormAddress label="Address:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormPostcode label="Postcode:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormPhoneNumberGeneric label="Phone Number:" handleFormChange={handleFormChange} formDataSection={formData['#PhoneNumber']} controlId='#PhoneNumber' required={true} />
                    <FormEducationLevel label="Education:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    {(formData['#EducationLevel'] && (formData['#EducationLevel']['Primary'] || formData['#EducationLevel']['Secondary'])) && (
                    <FormYearGroups label="Year Groups:" handleFormChange={handleFormChange} formData={formData} />
                    )}
                    
                    <FormTRN label="Teacher Reference Number:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormDBS label="DBS Number:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormDOB label="Date of Birth:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormBio label="Bio:" handleFormChange={handleFormChange} formData={formData} required={true} helper={true} />
                    <FormDayRate label="Day Rate" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormSubjectSpec label="Subject Specialism:" handleFormChange={handleFormChange} formData={formData} required={true} />
                    <FormExtraExp label="Previous Experience with..." handleFormChange={handleFormChange} formData={formData} />
                    <FormYearsExp label="Teaching Experience" handleFormChange={handleFormChange} formData={formData} required={true} />


                    <Button type="submit" variant="primary" id="#T#SignUpButton" disabled={!formData['#ImageUpload']}>Sign Up</Button>
                </fieldset>
            </Form>)}
        </div>
        </div>
        </>
    )
};