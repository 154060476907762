import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

const BadgeArray = ({ inputArray, nameLabel, icon, badgeColour, showTooltip }) => {
    const badgeContent = (
        <span>
            {inputArray.map((subject, index) => (
                <Badge pill bg={badgeColour} key={index} className="m-1">
                    {icon && <i className={`bi ${icon} me-2`}></i>}
                    {subject}
                </Badge>
            ))}
        </span>
    );

    return showTooltip ? (
        <OverlayTrigger placement="left" overlay={<Tooltip id={nameLabel}>{nameLabel}</Tooltip>}>
            {badgeContent}
        </OverlayTrigger>
    ) : (
        badgeContent
    );
};

export default BadgeArray;