import React from 'react';
import { Image, Col, Row } from 'react-bootstrap';
import BadgeArray from './utilities/badgeArray';
import LongText from './utilities/longText';
import { ListFunctionButtons } from './utilities/actionButtons/listFunctionButtons';


export const DisplayTeacher = ({ result, functionButton, afterEffects }) => {
    return (
        <Row className="justify-content-center gap-3 gap-sm-0">
            <Col xs={5} sm={1} className="d-flex align-items-center justify-content-center">
                {result.ProfileImage && <Image className="w-100" alt="profile_image" src={result.ProfileImage} roundedCircle />}
            </Col>
            <Col sm={9} className="d-flex flex-column justify-content-center flex-grow-1">
                <Row>
                    <Col sm={12} className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">{result.FullName}</h5>
                        {result.PostcodeArea && (
                            <p className="fw-light mb-0 align-self-center">
                                <span className="text-secondary me-2">Location:</span>
                                {result.PostcodeArea}
                            </p>
                        )}
                    </Col>
                    <Col sm={12}>
                        <Row>
                            <Col>
                                <small>
                                    {result.SubjectSpecialism && (
                                        <BadgeArray
                                            inputArray={result.SubjectSpecialism}
                                            nameLabel="Subject Specialism"
                                            icon="bi-check2-circle"
                                            badgeColour="danger"
                                            showTooltip={true}
                                        />
                                    )}
                                    {result.YearGroups && (
                                        <BadgeArray
                                            inputArray={result.YearGroups}
                                            nameLabel="Year Groups"
                                            icon="bi-check2-circle"
                                            badgeColour="success"
                                            showTooltip={true}
                                        />
                                    )}
                                    {result.ExtraExperience && (
                                        <BadgeArray
                                            inputArray={result.ExtraExperience}
                                            nameLabel="Extra Experience"
                                            icon="bi-check2-circle"
                                            badgeColour="warning"
                                            showTooltip={true}
                                        />
                                    )}
                                </small>
                            </Col>
                        </Row>
                        {result.Bio && (
                            <Row>
                                <Col sm={12}>
                                    <LongText textInput={result.Bio} />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Col>
            {functionButton && <ListFunctionButtons functionButton={functionButton} result={result} afterEffects={afterEffects} />}
        </Row>
    );
};