import React from 'react';
import { Row, Col, InputGroup, Form, Button, Badge, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';

const isValidLength = (givenInput, startLength, endLength) => {
    return givenInput.length >= startLength && givenInput.length <= endLength;
};

export const handleNumberKeyPress = (event) => {
    // Allow only numeric characters and specific control keys (backspace)
    const pattern = /[0-9]/;
    const functionalKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Tab'];
    
    // Check if the key pressed is a functional key or a numeric key
    const isFunctionalKey = functionalKeys.includes(event.key);
    const isNumericKey = pattern.test(event.key);
    const isPaste = (event.ctrlKey || event.metaKey) && event.key === 'v';

    if (!isNumericKey && !isFunctionalKey && !isPaste) {
        event.preventDefault();
    }
};


const getMaxDate = () => {
    const today = new Date();
    const year = today.getFullYear() - 18;
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const LabelTooltipHelper = ({ labeltext }) => {
    return (
        <sup>
            <OverlayTrigger
                placement="right"
                delay={{ hide: 300 }}
                overlay={<Tooltip>{labeltext}</Tooltip>}
            >
                <Badge pill className="ms-2" bg="info"><b>?</b></Badge>
            </OverlayTrigger>
        </sup>
    );
};

export const LabelPopoverHelper = () => {
    const popover = (
        <Popover id="schoolurn">
          <Popover.Body>
                You can find a School's URN <a href="https://get-information-schools.service.gov.uk/Search" target="_blank" rel="noreferrer">here.</a>
          </Popover.Body>
        </Popover>
      );

    return (
        <sup>
            <OverlayTrigger
                placement="bottom"
                delay={{ hide: 1500 }}
                overlay={popover}
            >
                <Badge pill className="ms-2" bg="info"><b>?</b></Badge>
            </OverlayTrigger>
        </sup>
    );
};

//////////
// COMPONENTS START BELOW
//////////

////// GENERIC COMPONENTS NEED SPECIFIC FORMDATAINPUT

export const FormNameGeneric = ({ label, handleFormChange, formDataSection, controlId, required, wideMode }) => {
    return (
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
                <Form.Label column sm={3}>{label}</Form.Label>
                <Col sm={wideMode ? 4: 3}>
                <Form.Control onChange={handleFormChange} value={formDataSection || ''} required={required} type="text" />
                <Form.Control.Feedback type="invalid">
                    This is a required field.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
    )
};


export const FormOfstedRating = ({ label, handleFormChange, formData, required }) => {
    const options = ["Outstanding", "Good", "Requires Improvement", "Inadequate", "N/A"];
    const currentValue = options.includes(formData['#OfstedRating']) ? formData['#OfstedRating'] : '';

    return (
        <Form.Group as={Row} className="mb-3" controlId="#OfstedRating">
            <Form.Label column sm={3}>{label}</Form.Label>
            <Col sm={4}>
                <Form.Select
                    aria-label="Select Ofsted Rating"
                    onChange={handleFormChange}
                    value={currentValue}
                    name="#OfstedRating"
                    required={required}
                >
                    <option value="" disabled>Please select</option>
                    <option value="Outstanding">Outstanding</option>
                    <option value="Good">Good</option>
                    <option value="Requires Improvement">Requires Improvement</option>
                    <option value="Inadequate">Inadequate</option>
                    <option value="N/A">Not Applicable</option>
                </Form.Select>
            </Col>
        </Form.Group>
    )
};

export const FormNamePreInput = ({ label, value, controlId }) => {
    return (
    <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>{label}</Form.Label>
                <Col sm={3}>
                <Form.Control value={value} disabled={true} type="text" />
                </Col>
            </Form.Group>
    )
};

export const SearchBox = ({ placeholder, value, handleChange, showClearBtn }) => {
    const customInputGroupText = {
        backgroundColor: 'transparent',
        borderRight: 'none',
        marginRight: '-10px',
      };

    const handleClear = () => {
        handleChange({ target: { value: '' } });
    };
    
    return (
        <InputGroup className="mb-2">
        <InputGroup.Text className="text-body-tertiary" style={customInputGroupText}>
        <i className="bi bi-search"></i>
        </InputGroup.Text>
        <Form.Control
          className="mt-0 border-start-0"
          value={value}
          onChange={handleChange}
          type="text"
          placeholder={placeholder}
        />
        {showClearBtn && value && <Button variant="outline-secondary" id="resetsearch" onClick={handleClear}>
        <i className="bi bi-x-lg"></i>
        </Button>}
      </InputGroup>
    )
};



export const FormEmailPreInput = ({ label, value, controlId }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm={3}>{label}</Form.Label>
        <Col sm={3}>
        <Form.Control value={value} disabled={true} type="email" />
        </Col>
    </Form.Group>
    )
};

export const FormEmailGeneric = ({ label, handleFormChange, formDataSection, controlId, required, wideMode }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
        <Form.Label column sm={3}>{label}</Form.Label>
        <Col sm={wideMode ? 4 : 3}>
        <Form.Control onChange={handleFormChange} value={formDataSection || ''} required={required} type="email" />
        <Form.Control.Feedback type="invalid">
            Please enter a valid email.
        </Form.Control.Feedback>
        </Col>
    </Form.Group>
    )
};


export function FormPhoneNumberGeneric ({ label, handleFormChange, formDataSection, controlId, required, wideMode }) {    
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
                <Form.Label column sm={3}>{label}</Form.Label>
                <Col sm={wideMode ? 4 : 3}>
                <Form.Control onChange={handleFormChange} onKeyDown={handleNumberKeyPress} minLength={10} pattern="[0-9]{10}|[0-9]{11}" value={formDataSection || ''} required={required} isInvalid={formDataSection ? !isValidLength(formDataSection, 10, 11) : null} type="tel" />
                <Form.Control.Feedback type="invalid">
                    Please enter a valid phone number.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
    )
};

export const FormTextAreaGeneric = ({ label, handleFormChange, formDataSection, controlId, required, colWidth }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={controlId}>
                <Form.Label column sm={3}>
                    <>
                        {label}
                        {!required && (<p className="text-secondary">
                        <small>(Optional)</small></p>)}
                    </>
                    </Form.Label>
                <Col sm={colWidth ? colWidth : 4}>
                <Form.Control onChange={handleFormChange} value={formDataSection || ''} required={required} as="textarea" rows={3} />
                <Form.Control.Feedback type="invalid">
                    This is a required field.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
    )
};


////// SPECIFIC COMPONENTS BELOW

export const FormAddress = ({ label, handleFormChange, formData, required, noBorder, wideMode }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#Address">
                <Form.Label column sm={3}>{label}</Form.Label>
                <Col sm={wideMode ? 5 : 4}>
                <Form.Control className={noBorder && "border-0"} onChange={handleFormChange} value={formData['#Address'] || ''} required={required} as="textarea" rows={3} />
                <Form.Control.Feedback type="invalid">
                    This is a required field.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
    )
};


export function FormPostcode ({ label, handleFormChange, formData, required, wideMode }) {
    const isValidPostcode = (partOne, partTwo) => {
        const postcodeRegex = /^[A-Z]{1,2}\d{1,2}[A-Z]?\s*\d[A-Z]{2}$/;
        return (postcodeRegex.test(partOne + " "+ partTwo) && isValidLength(partOne, 2,4) && isValidLength(partTwo, 3,3));
    };
    return (
        <Form.Group as={Row} className="mb-3" controlId="#Postcode">
                <Form.Label column sm={3}>{label}</Form.Label>
                <Col sm={wideMode ? 3 : 2}>
                <InputGroup hasValidation>
                    <Form.Control onChange={handleFormChange} name="#Postcode#1" minLength="2" maxLength="4" patern="[A-Z]{1,2}\d{1,2}[A-Z]?" value={formData['#Postcode#1'] || ''} required={required} isInvalid={formData['#Postcode#1'] ? !isValidPostcode(formData['#Postcode#1'], formData['#Postcode#2']) : null} type="text" />
                    <Form.Control onChange={handleFormChange} name="#Postcode#2" pattern="\d[A-Z]{2}" value={formData['#Postcode#2'] || ''} required={required} isInvalid={formData['#Postcode#2'] ? !isValidPostcode(formData['#Postcode#1'], formData['#Postcode#2']) : null} type="text" />
                    <Form.Control.Feedback type="invalid">
                    Please enter a valid UK postcode.
                    </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Form.Group>
    )
};

export const FormBio = ({ label, handleFormChange, formData, required, helper }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#Bio">
        <Form.Label column sm={3}>{label}
        {helper && <LabelTooltipHelper labeltext="2-3 lines max. This will be visible to Schools." />}
        </Form.Label>
        <Col sm={7}>
        <Form.Control onChange={handleFormChange} value={formData['#Bio'] || ''} required={required} as="textarea" rows={3} />
        <Form.Control.Feedback type="invalid">
            This is a required field.
        </Form.Control.Feedback>
        </Col>
    </Form.Group>
    )
};

export const FormOccupation = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#UserOccupation">
                        <Form.Label column sm={3}>{label}</Form.Label>
                        <Col sm={3}>
                        <Form.Control onChange={handleFormChange} value={formData['#UserOccupation'] || ''} required={required} type="text" />
                        <Form.Control.Feedback type="invalid">
                            This is a required field.
                        </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
    )
};

export const FormWebsite = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#Website">
                        <Form.Label column sm={3}>{label}</Form.Label>
                        <Col sm={5}>
                        <Form.Control 
                            onChange={handleFormChange} 
                            value={formData['#Website'] || ''} 
                            name="#Website"
                            required={required} 
                            type="url" 
                            />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid website.
                        </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
    )
};

export const FormTRN = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#TRN">
                        <Form.Label column sm={3}>{label}</Form.Label>
                        <Col sm={3}>
                        <Form.Control onChange={handleFormChange} onKeyDown={handleNumberKeyPress} minLength={7} pattern="[0-9]{7}" value={formData['#TRN'] || ''} required={required} isInvalid={formData['#TRN'] ? !isValidLength(formData['#TRN'], 7, 7) : null} type="text" />
                        <Form.Control.Feedback type="invalid">
                            This must be exactly 7 digits.
                        </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
    )
};

export const FormDBS = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#DBS">
                        <Form.Label column sm={3}>{label}</Form.Label>
                        <Col sm={3}>
                        <Form.Control onChange={handleFormChange} onKeyDown={handleNumberKeyPress} minLength={12} pattern="[0-9]{12}" value={formData['#DBS'] || ''} required={required} isInvalid={formData['#DBS'] ? !isValidLength(formData['#DBS'], 12, 12) : null} type="text" />
                        <Form.Control.Feedback type="invalid">
                            This must be exactly 12 digits.
                        </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
    )
};

export const FormDOB = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#DOB">
                        <Form.Label column sm={3}>{label}</Form.Label>
                        <Col sm={2}>
                        <Form.Control onChange={handleFormChange} value={formData['#DOB'] || ''} required={required} type="date" max={getMaxDate()} />
                        <Form.Control.Feedback type="invalid">
                            This is a required field.
                        </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
    )
};

export const FormDayRate = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#DayRate">
                        <Form.Label column sm={3}>{label} (£):</Form.Label>
                        <Col sm={2}>
                            <InputGroup>
                            <InputGroup.Text>£</InputGroup.Text>
                            <Form.Control onChange={handleFormChange} min="0" value={formData['#DayRate'] || ''} required={required} type="number" step="1" />
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">
                            This is a required field.
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
    )
};

export const FormYearsExp = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#YearsExp">
                        <Form.Label column sm={3}>{label}</Form.Label>
                        <Col sm={2}>
                        <InputGroup>
                            <Form.Control onChange={handleFormChange} min="0" max="99" value={formData['#YearsExp'] || ''} required={required} type="number" step="1" />
                            <InputGroup.Text>Years</InputGroup.Text>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">
                            This is a required field.
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
    )
};

export const FormSubjectSpec = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#SubjectSpec">
        <Form.Label column sm={3}>{label}</Form.Label>
        <Col sm={9}>
        <Form.Check
            inline
            label="Maths"
            name="#SubjectSpec"
            type="checkbox"
            id="Maths"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['Maths']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="English"
            name="#SubjectSpec"
            type="checkbox"
            id="English"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['English']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="Science"
            name="#SubjectSpec"
            type="checkbox"
            id="Science"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['Science']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="Geography"
            name="#SubjectSpec"
            type="checkbox"
            id="Geography"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['Geography']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="History"
            name="#SubjectSpec"
            type="checkbox"
            id="History"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['History']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="MFL"
            name="#SubjectSpec"
            type="checkbox"
            id="MFL"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['MFL']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
                        <Form.Check
            inline
            label="ICT"
            name="#SubjectSpec"
            type="checkbox"
            id="ICT"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['ICT']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="P.E"
            name="#SubjectSpec"
            type="checkbox"
            id="PE"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['PE']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="Art and Design"
            name="#SubjectSpec"
            type="checkbox"
            id="Art and Design"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['Art and Design']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
                <Form.Check
            inline
            label="Music"
            name="#SubjectSpec"
            type="checkbox"
            id="Music"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['Music']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        <Form.Check
            inline
            label="R.E"
            name="#SubjectSpec"
            type="checkbox"
            id="RE"
            onChange={handleFormChange}
            checked={formData['#SubjectSpec'] && formData['#SubjectSpec']['RE']}
            required={required && (!formData['#SubjectSpec'] || !Object.values(formData['#SubjectSpec']).some(value => value === true))}
        />
        </Col>
        </Form.Group>
    )
};

export const FormExtraExp = ({ label, handleFormChange, formData }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#ExtraExperience">
        <Form.Label column sm={3}>{label}</Form.Label>
        <Col sm={9} className="mt-1">
        <Form.Check
            inline
            type="switch"
            id="SEN"
            label="SEN"
            name="#ExtraExperience"
            onChange={handleFormChange}
            checked={formData['#ExtraExperience'] && formData['#ExtraExperience']['SEN']}
        />
        <Form.Check
            inline
            type="switch"
            id="Behavioural"
            label="Behavioural"
            name="#ExtraExperience"
            onChange={handleFormChange}
            checked={formData['#ExtraExperience'] && formData['#ExtraExperience']['Behavioural']}
        />
        <Form.Check
            inline
            type="switch"
            id="EYFS"
            label="EYFS"
            name="#ExtraExperience"
            onChange={handleFormChange}
            checked={formData['#ExtraExperience'] && formData['#ExtraExperience']['EYFS']}
        />
        </Col>
    </Form.Group>
    )
};

export const FormEducationLevel = ({ label, handleFormChange, formData, required }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#EducationLevel">
                        <Form.Label column sm={3}>{label}</Form.Label>
                        <Col sm={9}>
                        <Form.Check
                            inline
                            label="Primary"
                            name="#EducationLevel"
                            type="checkbox"
                            id="Primary"
                            onChange={handleFormChange}
                            checked={formData['#EducationLevel'] && formData['#EducationLevel']['Primary']}
                            required={required && (!formData['#EducationLevel'] || (!formData['#EducationLevel']['Primary'] && !formData['#EducationLevel']['Secondary']))}
                        />
                        <Form.Check
                            inline
                            label="Secondary"
                            name="#EducationLevel"
                            type="checkbox"
                            id="Secondary"
                            onChange={handleFormChange}
                            checked={formData['#EducationLevel'] && formData['#EducationLevel']['Secondary']}
                            required={required && (!formData['#EducationLevel'] || (!formData['#EducationLevel']['Primary'] && !formData['#EducationLevel']['Secondary']))}
                        />
                        </Col>
                    </Form.Group>
    )
};

export const FormYearGroups = ({ label, handleFormChange, formData, selecterOverride }) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId="#YearGroups">
        <Form.Label column sm={3}>{label}</Form.Label>
        <Col sm={9}>
        {((formData['#EducationLevel'] && formData['#EducationLevel']['Primary']) || selecterOverride) && (
        <><Form.Check
            inline
            label="EYFS"
            name="#YearGroups"
            type="checkbox"
            id="EYFS"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['EYFS']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y1"
            name="#YearGroups"
            type="checkbox"
            id="Y1"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y1']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y2"
            name="#YearGroups"
            type="checkbox"
            id="Y2"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y2']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y3"
            name="#YearGroups"
            type="checkbox"
            id="Y3"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y3']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y4"
            name="#YearGroups"
            type="checkbox"
            id="Y4"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y4']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y5"
            name="#YearGroups"
            type="checkbox"
            id="Y5"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y5']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y6"
            name="#YearGroups"
            type="checkbox"
            id="Y6"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y6']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        /><br /></>)}
        {((formData['#EducationLevel'] && formData['#EducationLevel']['Secondary']) || selecterOverride) && (
        <>
        <Form.Check
            inline
            label="Y7"
            name="#YearGroups"
            type="checkbox"
            id="Y7"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y7']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y8"
            name="#YearGroups"
            type="checkbox"
            id="Y8"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y8']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y9"
            name="#YearGroups"
            type="checkbox"
            id="Y9"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y9']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y10"
            name="#YearGroups"
            type="checkbox"
            id="Y10"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y10']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y11"
            name="#YearGroups"
            type="checkbox"
            id="Y11"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y11']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y12"
            name="#YearGroups"
            type="checkbox"
            id="Y12"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y12']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        <Form.Check
            inline
            label="Y13"
            name="#YearGroups"
            type="checkbox"
            id="Y13"
            onChange={handleFormChange}
            checked={formData['#YearGroups'] && formData['#YearGroups']['Y13']}
            required={!formData['#YearGroups'] || !Object.values(formData['#YearGroups']).some(value => value === true)}
        />
        </>)}
        </Col>
        <Form.Control.Feedback type="invalid">You need to select at least one option.</Form.Control.Feedback>
    </Form.Group>
    )
};