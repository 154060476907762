import React, {useState, useCallback, useEffect} from 'react';
import { useIdentityContext } from '../../identity-react';
import LoadingSpinner from '../utilities/loadingSpinner';
import { MainSectionBox } from '../utilities/mainSectionBox';
import { DisplayTeacher } from '../displayTeacher';

export function ConfirmedCandidateBox({ jobId, requestType }) {
    const identity = useIdentityContext();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [historyData, setHistoryData] = useState([]);

    const fetchHistory = useCallback(async () => {
        try {
            const response = await identity.authorizedFetch(`/app_api/job/getConfirmedCandidate/${encodeURIComponent(jobId)}/${requestType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An unexpected error occurred');
            }
            const data = await response.json();
            setHistoryData(data);
            setIsLoading(false);
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    }, [identity, jobId, requestType]);

    useEffect(() => {
        if (jobId && requestType) {
            setIsLoading(true);
            fetchHistory();
        } else {
            return null;
        }
    }, [jobId, fetchHistory, requestType]);

    return (
        <>
            <MainSectionBox title={requestType === "request_sent" ? "Request Pending with..." : requestType === "confirmed" ? 'Confirmed Candidate' : null}
                titleColour={requestType === "request_sent" ? "warning" : requestType === "confirmed" ? 'success' : null}
            >
            {isLoading ? <LoadingSpinner /> : error ? <div className="p-4 text-secondary mt-2">{error}</div> : 
            historyData.length === 0 ? <div className="p-4 text-secondary">No candidate found</div> : 
            (
                <div className="p-4"><DisplayTeacher result={historyData} /></div>
            )}
            </MainSectionBox>
        </>
    );
}