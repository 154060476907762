export const GroupByDate = (entries) => {
    const groupedEntries = entries.reduce((groups, entry) => {
        const date = new Date(entry.Details.CreatedAt);
        const formattedDate = formatDate(date);

        if (!groups[formattedDate]) {
            groups[formattedDate] = [];
        }
        groups[formattedDate].push(entry);
        return groups;
    }, {});

    // Sort each group's entries in descending order by CreatedAt
    Object.keys(groupedEntries).forEach(group => {
        groupedEntries[group].sort((a, b) => new Date(b.Details.CreatedAt) - new Date(a.Details.CreatedAt));
    });

    // Sort the groups by date (most recent first)
    const sortedGroups = {};
    Object.keys(groupedEntries)
        .sort((a, b) => {
            const dateA = new Date(groupedEntries[a][0].Details.CreatedAt);
            const dateB = new Date(groupedEntries[b][0].Details.CreatedAt);
            return dateB - dateA;
        })
        .forEach(group => {
            sortedGroups[group] = groupedEntries[group];
        });

    return sortedGroups;
};


const formatDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Strip time for comparison
    const stripTime = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate());

    const strippedToday = stripTime(today);
    const strippedYesterday = stripTime(yesterday);
    const strippedDate = stripTime(date);

    if (strippedDate.getTime() === strippedToday.getTime()) {
        return "Today";
    } else if (strippedDate.getTime() === strippedYesterday.getTime()) {
        return "Yesterday";
    } else {
        // Calculate the start of the current week (Monday as the first day)
        const startOfWeek = new Date(strippedToday);
        startOfWeek.setDate(strippedToday.getDate() - strippedToday.getDay() + 1);

        const startOfLastWeek = new Date(startOfWeek);
        startOfLastWeek.setDate(startOfWeek.getDate() - 7);

        const startOfNextWeek = new Date(startOfWeek);
        startOfNextWeek.setDate(startOfWeek.getDate() + 7);

        if (strippedDate >= startOfWeek && strippedDate < startOfNextWeek) {
            return "This Week";
        } else if (strippedDate >= startOfLastWeek && strippedDate < startOfWeek) {
            return "Last Week";
        } else {
            const month = date.toLocaleDateString("en-US", { month: "long" });
            const year = date.getFullYear() === today.getFullYear() ? "" : ` ${date.getFullYear()}`;
            return `${month}${year}`;
        }
    }
};