import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useIdentityContext } from '../../identity-react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FormAddress, FormNameGeneric, FormPhoneNumberGeneric, FormEmailGeneric, FormPostcode, FormWebsite, FormOfstedRating, FormEducationLevel } from '../Signup/allFormComponents';
import { SchoolURNScrape } from '../Signup/schoolURNScrape';

export function EditSchoolProfile( {schoolId, data, formLock, handleRefreshData, cancelHandler, masterOveride, reviewOveride } ) {
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        "#SchoolId": schoolId,
        "#SchoolName": data?.FullName,
        "#SchoolEmail": data?.Email,
        "#Address": data?.Address,
        "#Postcode#1": data?.PostcodeArea,
        "#Postcode#2": data?.Postcode.replace(new RegExp('^' + data?.PostcodeArea + '\\s*'), ''),
        "#PhoneNumber": data?.PhoneNumber,
        "#EducationLevel": {
            Primary: data?.EducationLevel?.includes('Primary'),
            Secondary: data?.EducationLevel?.includes('Secondary'),
          },
        "#Headteacher": data?.Headteacher,
        "#OfstedRating": data?.OfstedRating,
        "#SchoolURN": data?.SchoolURN,
        "#Website": data?.Website,
    });
    const identity = useIdentityContext();

    const handleFormChange = (e) => {
        if (!formLock) {
            const { id, name, value, type, checked } = e.target;
            if (type === 'checkbox') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: {
                        ...prevFormData[name],
                        [id]: checked,
                    },
                }));
            } else if (id === '#Postcode') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value.toUpperCase(),
                }));
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [id]: value,
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
        setError(false);
        console.log(formData);
        identity.authorizedFetch(masterOveride ? `/app_api/profile/${encodeURIComponent(schoolId)}` : '/app_api/schoolupdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            handleRefreshData();
            return response.json();
        })
        .catch(error => {
            setIsLoading(false);
            setError(true);
            console.error('Error signing up:', error);
        });
    };

    return (
        <>
            {isLoading ? (<div className="py-5 text-center purple_text">
                    <Spinner animation="border" size="lg" className="purple_spinner" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  </div>) 
                  : (<Form noValidate className="py-2 px-0" onSubmit={handleSubmit} validated={validated}>
            <fieldset>
            <FormNameGeneric label="School Name:" handleFormChange={handleFormChange} formDataSection={formData['#SchoolName']} controlId="#SchoolName" required={true} />
            <SchoolURNScrape formData={formData} required={true} handleFormChange={handleFormChange} justDisplay={true} />
            <FormNameGeneric label="Headteacher/Principal:" handleFormChange={handleFormChange} formDataSection={formData['#Headteacher']} controlId="#Headteacher" required={true} />
            <FormEmailGeneric label="Office Email:" handleFormChange={handleFormChange} formDataSection={formData['#SchoolEmail']} controlId="#SchoolEmail" required={true} />
            <FormAddress label="Address:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormPostcode label="Postcode:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormPhoneNumberGeneric label="Office Phone Number:" handleFormChange={handleFormChange} formDataSection={formData['#PhoneNumber']} controlId='#PhoneNumber' required={true} />
            <FormWebsite label="School Website:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormEducationLevel label="Education Level:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormOfstedRating label="Ofsted Rating:" handleFormChange={handleFormChange} formData={formData} required={true} />
            
            <div className="my-2">
            <Button className="me-2" onClick={cancelHandler} variant="outline-secondary">Cancel</Button>
            <Button type="submit" variant="primary">{reviewOveride ? 'Submit for Review' : 'Update'}</Button>
            </div>
            {error && <p className="text-danger"><small>An error occured, please try again</small></p>}
            </fieldset>
            </Form>)}
        </>
    );
}

// <FormNamePreInput label="Your Full Name:" value={identity.user.user_metadata.full_name} controlId="#Name" />
// <FormEmailPreInput label="Your Email:" value={identity.user.email} controlId="#Email" />
//<FormTRN label="Teacher Reference Number:" handleFormChange={handleFormChange} formData={formData} required={true} />
//<FormDBS label="DBS Number:" handleFormChange={handleFormChange} formData={formData} required={true} />
//<FormDOB label="Date of Birth:" handleFormChange={handleFormChange} formData={formData} required={true} />