import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { JobPagePass } from './jobPagePass';
import LoadingSpinner from '../utilities/loadingSpinner';
import { useIdentityContext } from '../../identity-react';

export function JobPage({ userType }) {
    const identity = useIdentityContext();
    const { jobId } = useParams();
    const isReduxLoading = useSelector((state) => state.schools.selectedSchool?.loading);
    const reduxSchoolId = useSelector((state) => state.schools.selectedSchool?.PK);

    const location = useLocation();
    const passedSchoolId = location.state?.schoolId;
  
    const [schoolId, setSchoolId] = useState(reduxSchoolId);
    const [isLoading, setIsLoading] = useState(isReduxLoading);

    const fetchSchoolId = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await identity.authorizedFetch(userType === 'staff' ? `/app_api/viewJob/${encodeURIComponent(jobId)}` : `/app_api/teacher/checkJob/${encodeURIComponent(jobId)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setSchoolId(data);
        } catch (error) {
            console.error('Authorization failed:', error);
        } finally {
            setIsLoading(false);
        }
    }, [identity, jobId, userType]);

    useEffect(() => {
        if (userType === 'teacher' || userType === "staff") {
            if (passedSchoolId) {
                setSchoolId(passedSchoolId);
            } else {
                fetchSchoolId();
            }
        } else {
            setSchoolId(reduxSchoolId);
        }
    }, [userType, fetchSchoolId, passedSchoolId, setSchoolId, reduxSchoolId]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (schoolId && jobId && userType) {
        return <JobPagePass schoolId={schoolId} jobId={jobId} userType={userType} />;
    }
}