import React, { useState, useEffect } from 'react';
import { MainShadowBox } from './utilities/mainShadowBox';
import { Row, Col } from 'react-bootstrap';
import { useIdentityContext } from '../identity-react';

export const DesignPlayground = () => {
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
      const currentHour = new Date().getHours();
  
      if (currentHour >= 5 && currentHour < 12) {
        setGreeting('Good Morning');
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting('Good Afternoon');
      } else {
        setGreeting('Good Evening');
      }
    }, []);

    const identity = useIdentityContext();
    const user = identity && identity.user;

    const hide = true;
    

    return (
        <>
        <MainShadowBox title={`${greeting} ${user?.user_metadata?.full_name}`} />
        
        {!hide && (<><Row className="w-100 d-flex">
            <Col sm={6}>
                <MainShadowBox>
                    <div className="text-center">
                    <h3>Create Job</h3>
                    </div>
                </MainShadowBox>
            </Col>
            <Col sm={6}>
                <MainShadowBox>
                    This is box 2
                </MainShadowBox>
            </Col>
        </Row>
        <Row className="w-100 d-flex">
            <Col sm={6}>
                <MainShadowBox>
                    Hello
                </MainShadowBox>
            </Col>
            <Col sm={6}>
                <MainShadowBox>
                    This is box 2
                </MainShadowBox>
            </Col>
        </Row></>)}
        </>
    );
}
