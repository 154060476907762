import React, {useState} from 'react';
import { Modal, Button, DropdownItem } from 'react-bootstrap';
import { ListJobs } from './listJobs';

export function ViewSchoolJobsModal ( {linkStyle, schoolId} ) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (!schoolId) {
        return null;
    }

    return (
        <>

            {linkStyle === "button" ? (<Button size="sm" variant="outline-primary" className="w-100 mt-3" onClick={handleShow}>
        View School Jobs
        </Button>) : linkStyle === "dropdownItem" ? (
            <DropdownItem onClick={handleShow}>View Jobs</DropdownItem>
        ) :
        (<Button variant="link" onClick={handleShow}>
            View School Jobs
            </Button>)
        }

        <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <ListJobs userType="staff" displayType="agencySchoolModal" givenId={schoolId} />
        </Modal.Body>
      </Modal>
        </>
    )
};