import React from 'react';
import { DisplayUserProfileSections } from '../utilities/displayUserProfileSections';

export function SchoolUserProfileView( {inputData, formLock, noEdit, masterOveride} ) {
    return (
        <>
            <DisplayUserProfileSections label="Email:" data={inputData.Email} />
            {(!masterOveride || formLock) && (<DisplayUserProfileSections label="Full Name:" data={inputData.FullName} />)}
            {!noEdit && (!masterOveride || !formLock) && <hr className="border-dark-subtle" />}
            {formLock && (<>
            <DisplayUserProfileSections label="Job Title:" data={inputData.JobTitle} />
            </>)}
        </>
    );
}
