import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useIdentityContext } from '../../identity-react';
import { Button, Form } from 'react-bootstrap';
import { FormAddress, FormEducationLevel, FormBio, FormExtraExp, FormPhoneNumberGeneric, FormYearGroups, FormPostcode, FormSubjectSpec, FormDayRate, FormYearsExp, FormTRN, FormDOB, FormDBS, FormNameGeneric } from '../Signup/allFormComponents';
import LoadingSpinner from '../utilities/loadingSpinner';

export function EditTeacherProfile( {data, formLock, handleRefreshData, cancelHandler, masterOveride, reviewOveride } ) {
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        "#FullName": data?.FullName,
        "#Address": data?.Address,
        "#Postcode#1": data?.PostcodeArea,
        "#Postcode#2": data?.Postcode.replace(new RegExp('^' + data?.PostcodeArea + '\\s*'), ''),
        "#PhoneNumber": data?.PhoneNumber,
        "#DayRate": data?.DayRate,
        "#YearsExp": data?.YearsExperience,
        "#Bio": data?.Bio,
        "#DOB": data?.DOB,
        "#TRN": data?.TRN,
        "#DBS": data?.DBS,
        "#EducationLevel": {
            Primary: data?.EducationLevel.includes('Primary'),
            Secondary: data?.EducationLevel.includes('Secondary'),
          },
        "#ExtraExperience": {
            SEN: data?.ExtraExperience.includes('SEN'),
            Behavioural: data?.ExtraExperience.includes('Behavioural'),
            EYFS: data?.ExtraExperience.includes('EYFS'),
          },
        "#YearGroups": {
            EYFS: data?.YearGroups.includes('EYFS'),
            Y1: data?.YearGroups.includes('Y1'),
            Y2: data?.YearGroups.includes('Y2'),
            Y3: data?.YearGroups.includes('Y3'),
            Y4: data?.YearGroups.includes('Y4'),
            Y5: data?.YearGroups.includes('Y5'),
            Y6: data?.YearGroups.includes('Y6'),
            Y7: data?.YearGroups.includes('Y7'),
            Y8: data?.YearGroups.includes('Y8'),
            Y9: data?.YearGroups.includes('Y9'),
            Y10: data?.YearGroups.includes('Y10'),
            Y11: data?.YearGroups.includes('Y11'),
            Y12: data?.YearGroups.includes('Y12'),
            Y13: data?.YearGroups.includes('Y13'),
          },
        "#SubjectSpec": {
            Maths: data?.SubjectSpecialism.includes('Maths'),
            English: data?.SubjectSpecialism.includes('English'),
            Science: data?.SubjectSpecialism.includes('Science'),
            Geography: data?.SubjectSpecialism.includes('Geography'),
            History: data?.SubjectSpecialism.includes('History'),
            MFL: data?.SubjectSpecialism.includes('MFL'),
            PE: data?.SubjectSpecialism.includes('PE'),
            RE: data?.SubjectSpecialism.includes('RE'),
          },
    });
    const identity = useIdentityContext();

    const handleFormChange = (e) => {
        if (!formLock) {
            const { id, name, value, type, checked } = e.target;
            if (type === 'checkbox') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: {
                        ...prevFormData[name],
                        [id]: checked,
                    },
                }));
                if (name === '#EducationLevel') {
                    const inputList = id === "Primary" ? PrimaryGroup : SecondaryGroup;
                    toggleAllYearGroupCheckboxes(checked, inputList);
                }
            } else if (id === '#Postcode') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value.toUpperCase(),
                }));
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [id]: value,
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
        setError(false);
        console.log(formData);
        identity.authorizedFetch(masterOveride ? `/app_api/profile/${encodeURIComponent(masterOveride)}` : '/app_api/teacherupdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            handleRefreshData();
            return response.json();
        })
        .catch(error => {
            setIsLoading(false);
            setError(true);
            console.error('Error signing up:', error);
        });
    };

    const PrimaryGroup = ['EYFS', 'Y1', 'Y2', 'Y3', 'Y4', 'Y5', 'Y6'];
    const SecondaryGroup = ['Y7', 'Y8', 'Y9', 'Y10', 'Y11', 'Y12', 'Y13'];

    const toggleAllYearGroupCheckboxes = (checked, inputGroup) => {
        const updatedYearGroups = { ...formData['#YearGroups'] };
        inputGroup.forEach(groupName => {
            updatedYearGroups[groupName] = checked;
        });
        setFormData((prevFormData) => ({
            ...prevFormData,
            '#YearGroups': updatedYearGroups,
        }));
    };

    return (
        <>
            {(!masterOveride && !reviewOveride) && (<div className="text-secondary m-0 mb-4"><small>You can't edit your Name, Email, Teacher Reference Number, DBS Number or Date of Birth.
                <br />If there is an error with any of these details please contact your representative.</small></div>)}
            {isLoading ? (<LoadingSpinner />) 
                  : (<Form noValidate className="py-2 px-0" onSubmit={handleSubmit} validated={validated}>
            <fieldset>
            {(masterOveride || reviewOveride) && (
                <>
                <FormNameGeneric label="Full Name:" handleFormChange={handleFormChange} formDataSection={formData['#FullName']} controlId="#FullName" required={masterOveride || reviewOveride} />
                <FormTRN label="Teacher Reference Number:" handleFormChange={handleFormChange} formData={formData} required={masterOveride || reviewOveride} />
                <FormDBS label="DBS Number:" handleFormChange={handleFormChange} formData={formData} required={masterOveride || reviewOveride} />
                <FormDOB label="Date of Birth:" handleFormChange={handleFormChange} formData={formData} required={masterOveride || reviewOveride} />
                </>
            )}
            <FormAddress label="Address:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormPostcode label="Postcode:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormPhoneNumberGeneric label="Phone Number:" handleFormChange={handleFormChange} formDataSection={formData['#PhoneNumber']} controlId='#PhoneNumber' required={true} />
            <FormEducationLevel label="Education:" handleFormChange={handleFormChange} formData={formData} required={true} />
            {(formData['#EducationLevel'] && (formData['#EducationLevel']['Primary'] || formData['#EducationLevel']['Secondary'])) && (
            <FormYearGroups label="Year Groups:" handleFormChange={handleFormChange} formData={formData} />
            )}
            <FormBio label="Bio:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormSubjectSpec label="Subject Specialism:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormExtraExp label="Previous Experience with..." handleFormChange={handleFormChange} formData={formData} />
            <FormDayRate label="Day Rate" handleFormChange={handleFormChange} formData={formData} required={true} />
            <FormYearsExp label="Teaching Experience" handleFormChange={handleFormChange} formData={formData} required={true} />
            
            <div className="my-2">
            <Button className="me-2" onClick={cancelHandler} variant="outline-secondary">Cancel</Button>
            <Button type="submit" variant="primary">{reviewOveride ? 'Submit for Review' : 'Update'}</Button>
            </div>
            {error && <p className="text-danger"><small>An error occured, please try again</small></p>}
            </fieldset>
            </Form>)}
        </>
    );
}

// <FormNamePreInput label="Your Full Name:" value={identity.user.user_metadata.full_name} controlId="#Name" />
// <FormEmailPreInput label="Your Email:" value={identity.user.email} controlId="#Email" />
//<FormTRN label="Teacher Reference Number:" handleFormChange={handleFormChange} formData={formData} required={true} />
//<FormDBS label="DBS Number:" handleFormChange={handleFormChange} formData={formData} required={true} />
//<FormDOB label="Date of Birth:" handleFormChange={handleFormChange} formData={formData} required={true} />