import React from 'react';
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIdentityContext } from '../../../identity-react';
import { AgencyProfileViewer } from '../agencyProfileViewer';
import { GetTeacherAvails } from '../../getTeacherAvails';
import { ViewSchoolStaffModal } from '../../viewSchoolStaffModal';
import { ViewSchoolJobsModal } from '../../viewSchoolJobsModal';

export function ManageUserDropdown( {userId, afterEffects} ) {
  const identity = useIdentityContext();

  const [typeOfUser] = userId.split('#');

  const handleUserCodeSubmit = async (action) => {
    try {
        if (!action || !typeOfUser || !userId) {
            throw new Error('Missing required parameters');
        }

        const response = await identity.authorizedFetch(`/app_api/account/${encodeURIComponent(action)}/${encodeURIComponent(typeOfUser)}/${encodeURIComponent(userId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        await response.json();
        afterEffects();
    } catch (error) {
        console.error('Error handling user code submission:', error);
    }
};



  return (
    <>
      <div className="mx-2">
      <AgencyProfileViewer profileId={userId} typeOfUser={typeOfUser} linkStyle={'iconButton'} />
      </div>

      <OverlayTrigger placement="top" overlay={<Tooltip id={`${userId}Manage`}>Manage User</Tooltip>}>
      <DropdownButton variant="outline-secondary" size="sm" id="dropdown-basic-button" title={<><i className="bi bi-gear-wide-connected"></i></>}>
      {typeOfUser === "teacher" && <GetTeacherAvails userId={userId} linkType="dropdownItem" staffFlag={true} showKey={true} />}
      {typeOfUser === "school" && <ViewSchoolStaffModal schoolId={userId} linkStyle="dropdownItem" />}
      {typeOfUser === "school" && <ViewSchoolJobsModal schoolId={userId} linkStyle="dropdownItem" />}
      <Dropdown.Item onClick={() => handleUserCodeSubmit('deactivate')}>Deactivate</Dropdown.Item>
    </DropdownButton>
    </OverlayTrigger>

    </>
  );
};