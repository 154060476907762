import React from 'react';
import { useSelector } from 'react-redux';
import { getAppLogo, getAppName } from '../../features/display/displaySlice';

function LoginBox({ children, noLegals }) {
    const appLogo = useSelector(getAppLogo);
    const appName = useSelector(getAppName);

      //this should be the img url in non demo mode
  //<img src={`${appLogo}?${new Date().toISOString().split('T')[0]}`}
  ////src={`${appLogo}?${new Date().getTime()}`}

    return (
        <div className="bg_gradient_purp container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh", minWidth: "100vw" }}>
            <div className="col-12 col-md-3 text-center">
                <img className="p-4 mb-2" src={`${appLogo}?${new Date().getTime()}`} alt={appName} style={{ width: "80%" }} />
                <div className="bg-body shadow-sm rounded-4 p-4">
                    {children}
                </div>
            </div>
            {!noLegals && (<div className="fixed-bottom text-center">
                <small>
                    <a href="/terms" target="_blank">Privacy Policy</a>
                </small>
            </div>)}
        </div>
    );
}

export default LoginBox;