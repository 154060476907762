import React, { useState } from 'react';
import { Button, Modal, DropdownItem, Badge, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from './loadingSpinner';
import { setSelectedSchool, setDefaultSchool } from '../../features/schools/schoolsSlice';
import { AddNewSchoolModal } from './addNewSchoolModal';
import { useIdentityContext } from '../../identity-react';

export function SchoolSwitcher( { linkStyle } ) {
    const selectedSchool = useSelector((state) => state.schools.selectedSchool);
    const schoolList = useSelector((state) => state.schools.schoolList);
    const isReduxLoading = useSelector((state) => state.schools.loading);
    const identity = useIdentityContext();

    const defaultSchoolPKs = schoolList
    .filter(school => school.DefaultSchool)
    .map(school => school.PK);

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleOptionClick = (schoolPK) => {
        dispatch(setSelectedSchool(schoolPK));
        //handleClose();
      };

    const handleDefaultClick = (schoolPK) => {
        dispatch(setDefaultSchool({
            identity: identity,
            newDefault: schoolPK,
            currentDefault: defaultSchoolPKs
          }));
    }

    return (
        <>
        {linkStyle === "button" ? (<Button variant="primary" onClick={handleShow}>
        Switch Schools
        </Button>) : linkStyle === "dropdownItem" ? (
            <DropdownItem onClick={handleShow}>Switch Schools</DropdownItem>
        ) : linkStyle === "smallButton" ? (
            <Button size="sm" variant="light" onClick={handleShow}>
                Switch Schools
                </Button>
        ) : 
        (<Button variant="link" onClick={handleShow}>
            Switch Schools
            </Button>)
        }

        <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>Switch Schools
        </Modal.Header>
        <Modal.Body>
        {isReduxLoading ? <LoadingSpinner /> : (<>
                {schoolList.map((school) => (
                        <div key={`${school?.PK}-container`} className="d-flex text-center align-items-center align-middle rounded-2">
                        <Col key={`${school?.PK}-imagecol`} className="flex-grow-1 py-1" xs={2}>
                            <Image key={`${school?.PK}-schoollogo`} src={school?.SchoolLogo} alt="school_logo" fluid rounded />
                        </Col>
                        <Col key={`${school?.PK}-namecol`} className="flex-grow-1" xs={6}>
                            <p className="m-0">
                                {school?.FullName}
                                {(['school_deactivated', 'school_pending'].includes(school?.classifictionGSI) || school?.AccessLevel === "requested" || school?.DefaultSchool) && <Badge key={`${school?.PK}-badge`} className="ms-2" bg={school?.classifictionGSI === "school_deactivated" ? 'danger' : 'info'}>{school?.classifictionGSI === "school_deactivated" ? 'Deactivated' : school?.classifictionGSI === "school_pending" ? 'Pending' : school?.AccessLevel === "requested" ? 'Request Sent' : school?.DefaultSchool ? 'Default' : null}</Badge>}
                            </p>
                        </Col>
                        <Col key={`${school?.PK}-defaultcol`} className="flex-shrink-1" xs={1}>
                                        {!school?.DefaultSchool && (<OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`${school?.PK}-makedefaulttooltip`}>Make Default</Tooltip>}
                            >
                            <Button key={`${school?.PK}-makedefaultbutton`} onClick={() => handleDefaultClick(school?.PK)} size="sm" variant={school?.DefaultSchool ? 'warning' : 'secondary'} disabled={school?.DefaultSchool}>{school?.DefaultSchool ? <i className="bi bi-bookmark-star-fill"></i> : <i className="bi bi-bookmark-plus"></i>}</Button>
                            </OverlayTrigger>)}
                        </Col>
                        <Col key={`${school?.PK}-selectcol`} className="flex-grow-1" xs={1}>
                            <Button key={`${school?.PK}-selectschoolbutton`} onClick={() => handleOptionClick(school?.PK)} className="w-100" size="sm" variant={school?.PK === selectedSchool?.PK ? 'success' : 'outline-success'}>
                                {school?.PK === selectedSchool?.PK ? 'Selected' : 'Select'}
                            </Button>
                        </Col>
                    </div>

                ))}
                </>
        )}
        
        <AddNewSchoolModal linkStyle={'button'} />
                </Modal.Body>
      </Modal>
        </>
    );
}