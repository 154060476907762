import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';
import { FormOccupation, FormNamePreInput, FormEmailPreInput } from './allFormComponents';
import LoadingSpinner from '../utilities/loadingSpinner';
import { useNavigate } from 'react-router-dom';

export function StaffSignUpForm () {
    const identity = useIdentityContext();
    const [formData, setFormData] = useState("");
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    
    const handleFormChange = (e) => {
        const { id, name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: {
                    ...prevFormData[name],
                    [id]: checked,
                },
            }));
        } else if (id === '#Postcode') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.toUpperCase(),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
        identity.authorizedFetch('/app_api/staffsignup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({formData: formData})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(identity.refreshUser)
        .then(navigate('/'))
        .catch(error => {
            setIsLoading(false);
            console.error('Error signing up:', error);
        });
    };

    return (
        <>
            {isLoading ? (<LoadingSpinner><p className="mt-3">Signing Up</p></LoadingSpinner>
                  ) 
                  : (<Form noValidate className="py-2 px-0 p-md-4" onSubmit={handleSubmit} validated={validated}>
                <fieldset>
                    <FormNamePreInput label="Your Full Name:" value={identity.user.user_metadata.full_name} controlId="#Name" />
                    <FormEmailPreInput label="Your Email:" value={identity.user.email} controlId="#Email" />
                    <FormOccupation label="Your Job Title:" handleFormChange={handleFormChange} formData={formData} required={true} />


                    <Button type="submit" variant="primary" id="#SignUpButton">Sign Up</Button>
                </fieldset>
            </Form>)}
        </>
    )
};
