import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import LoadingSpinner from '../utilities/loadingSpinner';
import { useIdentityContext } from '../../identity-react';
import { DisplayTeacher } from '../displayTeacher';

export function ViewTeacherProfileModal( { show, handleClose, userId, jobId } ) {
    const identity = useIdentityContext();
    const [error, setError] = useState(null);
    const [displayData, setDisplayData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchTeacherData = useCallback(async () => {
        try {
            const response = await identity.authorizedFetch(`/app_api/candidateProfile/${encodeURIComponent(jobId)}/${encodeURIComponent(userId)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An unexpected error occurred');
            }
            const data = await response.json();
            setDisplayData(data);
            setIsLoading(false);
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    }, [identity, jobId, userId]);
    

    useEffect(() => {
        if (show) {
            fetchTeacherData();
        }
    }, [show, fetchTeacherData]);

    return (
        <>
        <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            {isLoading ? <LoadingSpinner /> : error ? <p>an unexpected error occured 2103</p> : 
            (
            displayData && <DisplayTeacher result={displayData} />
            )}
        </Modal.Body>
      </Modal>
        </>
    );
}