import React, { useState, useCallback, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';

export function JobCounterBadge ( {countType, countParam, colour} ) {
    const [counterFig, setCounterFig] = useState(null);
    const [hasMore, setHasMore] = useState(null);
    const identity = useIdentityContext();

    const fetchCount = useCallback(async (countType = null, countParam = null) => {
        try {
            const response = await identity.authorizedFetch(`/app_api/count/${encodeURIComponent(countType)}/${encodeURIComponent(countParam)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch count');
            }
            const data = await response.json();
            setCounterFig(data.Count);
            setHasMore(data.hasMore);
        } catch (error) {
            console.error('Error fetching count:', error);
        }
    }, [identity]);

    useEffect(() => {
        const validCountTypes = ["job", "requests"];
        const validCountParams = ["open", "pending", "teacher_pending", "school_pending", "staff_pending"];
        if (validCountParams.includes(countParam) && validCountTypes.includes(countType)) {
            fetchCount(countType, countParam);
        } else {
            setCounterFig(null);
        }
    }, [fetchCount, countType, countParam]);


    return (
        <>
        {counterFig > 0 && <Badge pill bg={colour ? colour : 'danger'} className="ms-2">{counterFig}{hasMore && "+"}</Badge>}
        </>
    )
};
