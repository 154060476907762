import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { formatDistanceToNow, format } from 'date-fns';
import { DisplayUserProfileSections } from '../utilities/displayUserProfileSections';

export function JobDetailsSection({ jobDetails, SchoolLogo }) {
    const [showDateBox, setShowDateBox] = useState(false);
    const [tooltipPos, setTooltipPos] = useState('left');

    useEffect(() => {
        if (window.innerWidth < 576) {
            setTooltipPos('top-start');
        };
    }, []);

    const getMinMaxDates = (dates, type) => {
        if (type === "min") {
            return new Date(Math.min(...dates.map(date => new Date(date))));
        } else if (type === "max") {
            return new Date(Math.max(...dates.map(date => new Date(date))));
        }
    };

    const options = { day: 'numeric', month: 'short', year: 'numeric' }

    function niceList(subjects) {
        if (subjects.length === 0) {
            return '';
        } else if (subjects.length === 1) {
            return subjects[0];
        } else if (subjects.length === 2) {
            return subjects[0] + ' & ' + subjects[1];
        } else {
            return subjects.slice(0, -1).join(', ') + ' & ' + subjects[subjects.length - 1];
        }
    }

    return (
        <>
            <Row className="d-flex flex-column-reverse flex-sm-row">
                <Col sm={8}>
                    {jobDetails.JobDates && (<OverlayTrigger
                        placement={tooltipPos}
                        overlay={<Tooltip id="supplydatestooltip">Dates</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <p {...triggerHandler} className="mb-1"><i ref={ref} className="bi bi-calendar-range me-2 text-secondary"></i>{jobDetails.JobDates.length > 1 ? `Between ${getMinMaxDates(jobDetails.JobDates, 'min').toLocaleDateString('en-GB', options)} and ${getMinMaxDates(jobDetails.JobDates, 'max').toLocaleDateString('en-GB', options)}` : (jobDetails.JobDates)}
                                {jobDetails.JobDates.length > 1 && <Button className="my-2 my-sm-0 mx-3" size="sm" variant='outline-secondary' onClick={() => setShowDateBox(!showDateBox)}>{showDateBox ? 'Hide' : 'Show'} Full Dates</Button>}</p>)}</OverlayTrigger>)}

                    {showDateBox && (<Row className="m-3">
                        <Col sm={4}>

                            <DatePicker
                                calendarClassName="display-datepicker"
                                selectedDates={jobDetails.JobDates}
                                selectsMultiple
                                shouldCloseOnSelect={false}
                                disabledKeyboardNavigation
                                minDate={getMinMaxDates(jobDetails.JobDates, 'min')}
                                maxDate={getMinMaxDates(jobDetails.JobDates, 'max')}
                                calendarStartDay={1}
                                inline
                            />

                        </Col>
                    </Row>)}

                    {jobDetails.JobDescription && (<OverlayTrigger
                        placement={tooltipPos}
                        overlay={<Tooltip id="jobdescription">Description</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <p {...triggerHandler} className="mb-1">
                                <i ref={ref} className="bi bi-journal-text me-2 text-secondary"></i>
                                <DisplayUserProfileSections data={jobDetails.JobDescription} raw={true} /></p>)}</OverlayTrigger>)}


                    {jobDetails.Subjects && jobDetails.Subjects.length > 0 && (<OverlayTrigger
                        placement={tooltipPos}
                        overlay={<Tooltip id="subjectspopover">Subject{jobDetails.Subjects.length > 1 && 's'}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <p {...triggerHandler} className="mb-1">
                                <i ref={ref} className="bi bi-book me-2 text-secondary"></i>
                                {niceList(jobDetails.Subjects)}
                            </p>)}</OverlayTrigger>)}

                    {jobDetails.YearGroup && jobDetails.YearGroup.length > 0 && (<OverlayTrigger
                        placement={tooltipPos}
                        overlay={<Tooltip id="yeargroupspopover">Year Group{jobDetails.YearGroup.length > 1 && 's'}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <p {...triggerHandler} className="mb-1">
                                <i ref={ref} className="bi bi-bus-front me-2 text-secondary"></i>
                                {niceList(jobDetails.YearGroup)}
                            </p>)}</OverlayTrigger>)}

                    {jobDetails.ExpNeeded && jobDetails.ExpNeeded.length > 0 && (<OverlayTrigger
                        placement={tooltipPos}
                        overlay={<Tooltip id="expneededtooltip">Experience Needed</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <p {...triggerHandler} className="mb-1">
                                <i ref={ref} className="bi bi-star me-2 text-secondary"></i>
                                {niceList(jobDetails.ExpNeeded)}
                            </p>)}</OverlayTrigger>)}

                            {jobDetails.AdditionalInfo && (<OverlayTrigger
                        placement={tooltipPos}
                        overlay={<Tooltip id="additionalinfo">Additional Info</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <p {...triggerHandler} className="mb-1">
                                <i ref={ref} className="bi bi-journal-plus me-2 text-secondary"></i><DisplayUserProfileSections data={jobDetails.AdditionalInfo} raw={true} />
                            </p>)}</OverlayTrigger>)}

                </Col>

                <Col className="mb-2 mb-sm-0 align-items-center align-items-sm-end text-center text-sm-end" sm={4}>
                    {SchoolLogo && <Image className="w-75" alt="profile_image" src={SchoolLogo} rounded />}
                    <div className="my-2 my-sm-0 d-flex d-sm-block d-flex justify-content-between">
                    <p className="m-0 text-secondary"><small>Created by {jobDetails.CreatedByName}<i className="bi bi-person-up ms-2"></i></small></p>
                    <OverlayTrigger
                        placement="bottom-end"
                        overlay={<Tooltip id="createdtimepopover">{format(jobDetails.CreatedAt, 'E do LLL p')}</Tooltip>}
                    >{({ ref, ...triggerHandler }) => (
                        <p {...triggerHandler} className="m-0 text-secondary"><small>{formatDistanceToNow(jobDetails.CreatedAt, { addSuffix: true })}<i ref={ref} className="bi bi-clock ms-2"></i></small></p>)}
                    </OverlayTrigger>
                    </div>
                    <hr className="d-block d-sm-none" />
                </Col>

            </Row>
        </>
    );
}