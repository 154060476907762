import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useIdentityContext } from '../../identity-react';
import { Button, Form } from 'react-bootstrap';
import { FormOccupation, FormNameGeneric } from '../Signup/allFormComponents';
import LoadingSpinner from '../utilities/loadingSpinner';

export function EditSchoolUserProfile( {data, formLock, handleRefreshData, cancelHandler, masterOveride, staff } ) {
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        "#UserOccupation": data.JobTitle,
        "#FullName": data.FullName,
    });
    const identity = useIdentityContext();

    const handleFormChange = (e) => {
        if (!formLock) {
            const { id, name, value, type, checked } = e.target;
            if (type === 'checkbox') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: {
                        ...prevFormData[name],
                        [id]: checked,
                    },
                }));
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [id]: value,
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
        setError(false);
        console.log(formData);
        identity.authorizedFetch(masterOveride ? `/app_api/profile/${encodeURIComponent(masterOveride)}` : `/app_api/schoolUserupdate/${staff ? 'staff' : 'schooluser'}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            handleRefreshData();
            return response.json();
        })
        .catch(error => {
            setIsLoading(false);
            setError(true);
            console.error('Error signing up:', error);
        });
    };

    return (
        <>
            {!masterOveride && (<div className="text-secondary m-3 mb-4"><small>You can't edit your Name or Email.
                <br />If there is an error with any of these details please contact your representative.</small></div>)}
            {isLoading ? (<LoadingSpinner />) 
                  : (<Form noValidate className="py-2 px-0" onSubmit={handleSubmit} validated={validated}>
            <fieldset>
            {masterOveride && <FormNameGeneric label="Full Name:" handleFormChange={handleFormChange} formDataSection={formData['#FullName']} controlId="#FullName" required={masterOveride} />}
            <FormOccupation label="Job Title:" handleFormChange={handleFormChange} formData={formData} required={true} />
            <div className="my-2">
            <Button className="me-2" onClick={cancelHandler} variant="outline-secondary">Cancel</Button>
            <Button type="submit" variant="primary">Update</Button>
            </div>
            {error && <p className="text-danger"><small>An error occured, please try again</small></p>}
            </fieldset>
            </Form>)}
        </>
    );
}