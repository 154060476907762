import React from 'react';
import { Col, Row } from 'react-bootstrap';

export function DisplayUserProfileSections({ label, data, noMargin, children, raw }) {
    // Ensure data is a string
    const formattedData = String(data).split('\n').map((line, index) => (
        <span key={index}>
            {line}
            <br />
        </span>
    ));

    if (raw && data) {
        return (
            formattedData
        )
    }

    return (
        <>
            <Row className={noMargin ? '' : 'mb-3'}>
                {label && <Col className="text-secondary" sm={3}>{label}</Col>}
                <Col>{formattedData}</Col>
                {children && <Col>{children}</Col>}
            </Row>
        </>
    );
}