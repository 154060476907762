import React, { useState } from 'react';
import { useIdentityContext } from '../../identity-react';
import { Col, Row, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export function JobRequestButtons( {jobId, schoolId, afterEffects} ) {
    const identity = useIdentityContext();
    const [isLoading, setIsLoading] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (reply) => {
        setIsLoading(reply);
        setError(null);
        identity.authorizedFetch(`/app_api/respondToRequest/${reply}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ '#jobId': jobId, '#schoolId': schoolId })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            if (reply === "reject") {
                navigate('/jobrequests');
            } else {
                afterEffects();
            }
            return response.json();
        })
        .then(data => {
          console.log('Return from job request response:', data);
        })
        .catch(error => {
            console.error('Error responding to request:', error);
            setError('An error occured. Please try again.')
            setIsLoading(null);
        });
    };

    return (

        <div className="mt-3">
            <Row>
                    <Col className="d-flex text-center align-items-center gap-2" sm={12}>
                    <Button onClick={() => handleSubmit('reject')} className="w-100" variant="outline-danger" size="lg" disabled={isLoading}>
                        Decline{isLoading === "reject" && <Spinner className="ms-2" size="sm" />}
                    </Button>
                    <Button onClick={() => handleSubmit('confirm')} className="w-100" variant="success" size="lg" disabled={isLoading}>
                        Accept{isLoading === "confirm" && <Spinner className="ms-2" size="sm" />}
                    </Button>
                </Col>
            </Row>
            {error && <p className="text-danger m-0 mt-2 text-center"><small><i className="bi bi-exclamation-triangle me-2"></i>{error}</small></p>}
        </div>
    );
}