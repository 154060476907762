import React from 'react';
import { Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ManageUserDropdown } from './manageUserDropdown';
import { SendTeacherRequest } from '../../JobPage/sendTeacherRequest';
import { ManageUserPermissionsButton } from './manageUserPermissionsButton';
import { AgencyApproveButton } from './agencyApproveButton';
import { ReactivateButton } from './reactivateButton';
import { ManageStaffPermissionsButton } from './manageStaffPermissionsButton';

const ApproveRejectSmall = ( { schoolId }) => {
    return (
                        <OverlayTrigger placement="top" overlay={<Tooltip id={`rejectTooltip`}>Reject</Tooltip>}>
                            <Button size="sm" variant='danger'><i className="bi bi-x-lg"></i></Button>
                        </OverlayTrigger>
    );
}

export function ListFunctionButtons({ functionButton, result, afterEffects }) {
    const renderButton = () => {
      if (!functionButton || !functionButton.type) {
        return null;
      }
  
      switch (functionButton.type) {
        case "manage_school_staff":
            return functionButton.schoolId && result.AccessLevel ? (
                <Col sm={1} className="d-flex align-items-center justify-content-center gap-1">
                        <ManageUserPermissionsButton 
                            userId={result.PK} 
                            initPermission={result.AccessLevel !== "requested" && result.AccessLevel} 
                            schoolId={functionButton.schoolId} 
                            afterEffects={afterEffects} 
                            approve={result.AccessLevel === "requested" && true}
                        />
                </Col>
            ) : null;
        case "manage_agency_staff":
                return result.AccessLevel ? (
                    <Col sm={1} className="d-flex align-items-center justify-content-center gap-1">
                            <ManageStaffPermissionsButton 
                                userId={result.PK} 
                                initPermission={result.AccessLevel !== "requested" && result.AccessLevel} 
                                afterEffects={afterEffects} 
                                approve={result.AccessLevel === "requested" && true}
                            />
                    </Col>
                ) : null;       
        case "approve_school_staff":
            return functionButton.schoolId ? <ApproveRejectSmall schoolId={functionButton.schoolId} /> : null;
        case "agency_approve_profile":
            return result.PK ? (
            <Col sm={2} className="d-flex align-items-center text-center justify-content-center">
                <AgencyApproveButton userId={result.PK} afterEffects={afterEffects} />
            </Col>
        ) : null;
        case "agency_reactivate":
            return result.PK ? (
            <Col sm={2} className="d-flex align-items-center text-center justify-content-center">
                <ReactivateButton userId={result.PK} afterEffects={afterEffects} />
            </Col>
        ) : null;
        case "manage_user":
            return result.PK ? <Col sm={1} className="d-flex align-items-center justify-content-center"><ManageUserDropdown userId={result.PK} afterEffects={afterEffects} /></Col> : null;
        case "send_teacher_request":
            return result.PK && functionButton.jobId && functionButton.schoolId ? (
                <Col className="d-flex align-items-center justify-content-center"><SendTeacherRequest userId={result.PK} jobId={functionButton.jobId} schoolId={functionButton.schoolId} afterEffects={afterEffects} /></Col>
            ) : null;
        default:
          return null;
      }
    };
  
    return (
      <>{renderButton()}</>
    );
  }