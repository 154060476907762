import React, { useState, useCallback, useRef } from 'react';
import { Image, Form, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg';

const PhotoUpload = ({ onImageCropUpload, aspectRatio, isRequired, format, allowZoomOut }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const fileInputRef = useRef(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const handleZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setCroppedImage(null);
        setZoom(1);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleEditCrop = () => {
    setCroppedImage(null);
    onImageCropUpload(null);
  };

  const handleClearImg = () => {
    setCroppedImage(null);
    setSelectedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onImageCropUpload(null);
  };

  const showCroppedImage = useCallback(async (e) => {
    e.preventDefault();
    try {
      const croppedImageUrl = await getCroppedImg(selectedImage, croppedAreaPixels, format, zoom);
      setCroppedImage(croppedImageUrl);
      onImageCropUpload(croppedImageUrl);
    } catch (e) {
      console.error('Error:', e);
    }
  }, [selectedImage, croppedAreaPixels, onImageCropUpload, format, zoom]);

  return (
    <div>
      <Form.Control
        ref={fileInputRef}
        className="mb-3"
        type="file"
        onChange={onSelectFile}
        accept="image/*"
        required={isRequired}
      />
      {(selectedImage && !croppedImage) && (
        <div style={{ position: 'relative', width: '200px', height: '200px' }}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            minZoom={allowZoomOut ? 0.5 : 1}
            aspect={aspectRatio}
            onCropChange={handleCropChange}
            onZoomChange={handleZoomChange}
            onCropComplete={onCropComplete}
            restrictPosition={allowZoomOut ? false : true}
            cropAreaPixels={croppedAreaPixels}
            cropArea={() => (
              <div
                style={{
                  position: 'absolute',
                  border: '2px solid white',
                  boxShadow: '0 0 0 1000px rgba(0, 0, 0, 0.5)',
                  overflow: 'hidden',
                  width: `${croppedAreaPixels?.width}px`,
                  height: `${croppedAreaPixels?.height}px`,
                }}
              />
            )}
          />
          <button
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              padding: '10px',
              backgroundColor: 'blue',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={showCroppedImage}
          >
            Confirm
          </button>
        </div>
      )}
      {croppedImage && (
        <>
          <div style={{ position: 'relative', width: '200px' }}>
            <Image src={croppedImage} alt="Cropped Image" thumbnail />
          </div>
          <div className="d-flex justify-content-center" style={{ width: '200px' }}>
            <Button className="m-2" variant="light" size="sm" onClick={handleEditCrop}>
              Edit
            </Button>
            <Button className="m-2" variant="light" size="sm" onClick={handleClearImg}>
              Clear
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PhotoUpload;