import React from 'react';
import { MainShadowBox } from './utilities/mainShadowBox';
import { useSelector } from 'react-redux';
import { StaffList } from './staffList';

export function SchoolStaffListHolder () {
    const selectedSchoolPK = useSelector((state) => state.schools.selectedSchool?.PK);

    if (!selectedSchoolPK) {
        return null;
    }

    return (
        <>
        <MainShadowBox title='Staff List'>
            <StaffList schoolId={selectedSchoolPK} />
        </MainShadowBox>
        </>
    )
};