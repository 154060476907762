import React from 'react';
import { useSelector } from 'react-redux';
import { JobCreateForm } from './jobCreateForm';
import { TeacherProfile, SchoolProfile, SchoolUserProfile } from './userprofilepage/userProfile';
import { ListJobs } from './listJobs';
import { ListAllEntities } from './listAllEntities';
import { EditTheme } from './editTheme';
import { useIdentityContext } from '../identity-react';
import { Navigate } from 'react-router-dom';
import { JobPage } from './JobPage/jobPage';
import { DesignPlayground } from './designPlayground';
import { SetAvails } from './setAvails';
import CheckAccessLevel from './utilities/checkAccessLevel';
import { AgencyApprovals, AgencyDeactivated } from './listApprovalsDeactivates';
import AlertModule from './utilities/alertModule';
import { AgencyStaffList } from './agencyStaffList';
import { SchoolStaffListHolder } from './schoolStaffListHolder';
import AddAlertForm from './utilities/addAlertForm';
import { MainShadowBox } from './utilities/mainShadowBox';
import { AgencyListJobs } from './agencyListJobs';
import OfflineWarning from './utilities/offlineWarning';

export function ActivePane() {
  const isCollapsed = useSelector((state) => state.display.is_collapsed);
  const alerts = useSelector((state) => state.alerts.alerts);
  const activePage = useSelector((state) => state.display.active_page);
  const schoolPermissionLevel = useSelector((state) => state.schools.selectedSchool?.AccessLevel);
  const identity = useIdentityContext();

  const userHasRole = (role) => {
    return identity?.user?.app_metadata?.roles?.includes(role);
  };

  const renderPage = () => {
    switch (activePage) {
      case "schoolprofile":
        return userHasRole('school') && CheckAccessLevel('editor', schoolPermissionLevel) ? <><SchoolProfile /></> : <Navigate to="/" />;
      case "userprofile":
        return userHasRole('school') ? <><SchoolUserProfile /></> : userHasRole('teacher') ? <TeacherProfile /> : userHasRole('staff') ? <SchoolUserProfile staff={true} /> : <Navigate to="/" />;
      case "requestsupply":
        return userHasRole('school') && CheckAccessLevel('editor', schoolPermissionLevel) ? (
                      <JobCreateForm />)
                      : <Navigate to="/" />;
      case "jobs":
        return userHasRole('school') ? <><MainShadowBox title="Jobs List"><ListJobs userType='school' /></MainShadowBox></> : userHasRole('teacher') ? <MainShadowBox title="Jobs List"><ListJobs userType='teacher' /></MainShadowBox> : userHasRole('staff') ? <AgencyListJobs /> : <Navigate to="/" />;
      case "setavailability":
          return userHasRole('teacher') ? <SetAvails /> : <><Navigate to="/" />Error 404</>;
      case "listall":
        return userHasRole('staff') ? <ListAllEntities /> : <><Navigate to="/" /></>;
      case "approvalrequests":
        return userHasRole('staff') ? <AgencyApprovals /> : <><Navigate to="/" /></>;
      case "deactivatedusers":
        return userHasRole('staff') ? <AgencyDeactivated /> : <><Navigate to="/" /></>;
      case "edittheme":
        return userHasRole('staff') ? <EditTheme /> : <><Navigate to="/" /></>;
      case "broadcastmessage":
        return userHasRole('staff') ? <AddAlertForm /> : <><Navigate to="/" /></>;
      case "jobrequests":
        return userHasRole('teacher') ? <><MainShadowBox title="Jobs List"><ListJobs userType='teacher' displayType='requests' /></MainShadowBox></> : <Navigate to="/" />;
      case "stafflist":
          return userHasRole('school') && CheckAccessLevel('admin', schoolPermissionLevel) ? <><SchoolStaffListHolder /></> : userHasRole('staff') ? <AgencyStaffList /> : <Navigate to="/" />;
      case "job":
          return userHasRole('school') ? <JobPage userType={'school'} /> : userHasRole('teacher') ? <JobPage userType={'teacher'} /> : userHasRole('staff') ? <JobPage userType={'staff'} /> : null;
      default:
        return <DesignPlayground />;
    }
  };

  return (
    <div className={`transition-col col-12 col-md-${isCollapsed ? "11 offset-md-1" : "9 offset-md-3"} p-0 py-2 p-sm-4 d-flex flex-column align-items-center mb-3`}>
      <OfflineWarning />
      {alerts.map(alert => (<AlertModule key={alert?.id} alertId={alert?.id} variant={alert?.variant} alertHeading={alert?.heading} alertBody={alert?.message} timedRemove={alert?.dismissible} />))}
      {renderPage()}
    </div>
  );
}
