import React, { useState } from 'react';
import './mainStyles.css';
import { MainShadowBox } from './utilities/mainShadowBox';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button } from 'react-bootstrap';
import ThemeUpdater from './utilities/themeUpdater';
import { EditImage } from './utilities/editImage';
import { getAppLogo, updateAppName, getAppName } from '../features/display/displaySlice';
import { FormNameGeneric } from './Signup/allFormComponents';
import { DisplayUserProfileSections } from './utilities/displayUserProfileSections';
import { useIdentityContext } from '../identity-react';

export function EditTheme() {
    const appLogo = useSelector(getAppLogo);
    const appName = useSelector(getAppName);
    const [formLock, setFormLock] = useState(true);

    return (
        <>
            <MainShadowBox title="Edit Theme">
                <p className="text-secondary"><small>These settings update what all users see when using this app.</small></p>
                <br />
                <Row className="mb-2">
                    <Col className="text-secondary" sm={3}>Set Main Colour:</Col>
                    <Col sm={7}>
                        <ThemeUpdater />
                    </Col>
                </Row>
                <Row className="mb-2">
                <Col className="text-secondary" sm={3}>Company Logo:</Col>
                <Col sm={3}>
                    <EditImage origImage={appLogo} aspectRatio={3 / 1} shape={'rounded'} dynamoImageAttr={'AppLogo'} entryType={'theme'} format={'image/png'} allowZoomOut={true} />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col sm={3}></Col>
                <Col className="text-secondary">
                <small>For best results use a logo with a transparent background.<br />Scroll to zoom out the image and position in the cropzone.</small>
                </Col>
            </Row>
            {formLock ? (<><DisplayUserProfileSections label="Company Name:" data={appName} /><Col className="offset-sm-3 px-0 px-sm-2"><Button className="my-2" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Name</Button></Col></>):
                        (<AppDetailsEdit data={appName} cancelHandler={() => setFormLock(true)} formLock={formLock} />)}
            </MainShadowBox>
        </>
    );
}

const AppDetailsEdit = ( {data, cancelHandler, formLock} ) => {
    const dispatch = useDispatch();
    const identity = useIdentityContext();
    const [appDetails, setAppDetails] = useState({
        "#AppName": data,
    });

    const handleFormChange = (e) => {
        if (!formLock) {
            const { id, name, value, type, checked } = e.target;
            if (type === 'checkbox') {
                setAppDetails((prevAppDetails) => ({
                    ...prevAppDetails,
                    [name]: {
                        ...prevAppDetails[name],
                        [id]: checked,
                    },
                }));
            } else {
                setAppDetails((prevAppDetails) => ({
                    ...prevAppDetails,
                    [id]: value,
                }));
            }
        }
    };

    const handleSubmit = () => {
        dispatch(updateAppName({ appDetails, identity }));
        cancelHandler();
      }

    return(
        <>
        <FormNameGeneric label="Company Name:" handleFormChange={handleFormChange} formDataSection={appDetails['#AppName']} controlId="#AppName" required={true} />
        <Col className="offset-sm-3 my-2 px-0 px-sm-2">
            <Button className="me-2" variant="outline-secondary" onClick={cancelHandler}>Cancel</Button>
            <Button onClick={handleSubmit} variant="primary">Update</Button>
        </Col>
        </>
    );
}