import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, DropdownItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';
import LoadingSpinner from './loadingSpinner';
import { SchoolProfileView } from '../userprofilepage/schooolProfileView';
import { EditSchoolProfile } from '../userprofilepage/editSchoolProfile';
import { SchoolUserProfileView } from '../userprofilepage/schoolUserProfileView';
import { EditSchoolUserProfile } from '../userprofilepage/editSchoolUserProfile';
import { TeacherProfileView } from '../userprofilepage/teacherProfileView';
import { EditTeacherProfile } from '../userprofilepage/editTeacherProfile';
import { ModalApprovalFooter } from './actionButtons/modalApprovalFooter';

export function AgencyProfileViewer({ profileId, typeOfUser, linkStyle, noEdit, approvalFooter, afterEffects }) {
    const [show, setShow] = useState(false);
    const [displayData, setDisplayData] = useState(null);
    const [loading, setLoading] = useState(true);
    const identity = useIdentityContext();
    const [formLock, setFormLock] = useState(true);
    const [schoolCreatorData, setSchoolCreatorData] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchUserData = useCallback(async (userIdInput = null) => {
        try {
            const response = await identity.authorizedFetch(`/app_api/profile/${encodeURIComponent(userIdInput)}/${typeOfUser}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setDisplayData(data.profile);
            if (data.schoolCreator) {
                setSchoolCreatorData(data.schoolCreator);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
            // Handle error state
        }
    }, [identity, typeOfUser]);

    useEffect(() => {
        if (show) {
            fetchUserData(profileId);
        };
    }, [fetchUserData, profileId, show]);

    const handleRefreshData = () => {
        setLoading(true);
        setFormLock(true);
        fetchUserData(profileId);
    };

    const renderProfile = () => {
        switch (typeOfUser) {
            case "school":
                return (<>
                    {formLock ?
                        (<><SchoolProfileView inputData={displayData} noEdit={noEdit} />
                            {!noEdit && <Button className="my-2" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Profile</Button>}
                        </>) :
                        (<EditSchoolProfile schoolId={profileId} data={displayData} formLock={formLock} handleRefreshData={handleRefreshData} cancelHandler={() => setFormLock(!formLock)} masterOveride={profileId} />)}
                        {schoolCreatorData && (<><hr /><h6 className="mb-2">School Creator:</h6><SchoolUserProfileView inputData={schoolCreatorData} formLock={formLock} noEdit={true} masterOveride={true} /></>
                            )}
                </>);
            case "teacher":
                return (
                    <>
                        <TeacherProfileView inputData={displayData} formLock={formLock} noEdit={noEdit} masterOveride={true} />
                        {!formLock && <EditTeacherProfile data={displayData} formLock={formLock} handleRefreshData={handleRefreshData} cancelHandler={() => setFormLock(!formLock)} masterOveride={profileId} />}

                        {formLock && !noEdit && <Button className="my-2" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Profile</Button>}
                    </>
                );
            case "schooluser":
                return (<>
                    <SchoolUserProfileView inputData={displayData} formLock={formLock} noEdit={noEdit} masterOveride={true} />
                    {!formLock && <EditSchoolUserProfile data={displayData} formLock={formLock} handleRefreshData={handleRefreshData} cancelHandler={() => setFormLock(!formLock)} masterOveride={profileId} />}
                    {formLock && !noEdit && <Button className="my-2" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Profile</Button>}
                </>);
            case "staff":
                return (<>
                    <SchoolUserProfileView inputData={displayData} formLock={formLock} noEdit={noEdit} masterOveride={true} />
                    {!formLock && <EditSchoolUserProfile data={displayData} formLock={formLock} handleRefreshData={handleRefreshData} cancelHandler={() => setFormLock(!formLock)} masterOveride={profileId} />}
                    {formLock && !noEdit && <Button className="my-2" variant={formLock ? 'primary' : 'outline-secondary'} onClick={() => setFormLock(false)}>Edit Profile</Button>}
                </>);
            default:
                return <p>An error occured</p>;
        }
    };

    return (
        <>
            {linkStyle === "iconButton" ?
                (<OverlayTrigger placement="top" overlay={<Tooltip id={`${profileId}ViewProfile`}>View/Edit Profile</Tooltip>}>
                    <Button variant="outline-secondary" size="sm" key={`${profileId}ViewProfileBtn`} onClick={handleShow}>
                        <i className="bi bi-person-circle"></i>
                    </Button>
                </OverlayTrigger>)
                : linkStyle === "agencyApprove" ? (
                        <Button variant="outline-secondary" size="sm" key={`${profileId}ReviewProfileBtn`} onClick={handleShow}>
                            <i className="bi bi-person-circle me-2"></i>Review Profile
                        </Button>
                )
                : linkStyle === "dropdownItem" ? (
                    <DropdownItem onClick={handleShow}>View Full Profile</DropdownItem>
                ) :
                    (<Button variant="link" onClick={handleShow}>
                        View Profile
                    </Button>)
            }

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (displayData ? (
                        renderProfile()
                    )
                        : <p className="text-secondary mt-3">An error occured loading the data. Refresh the page to try again.</p>)}
                </Modal.Body>
                {approvalFooter && <ModalApprovalFooter typeOfUser={typeOfUser} userId={profileId} afterEffects={afterEffects}/>}
            </Modal>
        </>
    );
}