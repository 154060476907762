import React, { useState, useCallback, useEffect } from 'react';
import { useIdentityContext } from '../../identity-react';
import { MainShadowBox } from './mainShadowBox';
import { Col, Row, OverlayTrigger, Tooltip, Button, Spinner, Form, Badge } from 'react-bootstrap';
import { formatDistanceToNow, format } from 'date-fns';
import AlertModule from './alertModule';
import LoadingSpinner from './loadingSpinner';
import { FormNameGeneric, FormTextAreaGeneric } from '../Signup/allFormComponents';
import { MainSectionBox } from './mainSectionBox';

const AddAlertForm = () => {
  const identity = useIdentityContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [alertsData, setAlertsData] = useState([]);
  const [previewAlert, setPreviewAlert] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [formData, setFormData] = useState({ 'Audience': {'schools': true, 'staff': true, 'teachers': true} });
  const [validated, setValidated] = useState(false);

  const fetchAlerts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await identity.authorizedFetch('/app_api/alerts/all/staff', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch alerts data');
      }
      const data = await response.json();
      setAlertsData(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching alerts data:', error);
      setIsLoading(false);
    }
  }, [identity]);

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  useEffect(() => {
    setPreviewAlert({ variant: formData?.Variant, alertHeading: formData?.Heading, alertBody: formData?.Message });
  }, [formData]);


  const viewAlert = (alertId) => {
    const selectedAlert = alertsData.find(alert => alert.SK === alertId);
    setPreviewAlert({ variant: selectedAlert.Variant, alertHeading: selectedAlert.Heading, alertBody: selectedAlert.Message });
  };

  const deleteAlert = async (alertId) => {
    setDeleteLoading(alertId);

    try {
        const response = await identity.authorizedFetch('/app_api/alerts', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "alertId": alertId })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        await response.json();
        fetchAlerts();
        setDeleteLoading(false);
    } catch (error) {
        console.error('Error deleting date:', error);
        setDeleteLoading(false);
    }
};

const handleFormChange = (e) => {
  const { id, name, value, type, checked } = e.target;
  if (type === 'checkbox') {
      setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: {
              ...prevFormData[name],
              [id]: checked,
          },
      }));
  } else {
      setFormData((prevFormData) => ({
          ...prevFormData,
          [id]: value,
      }));
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(false);
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
        return;
    }
    setValidated(true);
    setIsFormLoading(true);
    console.log("this is the formData", formData);
    identity.authorizedFetch('/app_api/alerts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({formData: formData})
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        fetchAlerts();
        setFormData("");
        setValidated(false);
        setPreviewAlert(null);
        setIsFormLoading(false);
    })
    .catch(error => {
        setIsFormLoading(false);
        setFormError("An unexpected error occured. Please try again.");
        console.error('Error creating new alert:', error);
    });
};


  return (<>
    {previewAlert && (<AlertModule alertId='previewAlert' variant={previewAlert?.variant} alertHeading={previewAlert?.alertHeading} alertBody={previewAlert?.alertBody} timedRemove={false} testDisplay={true} />)}
    <MainShadowBox title="Broadcast Messages">
      {isLoading ? <LoadingSpinner /> : (<Col className="mb-5">
        <Row className="text-center text-secondary border-bottom p-2" key="headerRow">
          <Col xs={4} className="d-none d-md-block">
            <small><i className="bi bi-chat-left-text"></i><span className="d-none d-md-block">Message</span></small>
          </Col>
          <Col xs={1}>

          </Col>
          <Col xs={3} md={2}>
            <small><i className="bi bi-people"></i><span className="d-none d-md-block">Audience</span></small>
          </Col>
          <Col xs={4} md={2}>
            <small><i className="bi bi-person-up"></i><span className="d-none d-md-block">Created By</span></small>
          </Col>
          <Col xs={3} md={2}>
            <small><i className="bi bi-clock"></i><span className="d-none d-md-block">Expires In</span></small>
          </Col>
          <Col xs={1}>
          </Col>
        </Row>

        {alertsData?.length > 0 ? (alertsData.map((alert, index) => {
          const isLastItem = index === alertsData.length - 1;
          const singleResult = alertsData.length === 1;
          const borderClass = !isLastItem && !singleResult ? 'border-bottom' : '';

          return (
            <Row key={index} className={`text-center p-2 fs-6 ${borderClass}`}>
              <Col xs={4} className="d-flex align-items-center flex-column justify-content-center d-none d-md-block">
                <p className="m-0 w-100 text-truncate fw-bold">{alert.Heading}</p>
                <p className="m-0 w-100 text-truncate">{alert.Message}</p>
              </Col>
              <Col xs={1} className="d-flex align-items-center justify-content-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`${alert.SK}-viewalert`}>View Broadcast</Tooltip>}
                >
                  <Button onClick={() => viewAlert(alert.SK)} size="sm" variant="outline-secondary"><i className="bi bi-eye"></i></Button>
                </OverlayTrigger>
              </Col>
              <Col xs={3} md={2} className="d-flex align-items-center justify-content-center gap-1 flex-wrap">
                {alert.Audience?.map((aud, index) => (
                    <Badge bg="secondary" key={`${index}audience`} className="text-capitalize">{aud}</Badge>
                ))}
              </Col>
              <Col xs={4} md={2} className="d-flex align-items-center flex-column justify-content-center">
                <p className="m-0">{alert.CreatedByName}</p>
                <p className="m-0 text-secondary"><small>{formatDistanceToNow(alert.CreatedAt, { addSuffix: true })}</small></p>
              </Col>
              <Col xs={3} md={2} className="d-flex align-items-center text-center justify-content-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`${alert.SK}-expirydate`}>{format(new Date(alert.TTL * 1000), 'E do LLL y p')}</Tooltip>}
                >
                  <p className="m-0">{formatDistanceToNow(alert.TTL * 1000, { addSuffix: false })}</p>
                </OverlayTrigger>
              </Col>
              <Col xs={1} className="d-flex align-items-center justify-content-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`${alert.SK}-tooltip`}>Delete Broadcast</Tooltip>}
                >
                  <Button size="sm" variant="outline-danger" onClick={() => deleteAlert(alert.SK)} disabled={deleteLoading}>{deleteLoading === alert.SK ? <Spinner size="sm" /> : <i className="bi bi-x-lg"></i>}</Button>
                </OverlayTrigger>
              </Col>
            </Row>
          );
        })) : <Col xs={12} className="text-center p-3 text-secondary">There are currently no broadcasts.</Col>}

      </Col>)}

      <MainSectionBox title="Create a new Broadcast Message">
        {isFormLoading ? <LoadingSpinner /> : (<Form noValidate className="py-2 px-0 p-md-4" onSubmit={handleSubmit} validated={validated}>
        <fieldset>

          <FormNameGeneric label="Message Heading:" handleFormChange={handleFormChange} formDataSection={formData['Heading']} controlId='Heading' required={!formData['Heading'] && !formData['Message']} />

          <FormTextAreaGeneric label="Message Body:" handleFormChange={handleFormChange} formDataSection={formData['Message']} controlId='Message' required={!formData['Heading'] && !formData['Message']} />

          <Form.Group as={Row} className="mb-3" controlId="EndDate">
            <Form.Label column sm={3}>Expiry Date:</Form.Label>
            <Col sm={2}>
              <Form.Control onChange={handleFormChange} value={formData['EndDate'] || ''} required={true} type="date" min={(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).toISOString().split('T')[0]} />
              <Form.Control.Feedback type="invalid">
                This is a required field.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="Variant">
            <Form.Label column sm={3}>Label Colour:</Form.Label>
            <Col sm={3}>
            <Form.Select
              aria-label="select label colour"
              onChange={handleFormChange}
              value={formData['Variant']}
              name="Variant"
            >
              <option value="primary">Blue (Default)</option>
              <option value="success">Green</option>
              <option value="danger">Red</option>
              <option value="warning">Yellow</option>
              <option value="light">Grey</option>
            </Form.Select> 
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="Audience">
        <Form.Label column sm={3}>Broadcast to:</Form.Label>
        <Col sm={9} className="mt-1">
        <Form.Check
            type="switch"
            id="teachers"
            label="Teachers"
            name="Audience"
            onChange={handleFormChange}
            checked={formData['Audience'] && formData['Audience']['teachers']}
        />
        <Form.Check
            type="switch"
            id="schools"
            label="Schools"
            name="Audience"
            onChange={handleFormChange}
            checked={formData['Audience'] && formData['Audience']['schools']}
        />
        <Form.Check
            type="switch"
            id="staff"
            label="Agency Staff"
            name="Audience"
            onChange={handleFormChange}
            checked={formData['Audience'] && formData['Audience']['staff']}
        />
        </Col>
    </Form.Group>

          <Button type="submit" variant="primary" id="CreateNewAlertButton">Create Message</Button>
          {formError && <p className="text-danger mt-1"><small>{formError}</small></p>}
        </fieldset>
      </Form>)}
      </MainSectionBox>
    </MainShadowBox>
  </>);
};

export default AddAlertForm;