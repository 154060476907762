import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTheme, getColourSet } from '../../features/display/displaySlice';
import { useIdentityContext } from '../../identity-react';
import { Button, Dropdown } from 'react-bootstrap';
import chroma from 'chroma-js';

const ThemeUpdater = () => {
    const dispatch = useDispatch();
    const identity = useIdentityContext();
    const colourSet = useSelector(getColourSet);
    const [colorCore1, setColorCore1] = useState(colourSet?.coreColour || '');
    const [color2, setColor2] = useState('');
    const [color3, setColor3] = useState('');
    const [analogousColors, setAnalogousColors] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);

    const handleColorChangeCore1 = (e) => {
        const newColor = e.target.value;
        setColorCore1(newColor);
        calculateAnalogousColors(newColor);
    };

    const handleColorChangeCore1fromDropdown = (e) => {
      const newColor = e.target.value;
      setColorCore1(newColor);
      updateAnalogousColors(newColor, color2, color3);
  };

    const handleColorChange2 = (e) => {
        const newColor = e.target.value;
        setColor2(newColor);
        updateAnalogousColors(colorCore1, newColor, color3);
    };

    const handleColorChange3 = (e) => {
        const newColor = e.target.value;
        setColor3(newColor);
        updateAnalogousColors(colorCore1, color2, newColor);
    };

    //const handleColourSubmit = () => {
    //    dispatch(updateTheme({ analogousColors, identity }));
    //    setAnalogousColors({});
    //};

    const handleColourSubmit = () => {
      const themeUpdate = { analogousColors, identity };
      if (!checkContrastWithWhite(colorCore1)) {
          themeUpdate.textColour = '#000000';
      } else {
          themeUpdate.textColour = '#ffffff';
      }
      dispatch(updateTheme(themeUpdate));
      setAnalogousColors({});
  };

    const handleCancel = () => {
        setAnalogousColors({});
        setColorCore1(colourSet.coreColour);
    };

    const calculateAnalogousColors = (color) => {
        const color2 = chroma(color).set('hsl.h', '+30').hex();
        const color3 = chroma(color).set('hsl.h', '-30').hex();
        setAnalogousColors({
            "#ColourCore1": color,
            "#Colour2": color2,
            "#Colour3": color3,
        });
        setColor2(color2);
        setColor3(color3);
    };

    const updateAnalogousColors = (colorCore1, color2, color3) => {
        setAnalogousColors({
            "#ColourCore1": colorCore1,
            "#Colour2": color2,
            "#Colour3": color3,
        });
    };

    const checkContrastWithWhite = (color) => {
        const contrastRatio = chroma.contrast(color, 'white');
        return contrastRatio >= 1.5;
    };
  
    return (
        <div>
            <input 
                type="color" 
                value={colorCore1} 
                onChange={handleColorChangeCore1} 
            />
            {Object.keys(analogousColors).length > 0 && (
                <>
                    <div className="d-flex my-2">
                        <div style={{ 
                            background: `linear-gradient(135deg, ${analogousColors['#ColourCore1']}, ${analogousColors['#Colour2']})`,
                            width: '200px', 
                            height: '100px',
                            marginRight: '10px' 
                            }}
                            className={`d-flex ${checkContrastWithWhite(colorCore1) ? 'text-white' : 'text-dark'} text-center align-items-center p-3`}
                        >
                            <i className="bi bi-lightbulb"></i>This is how text will be displayed
                        </div>
                    </div>
                    <div className="d-flex my-2">
                        {Object.values(analogousColors).map((analogousColor, index) => (
                            <div 
                                key={index} 
                                style={{ 
                                    backgroundColor: analogousColor, 
                                    width: '20px', 
                                    height: '20px',
                                    marginRight: '10px' 
                                }}
                            />
                        ))}
                        <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                            <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic">
                                Advanced
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ minWidth: '200px' }}>
                                <div className="p-3">
                                    <div className="d-flex gap-1 justify-content-center w-100">
                                        <input 
                                            type="color" 
                                            value={colorCore1} 
                                            onChange={handleColorChangeCore1fromDropdown} 
                                        />
                                        <input 
                                            type="color" 
                                            value={color2} 
                                            onChange={handleColorChange2} 
                                        />
                                        <input 
                                            type="color" 
                                            value={color3} 
                                            onChange={handleColorChange3} 
                                        />
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Button onClick={handleCancel} className="ms-0 m-2" variant="outline-secondary">Cancel</Button>
                    <Button className="ms-0 m-2" onClick={handleColourSubmit}>Update Colours</Button>
                </>
            )}
        </div>
    );
};

export default ThemeUpdater;
