import React, { useState } from 'react';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { useIdentityContext } from '../../identity-react';
import { SchoolSelector } from './schoolSelector';
import { FormOccupation, FormNamePreInput, FormEmailPreInput } from './allFormComponents';
import { SchoolCreateSection } from './schoolCreateSection';

export function SchoolSignUpForm () {
    const identity = useIdentityContext();

    const [formData, setFormData] = useState("");
    const [validated, setValidated] = useState(false);
    const [chosenSchool, setChosenSchool] = useState(null);
    const [addNewSchool, setAddNewSchool] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleFormChange = (e) => {
        const { id, name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: {
                    ...prevFormData[name],
                    [id]: checked,
                },
            }));
        } else if (id === '#Postcode') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.toUpperCase(),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
            }));
        }
    };

    const handleImageCropUpload = (data, id) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            '#ImageUpload': data,
        }));
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!chosenSchool && !addNewSchool) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
    
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        setIsLoading(true);
        identity.authorizedFetch('/app_api/schoolsignup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({formData: formData, addNewSchool: addNewSchool, chosenSchool: chosenSchool ? chosenSchool.PK : null})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(identity.refreshUser)
        .catch(error => {
            setIsLoading(false);
            console.error('Error signing up:', error);
        });
    };

    return (
        <>
            {isLoading ? (<div className="py-5 text-center purple_text">
                    <Spinner animation="border" size="lg" className="purple_spinner" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p className="mt-3">Signing Up</p>
                  </div>) 
                  : (<Form noValidate className="py-2 px-0 p-md-4" onSubmit={handleSubmit} validated={validated}>
                <fieldset>
                    <FormNamePreInput label="Your Full Name:" value={identity.user.user_metadata.full_name} controlId="#Name" />
                    <FormEmailPreInput label="Your Email:" value={identity.user.email} controlId="#Email" />
                    <FormOccupation label="Your Job Title:" handleFormChange={handleFormChange} formData={formData} required={true} />

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>School:</Form.Label>
                        <Col sm={4}>
                            <SchoolSelector chosenSchool={chosenSchool} setChosenSchool={setChosenSchool} addNewSchool={addNewSchool} setAddNewSchool={setAddNewSchool} />
                            {validated && !chosenSchool && !addNewSchool && (
                                <div className="invalid-feedback d-block">Please select a school.</div>
                            )}
                        </Col>
                    </Form.Group>

                    {addNewSchool && (<>
                    <hr />
                    <SchoolCreateSection formData={formData} setFormData={setFormData} addNewSchool={addNewSchool} handleFormChange={handleFormChange} handleImageCropUpload={handleImageCropUpload} />
                    <hr />
                    </>)}


                    <Button type="submit" variant="primary" id="#SignUpButton" disabled={addNewSchool && !formData['#ImageUpload']}>Sign Up</Button>
                </fieldset>
            </Form>)}
        </>
    )
};
