import React from 'react';
import { DisplayTeacher } from './displayTeacher';
import { DisplaySchool } from './displaySchool';
import { DisplaySchoolUser } from './displayScholUser';

export const DisplaySearchResults = ({ searchResults, functionButton, userType, matchCount, afterEffects, vertPadding, hideImage }) => {
    return (
        <>
            {matchCount && (<div className="my-3 text-secondary fw-lighter">
                {searchResults.length > 1 
                    ? `${searchResults.length} results to display` 
                    : `${searchResults.length} result to display`}
            </div>)}
            {searchResults.map((result, index) => {
                const isLastItem = index === searchResults.length - 1;
                const singleResult = searchResults.length === 1;
                const borderClass = !isLastItem && !singleResult ? 'border-bottom' : '';

                return (
                    <div key={index} className={`w-100 ${borderClass}`}>
                        <div className={`col-12 px-4 ${vertPadding ? `py-${vertPadding}` : 'py-4'}`}>
                            {["teacher", "teacher_pending", "teacher_deactivated"].includes(userType) ? <DisplayTeacher key={`${result.PK}teacherdisplay`} result={result} functionButton={functionButton} afterEffects={afterEffects} /> :
                            ["school", "school_pending", "school_deactivated"].includes(userType) ? <DisplaySchool key={`${result.PK}schooldisplay`} result={result} functionButton={functionButton} afterEffects={afterEffects} hideImage={hideImage} /> : 
                            ["schooluser", "schooluser_pending", "schooluser_deactivated"].includes(userType) ? <DisplaySchoolUser key={`${result.PK}schooluserdisplay`} result={result} functionButton={functionButton} afterEffects={afterEffects} /> :
                            ["staff", "staff_pending", "staff_deactivated"].includes(userType) ?<DisplaySchoolUser key={`${result.PK}staffdisplay`} result={result} functionButton={functionButton} afterEffects={afterEffects} /> 
                            : <></>}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default DisplaySearchResults;
