import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { useIdentityContext } from '../../../identity-react';
import { EditTeacherProfile } from '../../userprofilepage/editTeacherProfile';
import LoadingSpinner from '../loadingSpinner';
import { EditSchoolProfile } from '../../userprofilepage/editSchoolProfile';
import { EditImage } from '../editImage';

export function RequestReactivationButton( {userId} ) {
  const identity = useIdentityContext();
  const [message, setMessage] = useState('Request Reactivation');
  const [loading, setLoading] = useState(true);
  const [displayData, setDisplayData] = useState(null);

  const [typeOfUser] = userId.split('#');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleUserCodeSubmit = async (action) => {
    try {
        setMessage('Requesting...');
        if (!action || !typeOfUser || !userId) {
            throw new Error('Missing required parameters');
        }

        const response = await identity.authorizedFetch(`/app_api/account/${encodeURIComponent(action)}/${encodeURIComponent(typeOfUser)}/${encodeURIComponent(userId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        await response.json();
        setMessage('Request Sent');
        handleClose();
    } catch (error) {
        console.error('Error handling user code submission:', error);
        setMessage('Request Reactivation');
    }
};

const fetchUserData = useCallback(async () => {
  try {
      const response = await identity.authorizedFetch(`/app_api/getaccount/${encodeURIComponent(typeOfUser)}/${typeOfUser === "school" ? encodeURIComponent(userId) : ''}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      });
      if (!response.ok) {
          throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      setDisplayData(data);
      setLoading(false);
  } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
      // Handle error state
  }
}, [identity, typeOfUser, userId]);


useEffect(() => {
  if (show) {
    fetchUserData();
  }
}, [fetchUserData, show]);

  if (!userId || !typeOfUser) {
    return null;
  }

  //export function EditSchoolProfile( {schoolId, data, formLock, handleRefreshData, cancelHandler, masterOveride

  return (
    <>
    <Button size="sm" variant="light" onClick={handleShow}>
    {message}</Button>

    <Modal size='xl' show={show} onHide={handleClose}>
        <Modal.Header closeButton>Confirm your details
        </Modal.Header>
        <Modal.Body>
          {loading ? <LoadingSpinner /> : typeOfUser === "teacher" ? (<><Row className="mb-2">
                <Col className="text-secondary" sm={3}>Profile Picture:</Col>
                <Col sm={3}>
                    <EditImage origImage={displayData.ProfileImage} aspectRatio={1 / 1} shape={'roundedCircle'} dynamoImageAttr={'ProfileImage'} entryType={'teacher'} format={'image/jpeg'} />
                </Col>
            </Row><EditTeacherProfile data={displayData} formLock={false} cancelHandler={handleClose} handleRefreshData={() => handleUserCodeSubmit('request_reactivation')} reviewOveride={true} /></>)
           : typeOfUser === "school" ? (<>
           <Row className="mb-2">
                <Col className="text-secondary" sm={3}>School Logo:</Col>
                <Col sm={3}>
                    <EditImage origImage={displayData.SchoolLogo} aspectRatio={3 / 1} shape={'rounded'} dynamoImageAttr={'SchoolLogo'} entryType={'school'} format={'image/png'} allowZoomOut={true} />
                </Col>
            </Row>
           <EditSchoolProfile schoolId={userId} data={displayData} formLock={false} cancelHandler={handleClose} handleRefreshData={() => handleUserCodeSubmit('request_reactivation')} reviewOveride={true} />
            </>)
          : null}
        </Modal.Body>
      </Modal>
    </>
  );
};