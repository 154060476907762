import React, { useState } from 'react';
import { Button, Dropdown, FormCheck, OverlayTrigger, Tooltip, DropdownMenu } from 'react-bootstrap';
import { useIdentityContext } from '../../../identity-react';

export function ManageUserPermissionsButton({ userId, initPermission, schoolId, afterEffects, approve }) {
  const [selectedPermission, setSelectedPermission] = useState(initPermission); // State to track selected radio button
  const identity = useIdentityContext();

  // Function to handle radio button change
  const handleRadioChange = (event) => {
    setSelectedPermission(event.target.id);
  };
  
  const handleRejectClick = () => {
    const newPermission = 'deactivated';
    setSelectedPermission(newPermission);
    handleClick(newPermission);
  };

  // Function to handle button click
  const handleClick = async (permission) => {
    const newPermission = permission || selectedPermission;
    try {
        if (!newPermission) {
          throw new Error("No permission selected");
        }

        const response = await identity.authorizedFetch('/app_api/schoolUser/changeAccessLevel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              SchoolId: schoolId,
              UserId: userId,
              NewPermission: newPermission,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to fetch staff data');
        }
        const data = await response.json();
        console.log("success", data);
        afterEffects();
    } catch (error) {
        console.error('Error updating permissions data:', error);
    }
  };

  return (
    <>
      {approve && (
        <OverlayTrigger placement="top" overlay={<Tooltip id={`${userId}RejectTooltip`}>Reject</Tooltip>}>
          <Button size="sm" key={`${userId}RejectButton`} onClick={handleRejectClick} variant='danger'><i className="bi bi-x-lg"></i></Button>
        </OverlayTrigger>
      )}
      <OverlayTrigger placement="top" overlay={<Tooltip id={`${userId}ManagePermissions`}>{approve ? 'Approve' : 'Manage Access Level'}</Tooltip>}>
        <Dropdown key={`${userId}PermissionsDropdown`} align="end">
          <Dropdown.Toggle className="dropdown-no-arrow" key={`${userId}ManagePermissionsDropdown`} variant={approve ? 'success' : 'outline-primary'} size="sm" id="dropdown-basic-button">
            <i className={`bi ${approve ? 'bi-check-lg' : 'bi-person-lock'}`}></i>
          </Dropdown.Toggle>
          <DropdownMenu className="text-center p-3">
          <div style={{minWidth: '200px'}}>
          <FormCheck
            type='radio'
            label='Admin'
            name={`${userId}PermissionsRadio`}
            id='admin'
            key={`${userId}admin`}
            checked={selectedPermission === 'admin'}
            onChange={handleRadioChange}
          />
          <FormCheck
            type='radio'
            label='Editor'
            name={`${userId}PermissionsRadio`}
            id='editor'
            key={`${userId}editor`}
            checked={selectedPermission === 'editor'}
            onChange={handleRadioChange}
          />
          <FormCheck
            type='radio'
            label='Viewer'
            name={`${userId}PermissionsRadio`}
            id='viewer'
            key={`${userId}viewer`}
            checked={selectedPermission === 'viewer'}
            onChange={handleRadioChange}
          />
          </div>
          <div className="mt-2">
          {!approve && (<OverlayTrigger placement="bottom" overlay={<Tooltip id={`${userId}DeactivateBtn`}>Remove User</Tooltip>}>
          <Button className="me-2" size="sm" key={`${userId}DeactivateBtn`} variant='outline-danger' onClick={handleRejectClick}><i className="bi bi-x-lg"></i></Button>
          </OverlayTrigger>)}
          <Button size="sm" key={`${userId}PermissionsBtn`} onClick={() => handleClick()} disabled={!selectedPermission}>{approve ? 'Approve' : 'Update'}</Button>
          {selectedPermission && (
            <p className="m-0 mt-3 border rounded p-2">
              <small>
                {selectedPermission === "admin" ? (
                  <>
                    <b>Admin Access</b>
                    <br />
                    Admins can create and edit jobs, team members and the school profile and approve/reject team invites. Limit it's use.
                  </>
                ) : selectedPermission === "editor" ? (
                  <>
                    <b>Editor Access</b>
                    <br />
                    Editors can create and edit jobs and the school profile.
                  </>
                ) : selectedPermission === "viewer" ? (
                  <>
                    <b>Viewer Access</b>
                    <br />
                    Viewers can view jobs, but can't make changes to jobs or the school profile.
                  </>
                ) : null}
              </small>
            </p>
          )}
          </div>
          </DropdownMenu>
        </Dropdown>
      </OverlayTrigger>
    </>
  );
}