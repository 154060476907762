import { addNotification } from "../../features/alerts/alertsSlice";

let socket = null;
let reconnectTimer = null;
let currentUserId = null;
let currentJobId = null;
let shouldReconnect = true; // Add this flag

const WEBSOCKET_URL = 'wss://ho9iyro42f.execute-api.eu-north-1.amazonaws.com/production/';

export const connectWebSocket = (userId, jobId, dispatch) => {
  // Check if the socket is already open and the userId hasn't changed
  if (socket && socket.readyState === WebSocket.OPEN) {
    if (currentUserId === userId) {
      console.log("Socket already open and userId matches");
      return; // No need to create a new connection
    } else {
      console.log("Closing existing socket due to userId change");
      socket.close(); // Close the existing socket if userId changes
    }
  }

  // Don't connect if userId is undefined or null
  if (userId == null) {
    console.log("Not connecting: userId is null or undefined");
    return;
  }

  console.log(`Attempting to connect WebSocket for userId: ${userId}, jobId: ${jobId}`);

  // Create a new WebSocket connection
  socket = new WebSocket(`${WEBSOCKET_URL}?userId=${encodeURIComponent(userId)}&jobId=${encodeURIComponent(jobId)}`);
  currentUserId = userId;
  currentJobId = jobId;
  shouldReconnect = true; // Reset the flag when connecting

  socket.onopen = () => {
    console.log('WebSocket connected');
    if (reconnectTimer) {
      clearTimeout(reconnectTimer);
      reconnectTimer = null;
    }
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    // Handle incoming messages
    console.log('Received message:', data);
    dispatch(addNotification(data));
  };

  socket.onclose = (event) => {
    console.log('WebSocket disconnected:', event.reason);
    // Attempt to reconnect after a delay only if shouldReconnect is true
    if (shouldReconnect) {
      reconnectTimer = setTimeout(() => connectWebSocket(currentUserId, currentJobId), 5000);
    }
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
  };
};

export const closeWebSocket = () => {
  if (socket) {
    console.log("Closing WebSocket");
    socket.close();
    socket = null;
  }
  if (reconnectTimer) {
    clearTimeout(reconnectTimer);
    reconnectTimer = null;
  }
  // Clear userId and jobId
  currentUserId = null;
  currentJobId = null;
  shouldReconnect = false; // Set the flag to prevent reconnection
};

export const sendMessage = (message) => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify(message));
  } else {
    console.error('WebSocket is not connected.');
  }
};