const CheckAccessLevel = (requiredLevel, currentPermissionLevel) => {
    switch (requiredLevel) {
      case "viewer":
        return ["viewer", "admin", "editor"].includes(currentPermissionLevel);
      case "editor":
        return ["admin", "editor"].includes(currentPermissionLevel);
      case "admin":
        return currentPermissionLevel === "admin";
      default:
        return false;
    }
  };
  
  export default CheckAccessLevel;  