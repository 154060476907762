import React from 'react';
import { Button } from 'react-bootstrap';
import { useIdentityContext } from '../../../identity-react';

export function ReactivateButton( {userId, afterEffects} ) {
  const identity = useIdentityContext();

  const [typeOfUser] = userId.split('#');

  const handleUserCodeSubmit = async (action) => {
    try {
        if (!action || !typeOfUser || !userId) {
            throw new Error('Missing required parameters');
        }

        const response = await identity.authorizedFetch(`/app_api/account/${encodeURIComponent(action)}/${encodeURIComponent(typeOfUser)}/${encodeURIComponent(userId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        await response.json();
        afterEffects();
    } catch (error) {
        console.error('Error handling user code submission:', error);
    }
};



  return (
    <>
    <Button size="sm" variant="outline-danger" onClick={() => handleUserCodeSubmit('approved')}>
    <i className="bi bi-person-check me-2"></i>Reactivate</Button>
    </>
  );
};